import { GRAY_FOOTER_COLOR } from 'global/constants';
import React from 'react';
import DiscoverMobileFold1 from './Fold1';
import DiscoverMobileFold2 from './Fold2';

const MobileDiscover = props => {

    const { fold1, fold2, translations, responsive = true, footerIconHandler } = props;

    return (<div className="mobile-container" id="discovercontainer">
        {fold1 && <DiscoverMobileFold1 data={fold1} responsive={responsive} translations={translations} />}
        {fold2 && <DiscoverMobileFold2 data={fold2} footerIconHandler={footerIconHandler} responsive={responsive} translations={translations} />}
    </div>)
}

export default MobileDiscover