import Wrapper from 'components/Wrapper';
import { fetchContent } from 'global/api';
import { FAQ_PAGE_URL, GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, OPAQUE_COLOR, QA_TYPE_IMAGE, QA_TYPE_VIDEO, WHITE_PRIMARY_COLOR } from 'global/constants';
import { getIconURL } from 'global/utils';
import React, { useEffect, useState } from 'react';
import Fallback from 'components/Fallback';
import { useLocation, withRouter } from 'react-router-dom';
import Accordion from 'components/Accordion';
import ComingSoon from 'components/ComingSoon';

const MobileFAQ = props => {

    const { translations, leftContainer, responsive } = props;
    const [subCategory, setSubCategory] = useState();
    const [video, setVideo] = useState();
    const [image, setImage] = useState();
    const location = useLocation()
    const [rightContainer, setRightContainer] = useState();
    const [checkedId, setCheckedId] = useState(0);

    useEffect(() => {
        if (location && !location.state) {
            setRightContainer();
            setSubCategory();
            setVideo();
        }
    }, [location])

    const [searchLoading, setSearchLoading] = useState(true);

    const selectMenuItemHandler = (item) => {
        fetchQuestions(item)
    }

    const [videoLoading, setVideoLoading] = useState(false);


    useEffect(() => {
        setVideoLoading(video ? video === "default" ? false : video.mediaUrl ? false : true : false);
        const id = setTimeout(() => {
            setVideoLoading(false)
        }, 10000);

        return () => clearTimeout(id);
    }, [video])

    const fetchQuestions = (item) => {
        setSearchLoading(true);
        fetchContent(FAQ_PAGE_URL, { subCategory: item.title }).then((response) => {
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        setRightContainer(data.rightContainer);
                        props.history.push('/assistance/faq', {
                            subCategory: item
                        })
                        setSubCategory(item)
                    }
                    setTimeout(() => {
                        setSearchLoading(false)
                    }, 500);
                }
            } else {
                setSearchLoading(false)
            }
        });
    }

    const handlePreview = (type, asset) => {
        if (type === QA_TYPE_VIDEO)
            setVideo(asset);
        else if (type === QA_TYPE_IMAGE)
            setImage(asset)
    }

    return <> {!(rightContainer && subCategory) ? <div className="mobile-container">
        <div className="mobile-title">
            {leftContainer.title}
        </div>
        <div>
            <div className="faq_grid">
                {leftContainer.menu && leftContainer.menu.map(menu => {
                    return (<div style={{ cursor: 'pointer' }}>
                        <Wrapper backgroundColor={OPAQUE_COLOR} borderRadius="12px" >
                            <div style={{ width: 100, height: 100, display: 'flex' }}
                                onClick={() => selectMenuItemHandler(menu)}>
                                <img src={menu.mediaUrl} style={{ margin: 'auto', width: 60 }} />
                            </div>
                        </Wrapper>
                        <div style={{
                            marginLeft: 5, marginTop: 10, textOverflow: 'ellipsis', overflow: 'hidden',
                            textAlign: 'center', color: GRAY_FOOTER_COLOR
                        }}>
                            {menu.displayTitle}
                        </div>
                    </div>)
                })}
            </div>
        </div>
    </div > : searchLoading ? <Fallback loading={searchLoading}
        color={responsive ? MOBILE_RED_PRIMARY_COLOR : WHITE_PRIMARY_COLOR} /> :
        <div className="mobile-container">
            <div className="mobile-title" style={{ maxWidth: '330px' }}>
                {leftContainer.title}
            </div>
            <div className="mobile-title" style={{ fontSize: 16, display: 'flex', margin: '1em 0' }}>
                <div style={{ display: 'flex' }}>
                    <img src={getIconURL(true, subCategory.mediaUrl)}
                        style={{ width: 30 }} />
                </div>
                <div style={{ marginLeft: '1rem', display: 'flex', alignItems: 'center' }}>
                    {subCategory.displayTitle}
                </div>
            </div>
            <div style={{ display: "grid", gap: 10, gridTemplateColumns: "auto", }}>
                {rightContainer.questions && rightContainer.questions.length !== 0 ?
                    rightContainer.questions.map((faq, index) => (
                        <Accordion handlePreview={handlePreview}
                            type={faq.type} text={faq.text}
                            translations={translations}
                            assets={faq.assets}
                            responsive={responsive}
                            setCheckedId={() => setCheckedId(index)}
                            checkedId={checkedId} id={index} tabLabel={faq.question} />
                    )) : <ComingSoon translations={translations} responsive={responsive} />}
            </div>
            {
                video && <div className="video-overlay">
                    <div style={{
                        padding: '1rem', marginTop: '50%', alignItems: 'center',
                        height: '100%', display: 'flex', flexDirection: 'column'
                    }}>
                        <div className="close-btn" onClick={() => setVideo()}>&#10006;</div>
                        <div style={{
                            display: 'flex', justifyContent: 'center'
                            , maxHeight: 'calc(100vh - 23em)', overflow: 'auto'
                        }}>
                            {videoLoading && <Fallback loading={videoLoading}
                                style={{ position: "absolute" }}
                                color={MOBILE_RED_PRIMARY_COLOR} height="20px" />}
                            {video.mediaUrl ? <video autoPlay="true"
                                controls
                                playsInline
                                loop
                                onLoadCapture={() => setVideoLoading(false)}
                                width="600px" height="360px">
                                <source src={video.mediaUrl} type="video/mp4" />
                            </video> : <iframe allowFullScreen={true}
                                onLoadCapture={() => setVideoLoading(false)}
                                loading="lazy" src={video.redirectUrl}
                                height="360px" style={{ width: "600px" }}
                            />}
                        </div>
                    </div>
                </div>
            }
            {
                image && <div className="video-overlay">
                    <div style={{
                        padding: '1rem', margin: '15% 5%', alignItems: 'center',
                        height: '100%', display: 'flex', flexDirection: 'column'
                    }}>
                        <div className="close-btn" onClick={() => setImage()}>&#10006;</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={image.mediaUrl == null ? image.redirectUrl : image.mediaUrl}
                                width="100%" height="100%" />
                        </div>
                    </div>
                </div>
            }
        </div>}
    </>
}



export default withRouter(MobileFAQ);