import React, { useEffect, useRef, useState } from "react";
import ContactUs from "./ContactUs";
import VisitUs from "./VisitUs";
import Slider from "react-slick";
import { fetchContent } from "global/api";
import {
  CONTACT_PAGE_URL,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import Fallback from "components/Fallback";
import { useLocation, useParams, withRouter } from "react-router-dom";
import MobileContact from "./Mobile";

const Contact = (props) => {
  const { translations, responsive } = props;
  const slideShowRef = useRef(null);
  const historyLocation = useLocation();
  const [serverResponse, setServerResponse] = useState(false);

  const category = historyLocation.state
    ? historyLocation.state.menu
      ? historyLocation.state.menu.title
      : "branches"
    : "branches";

  const initialSlide = historyLocation.state
    ? historyLocation.state.slideIndex
      ? historyLocation.state.slideIndex
      : 0
    : 0;

  const settings = {
    dots: true,
    autoplay: false,
    swipe: false,
    initialSlide: initialSlide,
    autoplaySpeed: 10000,
    customPaging: (i) => <div className="dot"></div>,
  };
  const handleFocus = () => {
    if (slideShowRef.current && slideShowRef.current.slickPause)
      slideShowRef.current.slickPause();
  };
  const handleBlur = () => {
    if (slideShowRef.current && slideShowRef.current.slickPlay)
      slideShowRef.current.slickPause();
  };

  const [fold1, setFold1] = useState();
  const [fold2, setFold2] = useState();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [location, setLocation] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setLocation(location);
        },
        (error) => {
          const message = error ? error.message : "User denied the location";
          const location = {
            latitude: 0.0,
            longitude: 0.0,
          };
          setLocation(location);
          setError(message);
        }
      );
    } else {
      const message = "location unavailable at moment";
      setError(message);
      const location = {
        latitude: 0.0,
        longitude: 0.0,
      };
      setLocation(location);
    }
  }, []);

  useEffect(() => {
    // check for any data in search query params;
    if (serverResponse) {
      if (
        historyLocation &&
        historyLocation.state &&
        historyLocation.state.searchQueryData
      ) {
        const fold2Copy = { ...fold2 };
        const rightContainerCopy = { ...fold2.rightContainer };
        rightContainerCopy["locations"] = [
          historyLocation.state.searchQueryData.location,
        ];
        setFold2({ ...fold2Copy, rightContainer: rightContainerCopy });
      }
      setServerResponse(false);
      setLoading(false);
    }
  }, [serverResponse]);

  useEffect(() => {
    if (location) {
      setLoading(true);
      setIsError(false);
      fetchContent(
        CONTACT_PAGE_URL,
        {},
        {
          category: category,
          latitude: location.latitude,
          longitute: location.longitude,
        }
      ).then((response) => {
        if (response) {
          if (response.code === 2000) {
            const data = response.data;
            if (data) {
              setFold1(data.folds[0]);
              setFold2(data.folds[1]);
            }
            setServerResponse(true);
          } else {
            setIsError(true);
            setLoading(false);
          }
        } else {
          setIsError(true);
          setLoading(false);
        }
      });
    }
  }, [location, historyLocation.state]);

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  console.log("historyLocation :: ", historyLocation);

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <div>
      <Slider ref={slideShowRef} {...settings}>
        <ContactUs
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          data={fold1}
          translations={translations}
        />
        <VisitUs
          data={fold2}
          handleBlur={handleBlur}
          setLocation={setLocation}
          handleFocus={handleFocus}
          error={error}
          currentLocation={location}
          translations={translations}
        />
      </Slider>
    </div>
  ) : (
    <MobileContact
      {...props}
      fold1={fold1}
      fold2={fold2}
      category={category}
      currentLocation={location}
      initialSlide={initialSlide}
      error={error}
      setLocation={setLocation}
      initialCase={
        historyLocation.state && historyLocation.state.menu ? false : true
      }
    />
  );
};

export default withRouter(Contact);
