export const capitalize = (string) => {
  if (string && string.trim() && string.length > 0) {
    let updatedString = string.toLocaleLowerCase(["ar", "en"]);
    return (
      updatedString.substring(0, 1).toLocaleUpperCase(["ar", "en"]) +
      updatedString.substring(1)
    );
  } else {
    return string;
  }
};

export const getIconURL = (active, url) => {
  if (url) {
    let iconname = url.substring(url.lastIndexOf("/") + 1);
    if (active)
      return url.substring(0, url.lastIndexOf("/") + 1) + "active" + iconname;
    else return url;
  } else {
    return undefined;
  }
};
export const getMobileIconURL = (url) => {
  if (url) {
    let iconname = url.substring(url.lastIndexOf("/") + 1);
    return url.substring(0, url.lastIndexOf("/") + 1) + "mobile-" + iconname;
  } else {
    return undefined;
  }
};
export const getFooterIconURL = (url) => {
  if (url) {
    let iconname = url.substring(url.lastIndexOf("/") + 1);
    return url.substring(0, url.lastIndexOf("/") + 1) + "footer-" + iconname;
  } else {
    return undefined;
  }
};

export function getMiles(i) {
  return (i * 0.000621371192).toFixed(2);
}

export function stopScrolling(enable) {
  if (enable) {
    const elts = document.getElementsByTagName("body");
    if (elts && elts.length > 0) {
      elts[0].setAttribute("class", "stop-scrolling");
    }
  } else {
    const elts = document.getElementsByTagName("body");
    if (elts && elts.length > 0) {
      elts[0].removeAttribute("class", "stop-scrolling");
    }
  }
}

export const formatTimings = (timings) => {
  let arrTimings = [];
  if (timings === null) return arrTimings;
  try {
    arrTimings = JSON.parse(timings);
  } catch (error) {
    console.log(error);
  }
  return arrTimings;
};
