import { PEACH_SHODOW_COLOR } from 'global/constants';
import React from 'react';

const Wrapper = props => {

    const {
        backgroundColor = "#EAEAEA", id = "wrapper", boxShadow, height = "fit-content",
        width = "fit-content", borderRadius = "30px", ...rest } = props;

    const styles = {
        rectangle: {
            boxShadow: boxShadow,
            width: width,
            height: height,
            borderRadius: borderRadius,
            overflow: 'hidden',
            backgroundColor: backgroundColor,
        },
    }

    return (
        <>
            <div style={{ margin: '5px', height: height }}>
                <div style={styles.rectangle} id={id}>
                    {props.children}
                </div>
            </div>
        </>
    )



}

export default Wrapper;