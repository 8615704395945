import axios from "axios";
import Fallback from "components/Fallback";
import Wrapper from "components/Wrapper";
import { fetchContent } from "global/api";
import {
  BASE_REDIRECT_URL,
  FETCH_PRIVACY_POLICY,
  FETCH_TERMS_AND_CONDITIONS,
  GRAY_FOOTER_COLOR,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import MobileTCPrivacy from "./Mobile";

const TCPrivacy = (props) => {
  const { tc = false, pp = false, responsive, translations } = props;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "calc(100% - 2em)",
      marginTop: "4em",
    },
    leftContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "6em",
      flexDirection: "column",
      flex: "20%",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "70%",
      marginLeft: "3em",
      marginRight: "3em",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  };

  const [leftContainer, setLeftContainer] = useState();
  const [rightContainer, setRightContainer] = useState();
  const [html, setHtml] = useState();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let URLToHit;
    if (tc) {
      URLToHit = FETCH_TERMS_AND_CONDITIONS;
    } else if (pp) {
      URLToHit = FETCH_PRIVACY_POLICY;
    }
    const lang = localStorage.getItem("locale");
    fetchContent(URLToHit, {}).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setLeftContainer(data.leftContainer);
            setRightContainer(data.rightContainer);
            const rightContainer = data.rightContainer;

            // if (rightContainer.description.description) {
            //   setHtml(`<div style="margin-top:1rem; direction:${
            //     lang === "en" ? "ltr" : "rtl"
            //   }; color: ${GRAY_FOOTER_COLOR};">
            //         ${decodeURI(rightContainer.description.description)}
            //         </div>`);
            // } else {
            //   setHtml("<div />");
            // }

            let htmlUrl = rightContainer?.description?.mediaUrl;
            if (htmlUrl && htmlUrl.includes(BASE_REDIRECT_URL)) {
              const origin = window.origin;
              htmlUrl = htmlUrl.replace(BASE_REDIRECT_URL, origin);
            }
            axios
              .get(htmlUrl)
              .then((data) => {
                setHtml(` <div style="margin-top:1rem;direction:${
                  lang === "en" ? "ltr" : "rtl"
                };
                                color: ${GRAY_FOOTER_COLOR};">
                                ${data.data}</div>`);
                setLoading(false);
              })
              .catch((data) => {
                setLoading(false);
                setHtml(`
                                <div style="text-align: ${
                                  responsive
                                    ? lang === "en"
                                      ? "left"
                                      : "right"
                                    : "center"
                                };margin-top:1rem;
                                color: ${GRAY_FOOTER_COLOR};">
                                ${
                                  translations[
                                    "Unable to fetch data at this moment."
                                  ]
                                }</div>`);
              });
          }
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setLoading(false);
      }
    });
  }, [tc, pp]);

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  function createMarkup() {
    return { __html: html };
  }

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div className="title">{rightContainer.description.displayTitle}</div>
        <br />
        <div className="Line-2"></div>
      </div>
      <div style={styles.rightContainer}>
        <div id="htmlContainer" className="tcpp">
          <div
            style={{
              padding: "1.5rem",
              paddingTop: 0,
            }}
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <MobileTCPrivacy
      leftContainer={leftContainer}
      createMarkup={createMarkup}
      rightContainer={rightContainer}
    />
  );
};

export default withRouter(TCPrivacy);
