import React, { useEffect, useRef, useState } from "react";
import Card from "components/Card";
import Slider from "react-slick";
import classes from "./SliderOfferSection.module.css";
import { withRouter } from "react-router-dom";

const locale = localStorage.getItem("locale");

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 2, // Two rows
  slidesPerRow: 2, // Two columns
  rtl: locale === "ar",
  responsive: [
    {
      breakpoint: 1192, // Apply settings below 768px screen width
      settings: {
        slidesPerRow: 1, // Display one element per row
      },
    },
  ],
};

function SliderOffersSection({ items, translations, fromGeneric }) {
  const slideRef = useRef();
  const [cardItems, setCardItems] = useState([]);

  const nextHandler = () => {
    if (slideRef && slideRef.current) {
      slideRef.current.slickNext();
    }
  };

  const prevHandler = () => {
    if (slideRef && slideRef.current) {
      slideRef.current.slickPrev();
    }
  };

  useEffect(() => {
    setCardItems(items);
    slideRef.current.slickGoTo(0);
  }, [items]);

  return (
    <div
      style={{ ...(!fromGeneric && { height: "414px" }) }}
      className={classes.cardContainer}
    >
      {cardItems?.length > 4 && (
        <div>
          <button onClick={prevHandler} className={classes.nextButton}>
            &lt;
          </button>
        </div>
      )}
      <div className={classes.sliderContainer}>
        <Slider {...settings} ref={slideRef}>
          {cardItems?.map((item, index) => {
            return (
              <div key={index} style={{ display: "flex" }}>
                <Card
                  fromGeneric={fromGeneric}
                  translations={translations}
                  image={item?.thumbnail}
                  redirectUrl={item?.offerContext}
                  heading={item?.heading}
                  subHeading={item?.overlayText}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      {cardItems?.length > 4 && (
        <div>
          <button onClick={nextHandler} className={classes.nextButton}>
            &gt;
          </button>
        </div>
      )}
    </div>
  );
}

export default withRouter(SliderOffersSection);
