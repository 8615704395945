import Tabs from "components/Tabs";
import SliderOffersSection from "container/SliderOffersSection";
import MobileSliderOffersSection from "container/SliderOffersSection/Mobile";
import React, { useRef, useState, useEffect } from "react";
import classes from "./OfferSection.module.css";

import { fetchContent } from "global/api";
import {
  FETCH_ALL_OFFERS,
  FETCH_OFFERS_BY_CATEGORY_ID,
} from "global/constants";
import { withRouter } from "react-router-dom";
import Card from "components/Card";

const OffersSection = ({ responsive, translations, fromGeneric }) => {
  const [activeTab, setactiveTabParent] = useState(0);
  const [offers, setOffers] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);

    let url = FETCH_OFFERS_BY_CATEGORY_ID;

    if (!activeTab) return;

    if (activeTab === "FETCH_ALL_OFFERS") url = FETCH_ALL_OFFERS;

    fetchContent(url, {
      ...(activeTab != "FETCH_ALL_OFFERS" && { catId: activeTab }),
    }).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setOffers(data);
          }
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setLoading(false);
      }
    });
  }, [activeTab]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>{translations?.["Offers"]}</div>

      <div className={classes.tabs}>
        <Tabs
          setactiveTabParent={setactiveTabParent}
          responsive={responsive}
          translations={translations}
        />
      </div>

      {/* {!responsive && (
        <div className={classes.cardContainer} id="OffersSection">
          <SliderOffersSection translations={translations} items={offers} />
        </div>
      )} */}

      {!responsive && offers?.length > 0 && (
        <div className={classes.cardContainer} id="OffersSection">
          {offers?.length > 1 && (
            <SliderOffersSection
              translations={translations}
              items={offers}
              fromGeneric={fromGeneric}
            />
          )}

          {offers?.length === 1 && (
            <div style={{ display: "flex", height: "400px", marginTop: "8px" }}>
              <Card
                translations={translations}
                image={offers[0]?.thumbnail}
                redirectUrl={offers[0]?.offerContext}
                heading={offers[0]?.heading}
                subHeading={offers[0]?.overlayText}
              />
            </div>
          )}
        </div>
      )}

      {responsive && (
        <div className={classes.cardContainer} id="MobileOfferSection">
          <MobileSliderOffersSection
            translations={translations}
            items={offers}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(OffersSection);
