import Wrapper from 'components/Wrapper';
import { fetchContent } from 'global/api';
import {
    FETCH_TUTORIAL_CONTENT, GRAY_FOOTER_COLOR,
    MOBILE_RED_PRIMARY_COLOR, OPAQUE_COLOR, WHITE_PRIMARY_COLOR
} from 'global/constants';
import { getMobileIconURL, stopScrolling, toggleScrolling } from 'global/utils';
import React, { useEffect, useState } from 'react';
import Fallback from 'components/Fallback';
import { useLocation, withRouter } from 'react-router-dom';
import VideoBox from 'components/VideoBox';

const MobileTutorials = props => {

    const { translations, leftContainer, responsive } = props;
    const [subCategory, setSubCategory] = useState();
    const [video, setVideo] = useState();
    const location = useLocation()
    const [rightContainer, setRightContainer] = useState();

    useEffect(() => {
        if (location && !location.state) {
            setRightContainer();
            setSubCategory();
            setVideo();
        }
    }, [location])

    const [searchLoading, setSearchLoading] = useState(true);

    const selectMenuItemHandler = (item) => {
        fetchVideos(item)
    }
    // console.log('props location ', props.location)
    const fetchVideos = (item) => {
        setSearchLoading(true);
        fetchContent(FETCH_TUTORIAL_CONTENT, { subCategory: item.title }).then((response) => {
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        setRightContainer(data.rightContainer);
                        props.history.push('/assistance/tutorials', {
                            subCategory: item
                        })
                        setSubCategory(item)
                    }
                    setTimeout(() => {
                        setSearchLoading(false)
                    }, 500);
                }
            } else {
                setSearchLoading(false)
            }
        });
    }

    const [videoLoading, setVideoLoading] = useState(false);

    useEffect(() => {
        if (video) {
            stopScrolling(true);
            if (!video.mediaUrl)
                setVideoLoading(true);
        } else {
            stopScrolling(false);
            setVideoLoading(false);
        }
    }, [video])



    useEffect(() => {
        let timeout;
        if (videoLoading) {
            timeout = setTimeout(() => {
                setVideoLoading(false);
            }, 5000);
        }
        return () => clearTimeout(timeout);
    }, [videoLoading])

    return <> {!(rightContainer && subCategory) ? <div className="mobile-container">
        <div className="mobile-title">
            {leftContainer.title}
        </div>
        <div>
            <div className="faq_grid">
                {leftContainer.menu && leftContainer.menu.map(menu => {
                    return (<div style={{ cursor: 'pointer' }}>
                        <Wrapper backgroundColor={OPAQUE_COLOR} borderRadius="12px" >
                            <div style={{ width: 100, height: 100, display: 'flex' }}
                                onClick={() => selectMenuItemHandler(menu)}>
                                <img src={menu.mediaUrl} style={{ margin: 'auto', width: 60 }} />
                            </div>
                        </Wrapper>
                        <div style={{
                            marginLeft: 5, marginTop: 10, textOverflow: 'ellipsis', overflow: 'hidden',
                            textAlign: 'center', color: GRAY_FOOTER_COLOR
                        }}>
                            {menu.displayTitle}
                        </div>
                    </div>)
                })}
            </div>
        </div>
    </div > : searchLoading ? <Fallback loading={searchLoading}
        color={responsive ? MOBILE_RED_PRIMARY_COLOR : WHITE_PRIMARY_COLOR} /> :
        <div className="mobile-container">
            <div className="mobile-title">
                {subCategory.displayTitle}
            </div>
            <div style={{
                display: "grid", gap: 10, justifyContent: 'start',
                gridTemplateColumns: "repeat(auto-fill, 190px)"
            }}>
                {rightContainer.videos.map(video => {
                    return <VideoBox width={190} height={190}
                        borderRadius="12px"
                        title={video.displayTitle} onClick={() => setVideo(video)}
                        description={video.description} backgroundColor={OPAQUE_COLOR} />
                })}
            </div>
            {video && <div className="video-overlay">
                <div style={{
                    padding: '1rem', marginTop: '50%', alignItems: 'center',
                    height: '100%', display: 'flex', flexDirection: 'column'
                }}>
                    <div className="close-btn" style={{ width: '100%' }} onClick={() => setVideo()}>&#10006;</div>
                    <div style={{
                        display: 'flex', justifyContent: 'center',
                        maxHeight: 'calc(100vh - 23em)', overflow: 'auto'
                    }}>
                        {videoLoading && <Fallback loading={videoLoading}
                            style={{ position: "absolute" }}
                            color={WHITE_PRIMARY_COLOR} height="20px" />}
                        {video.mediaUrl ? <video autoPlay="true"
                            controls
                            playsInline
                            loop
                            onLoadCapture={() => setVideoLoading(false)}
                            width="100%" height="300px">
                            <source src={video.mediaUrl} type="video/mp4" />
                        </video> : <iframe allowFullScreen={true}
                            onLoadCapture={() => setVideoLoading(false)}
                            loading="lazy" src={video.redirectUrl}
                            height="300px" style={{ width: "calc(100vw - 20px)" }}
                        />}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}



export default withRouter(MobileTutorials);