import React, { useEffect, useRef, useState } from "react";
import MobileCard from "components/Card/Mobile";
import Slider from "react-slick";
import classes from "./SliderOfferSection.module.css";

const locale = localStorage.getItem("locale");

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 2, // Two rows
  slidesPerRow: 1, // Two columns
  rtl: locale === "ar",
};

export default function MobileSliderOffersSection({
  items,
  translations,
  fromGeneric,
}) {
  const slideRef = useRef();
  const [cardItems, setCardItems] = useState([]);

  useEffect(() => {
    setCardItems(items);
    slideRef.current.slickGoTo(0);
  }, [items]);

  return (
    <div
      style={{ ...(!fromGeneric && { height: "300px" }) }}
      className={classes.cardContainer}
    >
      <div className={classes.sliderContainer}>
        <Slider {...settings} ref={slideRef}>
          {cardItems?.map((item, index) => {
            return (
              <div key={index} style={{ display: "flex" }}>
                <MobileCard
                  fromGeneric={fromGeneric}
                  translations={translations}
                  image={item?.thumbnail}
                  redirectUrl={item?.offerContext}
                  heading={item?.heading}
                  subHeading={item?.overlayText}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
