import { GRAY_FOOTER_COLOR, WHITE_PRIMARY_COLOR } from "global/constants";
import { getFooterIconURL } from "global/utils";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileFooter from "./MobileFooter";

const Footer = (props) => {
  const [showStoreIcons, setShowStoreIcons] = useState(true);
  const { responsive, footer, innerRef } = props;
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname === "/home") setShowStoreIcons(false);
    else setShowStoreIcons(true);
  }, [location]);

  const styles = {
    footerContainer: {
      backgroundColor: GRAY_FOOTER_COLOR,
      display: "flex",
      width: "100%",
      fontSize: "14px",
      flexDirection: "row",
      justifyContent: "space-around",
      height: "5em",
      position: "fixed",
      bottom: 0,
      padding: "0px 5px",
      color: WHITE_PRIMARY_COLOR,
    },
    endorsedContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    storeContainer: {
      display: "flex",
      alignItems: "center",
    },
    storeContainer1: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "0.5em",
    },
    image: {
      margin: "0px 15px",
      cursor: "pointer",
    },
  };

  const footerIconHandler = (url) => {
    if (url) window.open(url);
  };

  return !responsive ? (
    <div ref={innerRef} style={styles.footerContainer}>
      {!showStoreIcons && (
        <div style={styles.endorsedContainer}>
          <div>{footer[0].displayTitle}</div>
        </div>
      )}
      {showStoreIcons && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                color: WHITE_PRIMARY_COLOR,
                fontSize: "14px",
                lineHeight: "1.56",
              }}
            >
              {footer[2].description}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  src={getFooterIconURL(footer[2].mediaUrl)}
                  onClick={() => footerIconHandler(footer[2].redirectUrl)}
                  style={{
                    cursor: "pointer",
                    maxWidth: "120px",
                    width: "auto",
                  }}
                  alt={footer[2].title}
                />
                <img
                  src={getFooterIconURL(footer[1].mediaUrl)}
                  onClick={() => footerIconHandler(footer[1].redirectUrl)}
                  style={{
                    cursor: "pointer",
                    maxWidth: "120px",
                    marginLeft: 5,
                    width: "auto",
                  }}
                  alt={footer[1].title}
                />
                <img
                  src={footer[3].mediaUrl}
                  className="qrcode"
                  style={{ maxWidth: 40, width: "auto", marginLeft: 5 }}
                  alt={footer[3].title}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div style={styles.storeContainer}>
        <div style={{ display: "flex" }}>
          <img
            src={footer[4].mediaUrl}
            height={30}
            onClick={() => footerIconHandler(footer[4].redirectUrl)}
            className="social_footer_icons"
            alt={footer[4].title}
          />
          <img
            src={footer[5].mediaUrl}
            height={30}
            onClick={() => footerIconHandler(footer[5].redirectUrl)}
            className="social_footer_icons"
            alt={footer[5].title}
          />
          <img
            src={footer[6].mediaUrl}
            height={30}
            onClick={() => footerIconHandler(footer[6].redirectUrl)}
            className="social_footer_icons"
            alt={footer[6].title}
          />
        </div>
      </div>
      <div style={styles.storeContainer}>
        <div>
          <Link
            to="/termsandconditions"
            style={{ textDecoration: "none", color: WHITE_PRIMARY_COLOR }}
          >
            {footer[7].displayTitle}&nbsp;&nbsp;
          </Link>{" "}
          |
          <Link
            style={{ textDecoration: "none", color: WHITE_PRIMARY_COLOR }}
            to="/privacypolicy"
          >
            &nbsp;&nbsp;{footer[8].displayTitle}
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <MobileFooter
      footerIconHandler={footerIconHandler}
      {...props}
      showStoreIcons={showStoreIcons}
    />
  );
};

export default Footer;
