import Fallback from "components/Fallback";
import { GRAY_FOOTER_COLOR, RED_PRIMARY_COLOR } from "global/constants";
import { useEffect, useState } from "react";

const DiscoverMobileFold1 = props => {

    const { pauseSlide, resumeSlide, handleVideosCompleted, data } = props;

    const [leftContainer, setLeftContainer] = useState();
    const [rightContainer, setRightContainer] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { leftContainer, rightContainer } = data;
        const { assets = [] } = rightContainer;
        let modifiedAssets = [];
        if (assets) {
            modifiedAssets = assets.map((asset, index) => {
                return { ...asset, sequenceNo: (index + 1) + "" }
            })
        }
        const modifiedRightContainer = { ...rightContainer, assets: modifiedAssets };
        setLeftContainer(leftContainer)
        setRightContainer(modifiedRightContainer)
        setLoading(false)
    }, []);

    const [controls, setControls] = useState(true);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "column",
        },
        description: {
            fontSize: '14px', color: GRAY_FOOTER_COLOR, fontWeight: 600
        },
    }

    const [currentVideoId, setCurrentVideoId] = useState("1");
    const [currentVideo, setCurrentVideo] = useState();

    useEffect(() => {
        let video;
        if (rightContainer && rightContainer.assets) {
            video = rightContainer.assets.find(asset => asset.sequenceNo == currentVideoId);
        }
        setCurrentVideo(video)
    }, [currentVideoId, rightContainer])

    useEffect(() => {
        if (document.querySelector(`video[id="${currentVideoId}"]`))
            document.querySelector(`video[id="${currentVideoId}"]`).play()
    }, [currentVideo])



    const handleVideoAutoPlay = (id) => {
        if (rightContainer && rightContainer.assets && rightContainer && rightContainer.assets.length >= id) {
            setCurrentVideoId(id + "");
        }
        else {
            setTimeout(() => {
                setCurrentVideoId("1")
            }, 1000);
            if (handleVideosCompleted) handleVideosCompleted(true)
        }
    }
    const onEndedHandler = event => {
        const id = +event.target.id + 1;
        handleVideoAutoPlay(id);
    }
    return (loading ? <Fallback loading={loading} /> : <div style={styles.container}>
        <div style={styles.description}>
            {leftContainer.description}
        </div>
        <div style={{ padding: '1rem 0' }} >
            {currentVideo && <video muted controls={controls} autoPlay style={{ width: '100%', height: 'auto' }}
                id={currentVideoId} src={currentVideo.mediaUrl} onMouseEnter={() => setControls(true)}
                onEnded={onEndedHandler} onMouseLeave={() => setControls(false)}
            />}
        </div>
        <div style={{
            display: 'flex', flexDirection: 'column',
        }} id="gridContainer">
            {rightContainer && rightContainer.assets.map(asset => {
                return <Grid  {...asset} currentVideoId={currentVideoId}
                    handleClick={() => handleVideoAutoPlay(+asset.sequenceNo)} />
            })}
        </div>
    </div >);
}

const Grid = (props) => {

    const { sequenceNo, description, displayTitle, handleClick, currentVideoId } = props;

    return (<div style={{
        display: 'flex', cursor: 'pointer', fontSize: 14, marginBottom: '1rem',
        flexDirection: 'column', color: GRAY_FOOTER_COLOR, lineHeight: '1.53'
    }} onClick={handleClick}>
        <div style={{
            fontWeight: 600,
            width: '100%', display: 'flex',
            color: sequenceNo == currentVideoId ? RED_PRIMARY_COLOR : GRAY_FOOTER_COLOR
        }}>
            {sequenceNo}.&nbsp; <span style={{ textTransform: 'uppercase' }}>{displayTitle}</span>
        </div>
        <div style={{ display: 'flex', color: GRAY_FOOTER_COLOR, width: '100%', fontWeight: 'normal' }}>
            {description}
        </div>
    </div>
    )
}
export default DiscoverMobileFold1;