import React from 'react';
import Loader from 'react-loader-spinner';

const Fallback = props => {
    const { loading = true, color = "#fff", style = {} } = props;

    return (
        <div style={{
            textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center',
            height: 'calc(100vh - 12em)', ...style,
        }}>
            <Loader type="ThreeDots" color={color} width={100} height={50} visible={loading} />
        </div>
    )
}

export default Fallback