import ComingSoon from "components/ComingSoon";
import LabelBox from "components/LabelBox";
import Wrapper from "components/Wrapper";

import {
  BATCH_LENGTH,
  FIRST_BATCH,
  GRAY_COLOR,
  GRAY_FOOTER_COLOR,
  PEACH_SHODOW_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useState } from "react";
import Fallback from "components/Fallback";
import MyGoogleMap from "components/MyGoogleMap";
import { withRouter } from "react-router-dom";
import { logEvent } from "firebase-analytics";
import { formatTimings } from "global/utils";

const CategoryTemplate = (props) => {
  const { locations, currentLocation, translations, setLocation } = props;
  const [endItem, setEndItem] = useState(0);
  const [batchLoading, setBatchLoading] = useState(true);
  const [batches, setBatches] = useState([]);
  const [showMore, setShowMore] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState();

  useEffect(() => {
    const totalItems = locations.length;
    const newBatches = [];
    if (totalItems > 0) {
      if (totalItems < FIRST_BATCH) newBatches[0] = totalItems;
      else newBatches[0] = FIRST_BATCH;
      const batchCount = Math.floor((totalItems - FIRST_BATCH) / BATCH_LENGTH);
      for (let index = 0; index < batchCount; index++) {
        newBatches.push(BATCH_LENGTH);
      }
      const remainingItems = (totalItems - FIRST_BATCH) % BATCH_LENGTH;
      if (remainingItems >= 0) newBatches.push(remainingItems);
      setBatches(newBatches);
      setEndItem(newBatches[0] ? newBatches[0] : 0);
      setShowMore(newBatches[1] ? newBatches[1] : 0);
    }
    setBatchLoading(false);
  }, []);

  const updateBatch = () => {
    setBatchLoading(true);
    setTimeout(() => {
      setEndItem((prevEndItem) => prevEndItem + batches[currentBatch + 1]);
      setShowMore(batches[currentBatch + 2] ? batches[currentBatch + 2] : 0);
      setCurrentBatch((prevBatch) => prevBatch + 1);
      setBatchLoading(false);
      document.getElementById("template_view").scrollIntoView({
        behavior: "smooth",
      });
    }, 1000);
  };

  const backButtonhandler = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          if (
            location.latitude !== currentLocation.latitude ||
            location.longitude !== currentLocation.longitude
          )
            setLocation(location);
        },
        (error) => {
          const location = {
            latitude: 0.0,
            longitude: 0.0,
          };
          if (
            location.latitude !== currentLocation.latitude ||
            location.longitude !== currentLocation.longitude
          )
            setLocation(location);
        }
      );
    }
    setSelectedLocation();
  };

  return (
    <>
      {locations.length === 0 && batches.length === 0 ? (
        <ComingSoon translations={translations} />
      ) : selectedLocation ? (
        <>
          <div
            style={{
              marginTop: 5,
              padding: "0px 20px 0px 0px",
              marginBottom: 20,
            }}
          >
            <div
              style={{ marginTop: "-1em", height: "2em", marginBottom: "1em" }}
            >
              <img
                src="/iPayV2/icons/backicon.svg"
                style={{ cursor: "pointer", width: 30 }}
                onClick={backButtonhandler}
              />
            </div>
            <DetailedStore
              translations={translations}
              selectedLocation={selectedLocation}
              currentLocation={currentLocation}
              setLocation={setLocation}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
            <div
              style={{
                marginTop: 5,
                marginBottom: 20,
                display: "grid",
                padding: "0px 20px 0px 0px",
                gridTemplateColumns: "auto auto auto",
                gap: "20px",
              }}
              id="TemplateGridContainer"
            >
              {locations.slice(0, endItem).map((location, index) => (
                <Store
                  translations={translations}
                  location={location}
                  id={location.id}
                  key={Math.random() + ""}
                  setSelectedLocation={setSelectedLocation}
                  isNearest={
                    index === 0 &&
                    (currentLocation.latitude !== 0.0 ||
                      currentLocation.longitude !== 0.0)
                  }
                />
              ))}
              <div id="template_view"></div>
            </div>
          </div>
          {showMore !== 0 ? (
            batchLoading ? (
              <Fallback
                loading={batchLoading}
                color={RED_PRIMARY_COLOR}
                style={{ height: "3rem" }}
              />
            ) : (
              <div
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  margin: "20px auto 0px auto",
                }}
              >
                <LabelBox
                  translations={translations}
                  border={`1px solid ${RED_PRIMARY_COLOR}`}
                  embededActive={true}
                  labelText={`${translations["Show more"]}(${showMore})`}
                  handleClick={updateBatch}
                  active={true}
                />
              </div>
            )
          ) : undefined}
        </>
      )}
    </>
  );
};

const Store = (props) => {
  const {
    isNearest = true,
    id,
    location,
    setSelectedLocation,
    translations,
    key,
  } = props;

  return (
    <div style={{ textAlign: "center" }} key={key}>
      <Wrapper>
        <div
          style={{
            height:
              location.regularTimings !== "" &&
              location.regularTimings.toUpperCase() !== "NA"
                ? 300
                : 240,
            width:
              location.regularTimings !== "" &&
              location.regularTimings.toUpperCase() !== "NA"
                ? 240
                : 210,
          }}
          id="wrapper"
        >
          {isNearest ? (
            <div
              style={{
                width: "65%",
                height: 30,
                margin: "auto",
                textAlign: "center",
                borderRadius: "0px 0px 30px 30px",
                backgroundColor: RED_PRIMARY_COLOR,
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: WHITE_PRIMARY_COLOR,
                  lineHeight: 2.12,
                }}
              >
                {translations["Nearest to you"]}
              </div>
            </div>
          ) : (
            <div style={{ height: 30 }}></div>
          )}
          <div
            style={{
              fontSize: "20px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: "bold",
              color: RED_PRIMARY_COLOR,
              margin: "10px 10px",
              display: "-webkit-box",
              height: "54px",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {location.displayTitle}
          </div>

          {location.regularTimings &&
            location.regularTimings !== "" &&
            location.regularTimings.toUpperCase() !== "NA" && (
              <div
                style={{
                  fontSize: "14px",
                  height: "32px",
                  color: GRAY_FOOTER_COLOR,
                  // margin: "10px 0px",
                  marginBottom: "30px",
                }}
              >
                <div>Timings:</div>
                <div
                  style={{
                    maxHeight: 40,
                    overflowY: "auto",
                    margin: "0px 10px",
                  }}
                  className="template_visitus"
                >
                  {location.regularTimings === "" ||
                  location.regularTimings.toUpperCase() === "NA" ? (
                    location.regularTimings
                  ) : (
                    <div>
                      {formatTimings(location.regularTimings).map((timing) => (
                        <div>{timing}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

          <div
            style={{
              fontSize: "14px",
              height: "32px",
              color: GRAY_FOOTER_COLOR,
              marginBottom: "25px",
              marginTop: 15,
            }}
          >
            <div>Address:</div>
            <div
              style={{
                margin: "0px 10px",
                overflowY: "auto",
                maxHeight: 40,
              }}
              className="template_visitus"
            >
              {location.address}
            </div>
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              width: "75%",
              margin: "40px auto 20px auto",
            }}
          >
            <LabelBox
              handleClick={() => {
                logEvent("view_on_map", {
                  search_term: location.displayTitle,
                });
                setSelectedLocation(location);
              }}
              height="20px"
              border={`0.75px solid ${RED_PRIMARY_COLOR}`}
              embededActive={true}
              padding="10px 10px"
              active={true}
              marginRight="2px"
              icon={true}
              iconname="/iPayV2/icons/location.svg"
              labelText={translations["View on map"]}
              color={RED_PRIMARY_COLOR}
              backgroundColor={GRAY_COLOR}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const DetailedStore = (props) => {
  const { currentLocation, selectedLocation, translations, setLocation } =
    props;
  const [error, setError] = useState(false);

  useEffect(() => {
    var errorTimeout;
    if (error) {
      errorTimeout = setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      clearTimeout(errorTimeout);
    }

    return () => clearTimeout(errorTimeout);
  }, [error]);

  const styles = {
    answerLine: {
      minHeight: "1px",
      backgroundColor: " rgba(112, 112, 112, 0.16)",
      margin: "4px 0px 4px 0px",
      lineHeight: "1.67",
    },
    smallImages: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    smallImagesText: {
      fontSize: 10,
      lineHeight: 1.7,
      marginTop: "0.5em",
    },
    inputWrapper1: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      animation: "up 0.5s",
      animationTimingFunction: "ease-in-out",
    },
    input: {
      display: "flex",
      flexDirection: "column",
    },
  };
  return (
    <Wrapper boxShadow={"1px -1px 0px 0px #e0e0e0"}>
      <div
        className="detailedStore"
        style={{
          display: "flex",
          height: "calc(100vh - 14.5em)",
          width: 770,
          position: "relative",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 14.5em)",
            backgroundColor: GRAY_COLOR,
            width: "30%",
            fontFamily: "'Source Sans Pro'",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1.5em",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                minHeight: 60,
                fontWeight: "bold",
                color: RED_PRIMARY_COLOR,
                margin: "0 0 0.5em 0",
              }}
            >
              {selectedLocation.displayTitle}
            </div>
            {/* <div style={{ ...styles.answerLine }}></div> */}
            {selectedLocation.regularTimings === "" &&
              selectedLocation.regularTimings.toUpperCase() === "NA" && (
                <div
                  style={{
                    fontSize: "14px",
                    color: GRAY_FOOTER_COLOR,
                  }}
                >
                  <div style={{ fontWeight: "bold", margin: "0.5em 0" }}>
                    Timings:
                  </div>
                  <div
                    style={{
                      maxHeight: 70,
                      overflowY: "auto",
                      margin: "1em 10px",
                    }}
                    className="template_visitus"
                  >
                    {selectedLocation.regularTimings === "" ||
                    selectedLocation.regularTimings === "NA" ? (
                      selectedLocation.regularTimings
                    ) : (
                      <div>
                        {formatTimings(selectedLocation.regularTimings).map(
                          (timing) => (
                            <div>{timing}</div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {selectedLocation.exceptionTimings
                      ? selectedLocation.exceptionTimings
                      : ""}
                  </div>
                </div>
              )}
            <div style={{ ...styles.answerLine }}></div>
            <div
              style={{
                fontSize: "14px",
                color: GRAY_FOOTER_COLOR,
                margin: "0.5em 0 0 0",
              }}
            >
              <span style={{ fontWeight: "bold" }}> Address:</span>
              <div
                style={{
                  margin: "1em 0",
                  maxHeight: "6rem",
                  overflowY: "auto",
                }}
                className="template_visitus"
              >
                {selectedLocation.address}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1em",
              }}
            >
              <LabelBox
                height={"16px"}
                id="directions"
                marginRight="6px"
                padding="10px 10px"
                active={true}
                backgroundColor={RED_PRIMARY_COLOR}
                color={WHITE_PRIMARY_COLOR}
                labelText={translations["Get directions"]}
                icon={true}
                iconname="/iPayV2/icons/directions.svg"
              />
            </div>
          </div>
        </div>
        <div
          className="detailedStoremap"
          style={{
            display: "flex",
            zIndex: 1,
            position: "relative",
            flexDirection: "column",
            width: "70%",
            height: "calc(100vh - 15.7em)",
          }}
        >
          {error && (
            <div
              style={{
                ...styles.inputWrapper1,
                marginTop: "2px",
                position: "absolute",
                zIndex: 10,
                top: "0.5",
              }}
            >
              <div style={{ ...styles.input, marginRight: 20 }}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: RED_PRIMARY_COLOR,
                  }}
                >
                  {error}
                </span>
              </div>
            </div>
          )}
          <MyGoogleMap
            translations={translations}
            setError={setError}
            currentLocation={currentLocation}
            selectedLocation={selectedLocation}
            setLocation={setLocation}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(CategoryTemplate);
