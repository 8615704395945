import { GRAY_FOOTER_COLOR } from 'global/constants';
import React from 'react';
import { Link } from 'react-router-dom';
const ErrorPage = (props) => {

    const { message = "Something went wrong. Please contact Admin", responsive, translations } = props
    return (<div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
        color: GRAY_FOOTER_COLOR, flexDirection: 'column',
        fontSize: responsive ? 14 : 24
    }}>
        <div>
            {translations[message]}
        </div>
        <div>
            {translations["Please visit the"]}<span>&nbsp;
                <Link to={'/'} style={{ color: GRAY_FOOTER_COLOR }}>
                    Home Page
                </Link>
            </span>
        </div>
    </div>)
}

export default ErrorPage