import Fallback from "components/Fallback";
import LabelBox from "components/LabelBox";
import { fetchContent } from "global/api";
import {
  FETCH_ALL_CATEGORIES,
  GRAY_FOOTER_COLOR,
  HOME_PAGE_URL,
  maxDots,
  MOBILE_RED_PRIMARY_COLOR,
  QA_TYPE_IMAGE,
  QA_TYPE_VIDEO,
  RED_PRIMARY_COLOR,
  TRACKING_ID,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Mobile from "./Mobile";
import Slider from "react-slick";
import { logEvent } from "firebase-analytics";
import OffersSection from "container/OffersSection";
import classes from "./home.module.css";

const Home = (props) => {
  const { locale, footer, translations, responsive } = props;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "45px",
      width: "100%",
      marginTop: "2em",
      height: "calc(100% - 1em)",
      alignItems: "center",
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    middleContainer: {
      display: "flex",
      alignItems: "center",
    },
    subHeading: {
      alignItems: "center",
      fontSize: "24px",
      lineHeight: "1.20",
      marginBottom: "10px",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      flex: "30%",
      maxHeight: "calc(100vh - 16em)",
      marginTop: "32px",
    },
    storeContainer: {
      display: "flex",
      alignItems: "center",
    },
  };

  const [videoPausedByUser, setVideoPausedByUser] = useState(false);

  const settings = {
    dots: false,
    dotsClass: "slick-dots-home",
    autoplaySpeed: 10000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => {
      return (
        <div
          className={`dot-home dot-index-${i} ${
            i === maxDots - 1 ? "n-small-1" : ""
          } 
                    ${i > maxDots - 1 ? "hide" : ""}`}
        ></div>
      );
    },
    afterChange: (index) => {
      // setCurrentSlide(index);
      const el = document.querySelector(".slick-current video");
      if (el && !videoPausedByUser) {
        el.play();
      }
    },
  };

  const [leftContainer, setLeftContainer] = useState();
  const [rightContainer, setRightContainer] = useState();
  const [middleContainer, setMiddleContainer] = useState();
  const [showOfferSection, setShowOfferSection] = useState(true);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const slideRef = useRef();

  useEffect(() => {
    setIsError(false);
    logEvent("home_content_load_start");
    fetchContent(HOME_PAGE_URL, {}).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setLeftContainer(data?.leftContainer);
            setRightContainer(data?.rightContainer);
            setMiddleContainer(data?.middleContainer);
            fetchCategories();
          }
          setLoading(false);
          logEvent("home_content_load_success");
        } else {
          setIsError(true);
          setLoading(false);
          logEvent("home_content_load_fail");
        }
      } else {
        setIsError(true);
        setLoading(false);
        logEvent("home_content_load_fail");
      }
    });
  }, []);

  const fetchCategories = () => {
    fetchContent(FETCH_ALL_CATEGORIES, {}).then((response) => {
      if (response) {
        const data = response.data;
        setShowOfferSection(data?.length > 0);
      }
    });
  };

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  const goToLearnMore = () => {
    props.history.push("/discover");
  };

  const nextHandler = () => {
    if (slideRef && slideRef.current) {
      setAutoplay(true);
      slideRef.current.slickNext();
    }
  };

  const prevHandler = () => {
    if (slideRef && slideRef.current) {
      setAutoplay(true);
      slideRef.current.slickPrev();
    }
  };

  const footerIconHandler = (url) => {
    if (url) window.open(url);
  };

  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    autoplay ? slideRef?.current?.slickPlay() : slideRef?.current?.slickPause();
  }, [autoplay, slideRef]);

  const redirectHandler = (redirectUrl) => {
    if (redirectUrl.startsWith("http")) {
      window.open(redirectUrl);
    } else {
      props.history.push(redirectUrl);
    }
  };

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <>
      <div id="home" style={styles.container}>
        <div style={styles.leftContainer} className="left-container">
          <div style={styles.subHeading}>{leftContainer.subtitle}</div>
          <div
            style={{ maxWidth: "100%", alignItems: "center", fontSize: "40px" }}
            className="title"
          >
            {leftContainer.title}
          </div>
        </div>
        <div
          className="middle-container"
          style={{ width: "100%", height: "400px" }}
        >
          <div
            id="homesliderContainer"
            style={{
              width: "100%",
              height: "300px",
            }}
          >
            <Slider
              {...{
                autoplay: true,
                autoplaySpeed: 10000,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                afterChange: (index) => {
                  // setCurrentSlide(index);
                  const el = document.querySelector(".slick-current video");
                  if (el && !videoPausedByUser) {
                    el.play();
                  }
                },
              }}
              ref={slideRef}
            >
              {middleContainer &&
              middleContainer?.assetsMiddle &&
              middleContainer?.assetsMiddle?.length > 0 ? (
                middleContainer?.assetsMiddle?.map((asset, index) => {
                  let content;
                  if (asset?.assestType === QA_TYPE_IMAGE) {
                    content = (
                      <div
                        style={{
                          width: "100px",
                          height: "300px",
                        }}
                        key={index + ""}
                        onClick={() => {
                          if (asset.isRedirected && asset.redirectUrl)
                            return redirectHandler(asset.redirectUrl);
                          else return null;
                        }}
                      >
                        <img
                          style={{
                            height: "400px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                            ...(asset.isRedirected && { cursor: "pointer" }),
                            ...(!asset.isRedirected && { cursor: "default" }),
                          }}
                          src={asset?.image || asset.mediaUrl}
                          // src="https://digital.mv1.in/cdn/iPayV2/icons/KarwaBannerEN.jpg"
                          alt={asset.displayTitle}
                        />
                      </div>
                    );
                  } else if (asset?.assestType === QA_TYPE_VIDEO) {
                    content = (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                        }}
                        key={index + ""}
                      >
                        {asset.mediaUrl && (
                          <video
                            style={{
                              height: "400px",
                              display: "block",
                              margin: "auto",
                              width: "100%",
                              backgroundColor: RED_PRIMARY_COLOR,
                            }}
                            controls
                            src={asset.mediaUrl}
                            id={"video_" + index}
                            onPause={() => {
                              setVideoPausedByUser(true);
                              setAutoplay(true);
                            }}
                            onEnded={() => {
                              setVideoPausedByUser(false);
                              nextHandler();
                            }}
                            alt={asset.displayTitle}
                            onPlay={() => {
                              setAutoplay(true);
                            }}
                          />
                        )}
                      </div>
                    );
                  } else {
                    content = <div key={index + ""}>{asset.description}</div>;
                  }
                  return content;
                })
              ) : (
                <div className="hand-image">
                  <img src={"/iPayV2/icons/hand-image.png"} />
                </div>
              )}
            </Slider>
          </div>
          {middleContainer?.assetsMiddle?.length > 1 && (
            <div
              className={classes.homeSliderButtons}
              onClick={prevHandler}
              style={{
                left: "4%",
                bottom: "28%",
              }}
            >
              <button
                style={{
                  margin: "0px 10px",
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "white",
                }}
                id="prevButton"
              >
                &lt;
              </button>
            </div>
          )}
          {middleContainer?.assetsMiddle?.length > 1 && (
            <div
              className={classes.homeSliderButtons}
              onClick={nextHandler}
              style={{
                position: "relative",
                left: "94%",
                bottom: "37%",
                border: "1px solid #2A292B",
                backgroundColor: "#2A292B",
                borderRadius: "50%",
                height: "36px",
                width: "36px",
                opacity: "0.4",
                cursor: "pointer",
              }}
            >
              <button
                style={{
                  margin: "0px 10px",
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "white",
                }}
                id="prevButton"
              >
                &gt;
              </button>
            </div>
          )}
        </div>
        <div style={styles.rightContainer} id="rightContainer">
          <div className="home_grid_container" style={{ margin: "0px 10%" }}>
            {rightContainer.assets.map((asset, index) => {
              return (
                <Grid
                  title={asset.displayTitle}
                  key={asset.id}
                  description={asset.description}
                  icon={asset.mediaUrl}
                />
              );
            })}
          </div>
        </div>
        {showOfferSection && (
          <OffersSection
            fromGeneric={false}
            translations={translations}
            responsive={responsive}
          />
        )}
      </div>
    </>
  ) : (
    <Mobile
      {...props}
      goToLearnMore={goToLearnMore}
      middleContainer={middleContainer}
      responsive={responsive}
      leftContainer={leftContainer}
      rightContainer={rightContainer}
      settings={settings}
      redirectHandler={redirectHandler}
      setVideoPausedByUser={setVideoPausedByUser}
      showOfferSection={showOfferSection}
    />
  );
};

const Grid = (props) => {
  const { icon, title, description } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontWeight: "normal",
        color: GRAY_FOOTER_COLOR,
        width: "20%",
      }}
    >
      <div style={{ display: "flex", flex: "20%" }} className="home_grid">
        <img
          src={icon}
          alt={title}
          style={{
            maxHeight: "48px",
            maxWidth: "48px",
            height: "auto",
            width: "auto",
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "5px",
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontSize: "16px",
            fontWeight: "700",
            textTransform: "capitalize",
            width: "192",
            lineHeight: "24px",
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "13px",
            // color: "#807F7F",
            fontWeight: "400",
            fontFamily: "Manrope",
            fontStyle: "normal",
            lineHeight: "20px",
          }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
