import LabelBox from 'components/LabelBox';
import { GRAY_FOOTER_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import { getFooterIconURL, getIconURL, getMobileIconURL } from 'global/utils';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

const MobileFooter = props => {

    const { responsive, footer, translations, footerIconHandler } = props;

    const styles = {
        footerContainer: {
            backgroundColor: GRAY_FOOTER_COLOR,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '6rem',
            zIndex: 3,
            padding: '0.5rem 1rem',
            // position: 'fixed',
            bottom: 0,
            width: 'calc(100% - 2rem)',
            fontSize: '8px',
            color: WHITE_PRIMARY_COLOR,
        },
        endorsedContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        storeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        storeContainer1: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        image: {
            margin: "0px 10px",
            cursor: 'pointer'
        }
    }

    return (<div style={styles.footerContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={styles.endorsedContainer}>
                <div style={{ display: 'flex' }} >
                    {footer[1].description}
                </div>
                <div style={{ display: 'flex', marginTop: 5 }}>
                    <img src={getFooterIconURL(footer[2].mediaUrl)}
                        onClick={() => footerIconHandler(footer[2].redirectUrl)}
                        style={{ marginRight: 5, cursor: 'pointer', height: '13px' }} alt={footer[2].title} />
                    <img src={getFooterIconURL(footer[1].mediaUrl)} onClick={() => footerIconHandler(footer[1].redirectUrl)}
                        style={{ marginRight: 5, cursor: 'pointer', height: '13px' }} alt={footer[1].title} />
                </div>
            </div>
            <div style={styles.storeContainer1}>
                <div style={{ marginTop: 5 }} >
                    <img src={footer[3].mediaUrl}
                        style={{ maxWidth: 40, width: 'auto' }}
                        alt={footer[3].title} className="qrcode-mobile" />
                </div>
            </div>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'column',
            justifyContent: 'space-between', alignItems: 'flex-end',
        }} >
            <div style={styles.storeContainer}>
                <div>
                    &nbsp;
                </div>
                <div style={{ display: 'flex' }} >
                    <img src={getMobileIconURL(footer[4].mediaUrl)}
                        onClick={() => footerIconHandler(footer[4].redirectUrl)} style={styles.image} alt={footer[3].title} />
                    <img src={getMobileIconURL(footer[5].mediaUrl)}
                        onClick={() => footerIconHandler(footer[5].redirectUrl)} style={styles.image} alt={footer[5].title} />
                    <img src={getMobileIconURL(footer[6].mediaUrl)}
                        onClick={() => footerIconHandler(footer[6].redirectUrl)} style={styles.image} alt={footer[4].title} />
                </div>
            </div>
            <div style={{ ...styles.storeContainer }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Link to="/termsandconditions"
                        style={{ textDecoration: 'none', color: WHITE_PRIMARY_COLOR }}>
                        {footer[7].displayTitle}&nbsp;&nbsp;</Link>   |
                    <Link style={{ textDecoration: 'none', color: WHITE_PRIMARY_COLOR }}
                        to="/privacypolicy">&nbsp;&nbsp;{footer[8].displayTitle}</Link>

                </div>
            </div>
        </div>
    </div>)
}

export default MobileFooter;