import React, { useEffect, useState } from "react";
import LabelBox from "components/LabelBox";
import { useLocation, withRouter } from "react-router-dom";
import SearchBox from "components/SearchBox";
import { MOBILE_RED_PRIMARY_COLOR } from "global/constants";
import EmbededHeader from "components/EmbededHeader";

const MobileHeader = (props) => {
  const {
    translations,
    changeLocale,
    locale,
    arr,
    header,
    goToMerchantHandler,
    handleClick,
    searchHandler,
    callbackHandler,
    inputHandler,
    innerRef,
  } = props;

  const styles = {
    headerContainer: {
      display: "flex",
      width: "100%",
      height: "2em",
      zIndex: 2,
      padding: "1em",
      paddingBottom: "1.5em",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftContainer: {
      display: "flex",
    },
    rightContainer: {
      display: "flex",
      fontSize: "16px",
      color: "#fff",
      fontWeight: "bold",
      justifyContent: "center",
    },
    inputContainer: {
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      alignItems: "center",
      marginRight: "1em",
    },
  };

  return (
    <div
      ref={innerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        top: 0,
        height: "8rem",
        zIndex: 2,
        position: "fixed",
        width: "100%",
        backgroundColor: MOBILE_RED_PRIMARY_COLOR,
      }}
    >
      <div style={styles.headerContainer}>
        <div style={styles.leftContainer} onClick={() => handleClick("/")}>
          <img src="/iPayV2/icons/iPay.png" style={{ height: "2em" }} />
        </div>
        <div style={styles.rightContainer}>
          <div style={styles.inputContainer} id="searchContainer">
            <div style={{ marginRight: "1rem" }}>
              <span
                onClick={() => changeLocale(locale === "en" ? "ar" : "en")}
                style={{ cursor: "pointer" }}
              >
                {locale === "en" ? "عربي" : "English"}
              </span>
            </div>
            <SearchBox
              responsive={true}
              callbackHandler={callbackHandler}
              inputHandler={inputHandler}
              suggestionsRequired={true}
              arr={arr}
              searchHandler={searchHandler}
              id="mobilesearch"
              translations={translations}
            />
          </div>
          <div
            className="merchant"
            onClick={goToMerchantHandler}
            style={{
              cursor: "pointer",
              height: 60,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: "2rem",
              display: "flex",
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/iPayV2/icons/merchant.svg"
                width={50}
                style={{ marginBottom: "-5px" }}
              />
            </div>
            <div
              id="text"
              style={{
                display: "flex",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "0.5rem",
                height: 12,
              }}
            >
              <div
                style={{
                  fontWeight: "lighter",
                  fontSize: "10px",
                  opacity: "0.8",
                }}
              >
                Merchant
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmbededHeader
        header={header}
        locale={locale}
        translations={translations}
        setLocale={changeLocale}
      />
    </div>
  );
};

export default withRouter(MobileHeader);
