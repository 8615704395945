import ComingSoon from 'components/ComingSoon'
import LabelBox from 'components/LabelBox'
import Wrapper from 'components/Wrapper'
import { GRAY_COLOR, GRAY_FOOTER_COLOR, RED_PRIMARY_COLOR } from 'global/constants'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const DiscoverFold2 = props => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "row",
            width: '100%',
            alignItems: 'flex-start',
            height: 'calc(100%)'
        },
        leftContainer: {
            display: 'flex',
            marginTop: '6em',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "20%"
        },
        rightContainer: {
            display: 'flex',
            marginTop: '2rem',
            flexDirection: 'column',
            alignItems: 'center',
            flex: "80%",
            height: '100%'
        }
    }

    const { data, translations, handleSlidePlay, handleSlidePause, footerIconHandler } = props;
    const { rightContainer, leftContainer } = data;
    useEffect(() => {
        const nodes = document.getElementsByTagName('video');
        if (nodes && nodes[0])
            nodes[0].pause()
    }, [])

    const handleClick = () => {
        props.history.push('/contact', {
            slideIndex: 1
        })
    }

    return (<div style={styles.container} id="discover2">
        <div style={styles.leftContainer} className="left-container">
            <div className="title">
                {leftContainer.title}
            </div>
            <br />
            <div className="Line-2">
            </div>
        </div>
        <div style={styles.rightContainer} id="rightContainer">
            <div onMouseEnter={handleSlidePause} onMouseLeave={handleSlidePlay}
                style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 40 }}>
                {rightContainer.wallets.map((wallet, index) => {
                    return (<Wallet key={index + ""} {...wallet}
                        footerIconHandler={footerIconHandler}
                        src={rightContainer && rightContainer.assets && rightContainer.assets[index] ? rightContainer.assets[index].mediaUrl : undefined}
                        handleClick={handleClick}
                        translations={translations} />)
                })}
            </div>
        </div>
    </div>)
}



const Wallet = props => {

    const { displayTitle, features = [], handleClick, src, footerIconHandler, translations } = props;

    return (<div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
        <div style={{ margin: 'auto auto 20px auto' }} >
            <img src={src} alt={displayTitle} style={{
                maxHeight: '110px', maxWidth: '110px', height: 'auto', width: 'auto'
            }} />
        </div>
        <div id="d2wrapper" style={{
            minHeight: `40em`, maxHeight: '1400px',
            backgroundColor: GRAY_COLOR, width: 340,
            borderRadius: '30px 30px 0px 0px'
        }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold', padding: "1em 0 1.5em 0", color: RED_PRIMARY_COLOR }}>
                {displayTitle}
            </div>
            {features.length === 0 ? <ComingSoon responsive={true} translations={translations} /> : features.map((feature, index) => {
                return (<div style={{
                    fontSize: '14px', lineHeight: '1.5',
                    color: GRAY_FOOTER_COLOR, width: "100%", margin: 'auto'
                }}>
                    {feature.featureType === 'text' && feature.featureDescription}
                    {feature.featureType === 'icon' && <div style={{
                        display: 'flex',
                        justifyContent: 'center', padding: "0.5em 0"
                    }}>
                        {feature.assets && feature.assets.map(asset => {
                            return <img src={asset.mediaUrl} width={40} height={40}
                                style={{ padding: "0 0.5em", cursor: 'pointer' }}
                                onClick={() => footerIconHandler(asset.redirectUrl)} />
                        })}
                    </div>}
                    {feature.featureType === 'button' &&
                        <div style={{ display: 'flex', justifyContent: 'center', padding: "0.5em 0", fontWeight: 'bold', cursor: 'pointer' }}>
                            <LabelBox handleClick={handleClick} embededActive={true} labelText={feature.featureDescription}
                                color={RED_PRIMARY_COLOR} active={true} padding="5px 10px"
                                border={`0.75px solid ${RED_PRIMARY_COLOR}`}
                                backgroundColor={GRAY_COLOR} />
                        </div>}
                    {(index !== features.length - 1) && <hr style={{ color: GRAY_FOOTER_COLOR }} />}
                </div>)
            })}
        </div>
    </div>)

}

export default withRouter(DiscoverFold2)