import React, { useEffect } from "react";
import { RED_PRIMARY_COLOR, ANDROID_URL, IOS_URL } from "global/constants";
import { withRouter } from "react-router-dom";
import Fallback from "components/Fallback";

const Download = (props) => {
  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isAndroid = userAgent.includes("Android");
    const isIos = userAgent.includes("iPhone");

    if (isAndroid) {
      window.location.href = ANDROID_URL;
    } else if (isIos) {
      window.location.href = IOS_URL;
    } else {
      props.history.push("/discover");
    }
  }, []);

  return (
    <Fallback
      loading={true}
      color={RED_PRIMARY_COLOR}
      style={{
        height: "50vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "flex-end",
      }}
    />
  );
};

export default withRouter(Download);
