import Fallback from 'components/Fallback';
import Wrapper from 'components/Wrapper';
import { GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, OPAQUE_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import Branches from './Branches';
import Kiosks from './Kiosks';
import SSM from './SSM';

const Components = {
    'BRANCHES': (props) => <Branches {...props} />,
    'KIOSKS': (props) => <Kiosks {...props} />,
    'SSM': (props) => <SSM {...props} />
}

const VisitUs = props => {

    const { data, currentLocation, translations, handleFocus, handleBlur, setLocation,
        responsive, initialCase, showMap, setShowMap } = props;
    const { leftContainer, rightContainer } = data;
    const location = useLocation()
    const styles = {
        description: {
            fontSize: '18px', color: GRAY_FOOTER_COLOR, fontWeight: 600,
            marginBottom: '5px'
        }
    }
    const [loading, setLoading] = useState(false);
    const [Component, setComponent] = useState()
    const menu = location.state ? location.state.menu ?
        location.state.menu : undefined : undefined

    useEffect(() => {
        if (!initialCase && menu) {
            const comp = Components[menu.title.toUpperCase()]({
                locations: rightContainer.locations,
                currentLocation: currentLocation,
                translations: translations,
                title: menu.displayTitle,
                responsive: true,
                setLocation
            });
            setComponent(comp)
        }
    }, [])

    const selectMenuItemHandler = menu => {
        props.history.push('/contact', { menu: menu, slideIndex: 1 })
        // getVisitusContent(menu)
    }

    return (<div onMouseOver={handleFocus}
        onMouseLeave={handleBlur} >
        {(!Component && initialCase) ? <><div style={styles.description}>
            {leftContainer.title}
        </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto", gap: 10 }}>
                {
                    leftContainer.menu && leftContainer.menu.map(menu => {
                        return <EnhancedWrapper description={menu.description}
                            selectMenuItemHandler={() => selectMenuItemHandler(menu)}
                            title={menu.title} displayTitle={menu.displayTitle}
                            src={menu.mediaUrl} />
                    })
                }
            </div> </> : loading ?
            <Fallback loading={loading}
                color={responsive ? MOBILE_RED_PRIMARY_COLOR : WHITE_PRIMARY_COLOR} /> :
            Component}
    </div>)
}


const EnhancedWrapper = (props) => {

    const { title = "Title", displayTitle = "Title", src,
        description = "Description Text", selectMenuItemHandler } = props;

    return (
        <Wrapper backgroundColor={OPAQUE_COLOR} width={"100%"} borderRadius="12px">
            <div onClick={selectMenuItemHandler} style={{
                cursor: 'pointer',
                width: '100%', height: 70, gridTemplateAreas: "'menu main'",
                display: 'grid', gridTemplateColumns: '20% 75%',
            }}>
                <div style={{ gridArea: 'menu', margin: 'auto', }}>
                    <img src={src} alt={title} style={{ width: '40px' }} />
                </div>
                <div style={{
                    fontSize: "14px", color: GRAY_FOOTER_COLOR, fontWeight: 'normal',
                    margin: "auto 0", lineHeight: 1.36, gridArea: 'main',
                }}>
                    <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{displayTitle}</span><br />
                    {description}
                </div>
            </div>
        </Wrapper>
    )
}

export default withRouter(VisitUs);