import React, { useEffect, useState } from "react";
import Header from "container/Header";
import Footer from "container/Footer";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  withRouter,
} from "react-router-dom";
import Discover from "container/Discover";
import Assistance from "container/Assistance";
import Contact from "container/Contact";
import Home from "container/Home";
import FAQ from "container/Assistance/FAQ";
import TroubleShooting from "container/Assistance/TroubleShooting";
import Tutorials from "container/Assistance/Tutorials";
import ErrorPage from "container/ErrorPage";
import {
  API_KEY_NOTIFICATION,
  CHAT_URL,
  FETCH_HEADER_AND_FOOTER,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import TCPrivacy from "container/TC&PrivacyPolicy";
import { fetchContent } from "global/api";
import Fallback from "components/Fallback";
import fetchTranslations from "global/translations";
import SearchResults from "container/SearchResults";
import firebase from "./firebase";
import { logEvent } from "firebase-analytics";
import Tariff from "container/Assistance/Tariff";
import Genericpage from "container/Genericpages/index";
import Download from "components/Download";
import OurProducts from "container/OurProducts";

const styles = {
  bg: {
    // backgroundImage: 'url("/iPayV2/icons/background.jpg")',
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  responsiveBg: {
    backgroundColor: MOBILE_RED_PRIMARY_COLOR,
    zIndex: 2,
    position: "relative",
  },
  container: {
    height: "calc(100vh - 12em)",
    padding: "6em 0",
    position: "relative",
  },
  responsiveContainer: {
    backgroundColor: WHITE_PRIMARY_COLOR,
    marginTop: "8rem",
  },
  chat: {
    position: "absolute",
    width: "50px",
    backgroundColor: "#fff",
    height: "50px",
    borderRadius: "50%",
    top: "6em",
    textAlign: "center",
    right: "2em",
    cursor: "pointer",
  },
};

const validPaths = [
  "/",
  "/home",
  "/home/",
  "/discover",
  "/discover/",
  "/assistance",
  "/assistance/",
  "/termsandconditions",
  "/termsandconditions/",
  "/privacypolicy",
  "/privacypolicy/",
  "/assistance/faq",
  "/assistance/faq/",
  "/assistance/troubleshooting",
  "/assistance/troubleshooting/",
  "/assistance/tutorials",
  "/assistance/tutorials/",
  "/assistance/tariff",
  "/assistance/tariff/",
  "/contact",
  "/contact/",
  "/search",
  "/search/",
  "/ourProducts",
];

const metaInfos = [
  {
    path: "/home",
    title: "Home",
    description: "The next generation e-wallet",
  },
  {
    path: "/discover",
    title: "Discover",
    description: "Hassle Free , Fast ,Rewarding & Secure",
  },
  {
    path: "/assistance",
    title: "Assistance",
    description: "FAQ , Tutorials & Troubleshooting",
  },
  {
    path: "/contact",
    title: "Contact",
    description: "Get in touch & Visit Us",
  },
];

function App(props) {
  const [responsive, setResponsive] = useState();
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [locale, setLocale] = useState(localStorage.getItem("locale"));
  const [translations, setTranslations] = useState(fetchTranslations(locale));
  const [showMap, setShowMap] = useState();
  const location = useLocation();

  const changeLocale = value => {
    localStorage.setItem("locale", value);
    window.location.reload();
  };

  useEffect(() => {
    if (navigator.serviceWorker) {
      const messaging = firebase.messaging();
      // const analytics = firebase.analytics();
      // analytics.logEvent("search",{
      //   search_term :
      // });
      messaging
        .getToken({ vapidKey: API_KEY_NOTIFICATION })
        .then(currentToken => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            // console.log('current token', currentToken)
          } else {
            // Show permission request UI
            // console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch(err => {
          // console.log('An error occurred while retrieving token. ', err);
          // ...
        });

      messaging.onMessage(payload => {
        // alert("Message received");
        // console.log('Foreground Message received. ', payload);
        // ...
      });
    }
    // navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
  }, []);

  useEffect(() => {
    if (
      location &&
      (validPaths.includes(location.pathname) ||
        validPaths.includes(location.pathname + "/"))
    ) {
      const path = location.pathname;
      const index = metaInfos.findIndex(mi => path.includes(mi.path));
      if (index > -1) {
        document.title = "iPay " + metaInfos[index].title;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metaInfos[index].description);
      } else {
        document.title = "iPay Consumer";
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metaInfos[0].description);
      }
      // document.title = path.substring(path.lastIndexOf('/')) === '' ? 'iPay Consumer' :
      //   'iPay Consumer ' + capitalize(path.substring(path.lastIndexOf('/') + 1));
      setIsValidUrl(true);
    } else {
      document.title = "iPay Consumer Error";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", metaInfos[0].description);
      setIsValidUrl(false);
    }
  }, [location]);

  useEffect(() => {
    try {
      let locale = localStorage.getItem("locale");
      if (locale && ["ar", "en"].includes(locale)) {
        setLocale(locale);
      } else {
        if (navigator.language) {
          locale = navigator.language;
          if (locale && locale.indexOf("en") > -1) {
            locale = "en";
          } else if (locale && locale.indexOf("ar") > -1) {
            locale = "ar";
          } else {
            locale = "en";
          }
        } else {
          locale = "en";
        }
        setLocale(locale);
      }
    } catch (error) {
      setLocale("en");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    localStorage.setItem("locale", locale);
    const translation = fetchTranslations(locale);
    setTranslations(translation);
  }, [locale]);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [header, setHeader] = useState();
  const [footer, setFooter] = useState();

  useEffect(() => {
    logEvent("header_footer_content_load_start");
    fetchContent(FETCH_HEADER_AND_FOOTER, {}).then(response => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setHeader(data.header);
            setFooter(data.footer);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          logEvent("header_footer_content_load_success");
        } else {
          setIsError(true);
          setLoading(false);
          logEvent("header_footer_content_load_fail");
        }
      } else {
        setIsError(true);
        setLoading(false);
        logEvent("header_footer_content_load_fail");
      }
    });
  }, [translations]);

  useEffect(() => {
    if (window.loadGTA) {
      // console.log("GTA loaded")
      window.loadGTA();
    }
  }, [location]);

  useEffect(() => {
    if (isError) props.history.replace("error");
  }, [isError]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    const resizeWindow = () => {
      if (window.innerWidth <= 700) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }
    };
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  /******************************************************************************** */

  return loading ? (
    <Fallback
      loading={loading}
      color={RED_PRIMARY_COLOR}
      style={{
        height: "50vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "flex-end",
      }}
    />
  ) : (
    <div
      style={
        responsive
          ? {
              ...styles.responsiveBg,
              backgroundColor: WHITE_PRIMARY_COLOR,
            }
          : styles.bg
      }
    >
      {isValidUrl && header && (
        <Header
          responsive={responsive}
          locale={locale}
          translations={translations}
          changeLocale={changeLocale}
          header={header}
          backgroundColor={"rgb(200, 6, 0 , 1)"}
        />
      )}

      <div
        className={
          responsive
            ? ""
            : [
                "/assistance",
                "/assistance/faq",
                "/discover",
                "/termsandconditions",
                "/privacypolicy",
              ].includes(location.pathname)
            ? "containerB"
            : "containerA"
        }
        style={
          responsive
            ? {
                ...styles.responsiveContainer,
                marginTop:
                  location.pathname === "/home" ? "10.5rem" : "12.5rem",
              }
            : undefined
        }
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/download">
            <Download />
          </Route>
          <Route exact path="/discover">
            <Discover translations={translations} responsive={responsive} />
          </Route>
          <Route exact path="/assistance">
            <Assistance translations={translations} responsive={responsive} />
          </Route>
          <Route exact path={`/assistance/faq`}>
            <FAQ translations={translations} responsive={responsive} />
          </Route>
          <Route exact path={`/assistance/troubleshooting`}>
            <TroubleShooting
              translations={translations}
              responsive={responsive}
            />
          </Route>
          <Route exact path={`/assistance/tutorials`}>
            <Tutorials translations={translations} responsive={responsive} />
          </Route>
          <Route exact path={`/assistance/tariff`}>
            <Tariff translations={translations} responsive={responsive} />
          </Route>
          <Route exact path="/contact">
            <Contact
              translations={translations}
              responsive={responsive}
              showMap={showMap}
              setShowMap={setShowMap}
            />
          </Route>
          <Route exact path="/errorPage">
            <ErrorPage
              message={"This is not the working page of the application"}
              responsive={responsive}
              translations={translations}
            />
          </Route>
          <Route exact path="/home">
            <Home
              footer={footer}
              translations={translations}
              locale={locale}
              responsive={responsive}
            />
          </Route>
          <Route exact path="/ourProducts">
            <OurProducts translations={translations} responsive={responsive} />
          </Route>
          <Route exact path="/search">
            <SearchResults
              translations={translations}
              locale={locale}
              responsive={responsive}
            />
          </Route>
          <Route exact path={`/termsandconditions`}>
            <TCPrivacy
              tc={true}
              pp={false}
              translations={translations}
              responsive={responsive}
            />
          </Route>
          <Route exact path={`/privacypolicy`}>
            <TCPrivacy
              pp={true}
              tc={false}
              translations={translations}
              responsive={responsive}
            />
          </Route>
          <Route exact path="/error">
            <ErrorPage
              message={"This is not the working page of the application"}
              responsive={responsive}
              translations={translations}
            />
          </Route>
          <Route path={`/:pagecontext`}>
            {() => {
              setIsValidUrl(true);
              document.title = "iPay Consumer";
              return (
                <Genericpage
                  translations={translations}
                  responsive={responsive}
                />
              );
            }}
          </Route>
        </Switch>
      </div>

      {isValidUrl && footer && (
        <Footer
          responsive={responsive}
          translations={translations}
          footer={footer}
        />
      )}
    </div>
  );
}

export default withRouter(App);
