import axios from 'axios';
import Fallback from 'components/Fallback';
import SearchBox from 'components/SearchBox';
import Wrapper from 'components/Wrapper';
import { fetchContent } from 'global/api';
import { GLOBAL_SEARCH, GRAY_COLOR, GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, PAGE_MAPPING, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import MobileSearchResults from './Mobile';

const SearchResults = props => {

    const location = useLocation()
    const { translations, responsive } = props;
    const [searchQuery, setSearchQuery] = useState(location.state ? location.state.searchQuery : "");

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "row",
            width: '100%',
            height: '100%',
            marginTop: "3em",
        },
        leftContainer: {
            display: 'flex',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "25%", marginTop: '2rem'
        },
        rightContainer: {
            display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: "6em",
            flex: "75%",
            height: "100%",
            overflow: 'hidden'
        },
        answerLine: {
            height: "1px",
            backgroundColor: "#ccc",
            margin: "4px 0px 4px 0px",
            lineHeight: "1.67"
        }
    }

    const [leftContainer, setLeftContainer] = useState({
        title: `${translations["Search Results"]}:`
    });
    const [rightContainer, setRightContainer] = useState({});
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setSearchQuery(location.state.searchQuery)
    }, [location])

    useEffect(() => {
        if (searchQuery && searchQuery.trim() && searchQuery.length > 0) {
            setLoading(true)
            fetchContent(GLOBAL_SEARCH, { query: searchQuery }).then(response => {
                if (response) {
                    if (response.code === 2000) {
                        const data = response.data;

                        if (data) {
                            setLeftContainer(data.leftContainer);
                            setRightContainer(data.rightContainer);
                        } else {
                            setIsError(true)
                        }
                    } else if (response.code === 2004) {
                        setRightContainer({});
                    } else {
                        setIsError(true);
                    }
                    setLoading(false)
                } else {
                    setIsError(true);
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
            setLeftContainer({
                title: `${translations["Search Results"]}:`
            })
            setRightContainer({});
        }
    }, [searchQuery])


    const searchHandler = (query) => {
        setSearchQuery(query)
    }

    useEffect(() => {
        if (isError)
            props.history.replace('errorPage')
    }, [isError])

    const resultHandler = (data) => {
        const { pageNo = 0 } = data;
        props.history.replace(PAGE_MAPPING[pageNo].path, {
            searchQueryData: data,
            slideIndex: PAGE_MAPPING[pageNo].slideIndex
        })
    }

    return (loading ? <Fallback loading={loading} color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR} /> : !responsive ? <div style={styles.container}>
        <div style={styles.leftContainer} className="left-container">
            <div className="title">
                {leftContainer.title}
            </div>
            <br />
            <div className="Line-2">
            </div>
        </div>
        <div style={styles.rightContainer}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: "100%",
                paddingTop: "1em",
            }}>
                <SearchBox id="globalsearch" border="1px solid rgb(200, 6, 0, 0.8)" labelRequired={false}
                    src="/iPayV2/icons/faq search.svg" searchHandler={searchHandler} translations={translations} />
            </div>
            {rightContainer.results && rightContainer.results.length > 0 ? <div style={{
                width: "80%", marginTop: "10px", height: '100%', backgroundColor: GRAY_COLOR,
                overflow: 'auto', borderRadius: '30px 30px 0px 0px'
            }}>
                <div style={{
                    minHeight: "100%",
                    overflowY: 'auto', padding: "10px 20px",
                }}>
                    {rightContainer.results.map((result, index) => {
                        return <div style={{ padding: '0 1em' }}>
                            <div key={index + ""} style={{
                                cursor: 'pointer',
                                fontSize: 18, padding: '1em 0',
                                color: GRAY_FOOTER_COLOR, textAlign: 'left'
                            }} onClick={() => resultHandler(result)}>
                                {result.title}
                            </div>
                            {index !== rightContainer.results.length - 1 && <div style={styles.answerLine}>
                            </div>}
                        </div>
                    })}
                </div>
            </div> : <div style={{
                color: RED_PRIMARY_COLOR, fontSize: 24, width: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {translations["No Results found, Try to search more specific."]}
            </div>}
        </div>
    </div> : <MobileSearchResults {...props} resultHandler={resultHandler}
        leftContainer={leftContainer} rightContainer={rightContainer} />)
}

export default withRouter(SearchResults);