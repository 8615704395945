import Fallback from "components/Fallback";
import SideMenu from "components/SideMenu";
import { logEvent } from "firebase-analytics";
import { fetchContent } from "global/api";
import { CONTACT_PAGE_URL, RED_PRIMARY_COLOR } from "global/constants";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Branches from "./Branches";
import CategoryTemplate from "./CategoryTemplate";
import Kiosks from "./Kiosks";
import SSM from "./SSM";

// const Components = [
//   undefined,
//   (props) => <Branches {...props} />,
//   (props) => <Kiosks {...props} />,
//   (props) => <SSM {...props} />,
// ];
const VisitUs = (props) => {
  const {
    data,
    currentLocation,
    translations,
    handleFocus,
    handleBlur,
    setLocation,
  } = props;

  const [rightContainer, setRightContainer] = useState(
    data ? data.rightContainer : undefined
  );
  const [leftContainer, setLeftContainer] = useState(
    data ? data.leftContainer : undefined
  );
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "3em",
    },
    leftContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "6em",
      flexDirection: "column",
      flex: "32%",
    },
    description: {
      fontSize: "14px",
      color: "#fff",
      marginBottom: "85px",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "68%",
    },
  };

  const [activeId, setActiveId] = useState(1);
  const [loading, setLoading] = useState(false);
  //   const [Component, setComponent] = useState(
  //     Components[+activeId]({
  //       locations: rightContainer.locations,
  //       currentLocation: currentLocation,
  //       translations: translations,
  //     })
  //   );

  const selectMenuItemHandler = (id) => {
    const menu = leftContainer.menu.find((item) => {
      return item.sequenceNo == id;
    });
    getVisitusContent(menu.title, () => setActiveId(id));
  };

  const getVisitusContent = (category, callback) => {
    setLoading(true);
    logEvent(`locations_load_start_${category}`);
    fetchContent(
      CONTACT_PAGE_URL,
      {},
      {
        category: category,
        latitude: currentLocation.latitude,
        longitute: currentLocation.longitude,
      }
    ).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setRightContainer(data.folds[1].rightContainer);
          }
          callback();
          logEvent(`locations_load_success_${category}`);
          setLoading(false);
        }
      } else {
        setLoading(false);
        logEvent(`locations_load_fail_${category}`);
      }
    });
  };

  //   useEffect(() => {
  //     setComponent(
  //       Components[+activeId]({
  //         locations: rightContainer.locations,
  //         setLocation: setLocation,
  //         currentLocation: currentLocation,
  //         translations: translations,
  //       })
  //     );
  //   }, [activeId]);

  //   console.log("Component :: ", Component);

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer} className="left-container">
        <div className="title">{leftContainer.title}</div>
        <div className="Line-2"></div>
        <div style={styles.description}>
          <SideMenu
            activeId={activeId}
            menu={leftContainer.menu}
            selectMenuItemHandler={selectMenuItemHandler}
          />
        </div>
      </div>
      <div
        style={styles.rightContainer}
        onMouseOver={handleFocus}
        onMouseLeave={handleBlur}
      >
        {loading ? (
          <Fallback loading={loading} color={RED_PRIMARY_COLOR} />
        ) : (
          <CategoryTemplate
            locations={rightContainer.locations}
            setLocation={setLocation}
            currentLocation={currentLocation}
            translations={translations}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(VisitUs);
