import { GOOGLE_KEY, RED_PRIMARY_COLOR } from "global/constants";
import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader"
import Fallback from "components/Fallback";
import { getMiles } from "global/utils";
import { logEvent } from "firebase-analytics";

const MyGoogleMap = props => {

    const { selectedLocation, currentLocation, setError, translations, marginLeft = "2em",
        setDistance, } = props;
    let google;
    let map;
    let directionsService, directionsRenderer, origin, destination;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loader = new Loader({
            apiKey: GOOGLE_KEY,
            version: 'weekly'
        });

        loader.load().then(() => {
            google = window.google;
            setLoading(false);
            map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: +selectedLocation.latitude, lng: +selectedLocation.longitude },
                zoom: 18,
                gestureHandling: "cooperative",
                zoomControl: true,
                streetViewControl: false,
                mapTypeId: 'roadmap',
                mapTypeControl: true
            });
            directionsService = new google.maps.DirectionsService();
            directionsRenderer = new google.maps.DirectionsRenderer();
            origin = new google.maps.LatLng(currentLocation.latitude,
                currentLocation.longitude);
            destination = new google.maps.LatLng(+selectedLocation.latitude, +selectedLocation.longitude);

            const infowindow = new google.maps.InfoWindow({
                content: `<div id="infow" ><div style="font-weight : bold;margin-bottom : 1em;">Store Name</div><div>${selectedLocation.displayTitle}</div></div>`,
                maxWidth: 200,
                disableAutoPan: true,
                width: 100
            });
            const marker = new google.maps.Marker({
                position: { lat: +selectedLocation.latitude, lng: +selectedLocation.longitude },
                icon: '/iPayV2/icons/store.svg',
                map: map,
            });
            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: true,
                });
            })
            infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });
            directionsRenderer.setMap(map);
            setError()
        })
            .catch(err => {
                setLoading(false)
                setError(translations["Something went wrong"]);
            })

    }, [])


    useEffect(() => {
        if (document.getElementById('directions')) {
            document.getElementById('directions').addEventListener('click', calcRoute);
        }

    }, []);

    function calcRoute() {
        console.log("in calcRoute")
        logEvent("get_directions_called");
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const location = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                origin = new google.maps.LatLng(location.latitude,
                    location.longitude);
                var request = {
                    origin,
                    destination,
                    travelMode: google.maps.TravelMode.WALKING
                };
                directionsService.route(request, function (response, status) {
                    if (status == 'OK') {
                        directionsRenderer.setDirections(response);
                        if (setDistance) {
                            if (response && response.routes.length > 0) {
                                const miles = getMiles(response.routes[0].legs[0].distance.value) === 0 ? undefined : getMiles(response.routes[0].legs[0].distance.value) + " miles"
                                setDistance(miles);
                            } else {
                                setDistance();
                            }
                        }
                        setError();
                    } else if (status === 'ZERO_RESULTS') {
                        setError(translations["No Direction available for the selected route"]);
                    } else {
                        setError(translations["Something went wrong"]);
                    }
                });
            }, (error) => {
                let message = error ? error.message : 'Please enable the location';
                if (error && error.code === 1) {
                    message = translations["PERMISSION_DENIED"]
                }
                setError(message)
            });
        }
    }

    return (
        loading ?
            <Fallback loading={loading} color={RED_PRIMARY_COLOR} /> :
            <div id="map" style={{ height: '100%', width: '100%', marginLeft: { marginLeft } }}>
            </div>
    );
}

export default MyGoogleMap;