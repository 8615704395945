import { MOBILE_RED_PRIMARY_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import React from 'react';

const ComingSoon = props => {

    const { translations, message = translations["Coming soon"], responsive } = props;

    return <div style={{
        display: 'flex', flexDirection: 'column',
        marginTop: responsive ? 20 : undefined,
        alignItems: 'center', justifyContent: 'center'
    }}>
        <div>
            <img src={"/iPayV2/icons/comingsoon.svg"} />
        </div>
        <div style={{
            fontSize: responsive ? '18px' : '58px',
            fontWeight: responsive ? 'normal' : 'lighter',
            lineHeight: '3.03',
            color: responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR
        }}>
            {message}
        </div>
    </div>
}

export default ComingSoon;