import axios from 'axios';

export const fetchContent = async (url, params, data = {}) => {
    const language = localStorage.getItem('locale') ? localStorage.getItem('locale') === "null" ? 'en' : localStorage.getItem('locale') : 'en';
    let updatedUrl = url + '?language=' + language;
    if (Object.entries(params).length > 0) {
        for (const key in params) {
            if (params[key] !== '')
                updatedUrl += '&' + key + '=' + params[key];
        }
    }
    return axios.post(updatedUrl, data, {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    })
        .then(response => {
            // console.log(response)
            return response ? response.data : undefined
        })
        .catch(() => {
            throw new Error('error while serving your request')
        })
        .catch(() => {
            return undefined
        })
}