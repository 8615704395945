const en_translation = {
  Discover: "Discover",
  Contact: "Contact",
  "Welcome to iPay": "Welcome to iPay",
  "The next generation E-Wallet": "The next generation E-Wallet",
  "Learn more": "Learn more",
  "Get yours from": "Get yours from",
  "Terms & Conditions": "Terms & Conditions",
  "Privacy Policy": "Privacy Policy",
  "Endorsed by": "Endorsed by",
  "Qatar Central Bank": "Qatar Central Bank",
  "Live Chat": "Live Chat",
  Easy: "Easy",
  "Register through the iPay app in 3 steps without visiting the store":
    "Register through the iPay app in 3 steps without visiting the store",
  Secure: "Secure",
  "iPay App design is secured with your biometrics and all your payments are SSL secured":
    "iPay App design is secured with your biometrics and all your payments are SSL secured",
  Fast: "Fast",
  "Quick transfer to family and friends anywhere in the world":
    "Quick transfer to family and friends anywhere in the world",
  Convenient: "Convenient",
  "Track progress of your requests and transfers in real time from the iPay App. We are at your service 24/7":
    "Track progress of your requests and transfers in real time from the iPay App. We are at your service 24/7",
  "Discover iPay": "Discover iPay",
  "Send money in 3 simple steps": "Send money in 3 simple steps",
  "1. Download and Register": "1. Download and Register",
  "Provide your local mobile number, QID, record a video selfie and set password":
    "Provide your local mobile number, QID, record a video selfie and set password",
  "2. Load Money": "2. Load Money",
  "Add money into your wallet through your debit card or via any of our outlets.":
    "Add money into your wallet through your debit card or via any of our outlets.",
  "3. Start transacting": "3. Start transacting",
  "Send & receive money from family and friends":
    "Send & receive money from family and friends",
  "Wallet in all sizes": "Wallet in all sizes",
  "Mini Wallet": "Mini Wallet",
  Features: "Features",
  "Daily Limit -QR X": "Daily Limit -QR X",
  "Wallet Limit - QR X": "Wallet Limit - QR X",
  "Register with QID / Passport": "Register with QID / Passport",
  "Registration via App or Store": "Registration via App or Store",
  "Full Wallet": "Full Wallet",
  "Register with QID ": "Register with QID ",
  "Registration via Store": "Registration via Store",
  "Visit Store": "Visit Store",
  Assistance: "Assistance",
  "Empowering Self Help": "Empowering Self Help",
  FAQ: "FAQ",
  "Frequently Asked Questions": "Frequently Asked Questions",
  Tutorials: "Tutorials",
  "Learn to use iPay App": "Learn to use iPay App",
  Troubleshooting: "Troubleshooting",
  "Find solutions to all your issues": "Find solutions to all your issues",
  "Self Help Tutorials": "Self Help Tutorials",
  "International Transfers": "International Transfers",
  "Local Transfers": "Local Transfers",
  Settings: "Settings",
  "Cash In & Out": "Cash In & Out",
  Beneficiary: "Beneficiary",
  Transactions: "Transactions",
  "Video Title": "Video Title",
  "Get in touch": "Get in touch",
  "Leave us your details & we will get back to you":
    "Leave us your details & we will get back to you",
  "You can also call our iPay experts 24/7 on 4000 0555":
    "You can also call our iPay experts 24/7 on 4000 0555",
  "First & last name": "First and last name",
  "Mobile number": "Mobile number",
  "Local contact number": "Local contact number",
  "E-mail": "E-mail",
  "Leave your feedback & comments": "Leave your feedback and comments",
  Submit: "Submit",
  "Visit us": "Visit us",
  Branches: "Branches",
  Kiosks: "Kiosks",
  "Self Service Machines": "Self Service Machines",
  "Show more": "Show more",
  "View on map": "View on map",
  "Nearest to you": "Nearest to you",
  "Coming soon": "Coming soon",
  "Get directions": "Get directions",
  "Your browser does not support the video tag":
    "Your browser does not support the video tag",
  "Play on Enlarged View": "Play on Enlarged View",
  "Maximize the image": "Maximize the image",
  search: "Search",
  SUBMIT: "SUBMIT",
  English: "English",
  Arabic: "Arabic",
  "No Results found, Try to search more specific.":
    "No Results found, Try to search more specific.",
  "Search Results": "Search Results",
  "Something went wrong": "Something went wrong",
  "No Direction available for the selected route":
    "No Direction available for the selected route",
  "Your Feedback Has Been Submitted": "Your Feedback Has Been Submitted!",
  "We'll contact you soon.": "We'll contact you soon.",
  "Unable to fetch data at this moment.":
    "Unable to fetch data at this moment.",
  "Something went wrong. Please contact Admin":
    "Something went wrong. Please contact Admin",
  "Please visit the": "Please visit the",
  "This is not the working page of the application":
    "This is not the working page of the application",
  NAME_VALIDATION:
    "Name only accepts alphabets with the length between 3 and 30.",
  MSISDN_VALIDATION:
    "Mobile number only accept numeric with length between 5 and 15.",
  FEEDBACK_VALIDATION:
    "Feedback only accept alphanumeric and some special characters with length more than 5",
  SEARCH_VALIDATION: "Invalid search input.",
  PERMISSION_DENIED: "Please enable the location to get the directions.",
  EMAIL_VALIDATION: "* Email is not valid",
  TARIFF_CHART: "Tariff Chart",
  CHARGES: "Charges",
  "This Offer applies to recharge done from the iPay application":
    "This Offer applies to recharge done from the iPay application",
  "Customer can get the extra benefits each time they recharge till the end of the promotion period":
    "Customer can get the extra benefits each time they recharge till the end of the promotion period",
  "Offer Valid from 18/01/2023 to 18/04/2023 (Promo Period)":
    "Offer Valid from 18/01/2023 to 18/04/2023 (Promo Period)",
  "General Prepaid Terms & Conditions apply":
    "General Prepaid Terms & Conditions apply",
  "Offer is applicable for recharge denominations":
    "Offer is applicable for recharge denominations",
  "This Offer applies to recharge done from the iPay application":
    "This Offer applies to recharge done from the iPay application",
  "To avail the offer, customers must use the iPay e-wallet to make their bill payment":
    "To avail the offer, customers must use the iPay e-wallet to make their bill payment",
  "This offer is over and above any other offers that they are currently getting.":
    "This offer is over and above any other offers that they are currently getting.",
  'The extra local data ("Benefit") will depend on the bill value as per the grid:':
    'The extra local data ("Benefit") will depend on the bill value as per the grid:',
  "The Benefit will only be given to the primary number linked to the account and not on the number that makes the payment.":
    "The Benefit will only be given to the primary number linked to the account and not on the number that makes the payment.",
  "Customers eligible for the Benefit will receive the bonus data on their primary number 24 hours after they make the payment.":
    "Customers eligible for the Benefit will receive the bonus data on their primary number 24 hours after they make the payment.",
  "Customers will get a notification message on their primary number once the free data has been activated.":
    "Customers will get a notification message on their primary number once the free data has been activated.",
  "Offer start date: 18th Jan 2023 and ends on 18th April 2023":
    "Offer start date: 18th Jan 2023 and ends on 18th April 2023",
  "Standard Post-paid terms and conditions apply.":
    "Standard Post-paid terms and conditions apply.",
  "Pay your next Vodafone bill using iPay and get up to 1GB local data for free":
    "Pay your next Vodafone bill using iPay and get up to 1GB local data for free",
  "Offer valid from 01 February till 18th April 2023.T&C appply*":
    "Offer valid from 01 February till 18th April 2023.T&C appply*",
  "Recharge with iPay and get 8% extra benefit on all recharges of QR30 & above":
    "Recharge with iPay and get 8% extra benefit on all recharges of QR30 & above",
  "Offer valid from 18 January 2023 till 18 April 2023. Terms & Conditions Apply*":
    "Offer valid from 18 January 2023 till 18 April 2023. Terms & Conditions Apply*",
  "Bill amount": "Bill amount",
  "Benefit (local)": "Benefit (local)",
  "Validity - Days": "Validity - Days",
  "Up to QR 10": "Up to QR 10",
  "QR 11 - QR 20": "QR 11 - QR 20",
  "QR 21 - QR 50": "QR 21 - QR 50",
  "QR 51 - QR 99": "QR 51 - QR 99",
  "QR 100 & above": "QR 100 & above",
  "50 MB Extra Free": "50 MB Extra Free",
  "100 MB Extra Free": "100 MB Extra Free",
  "200 MB Extra Free": "200 MB Extra Free",
  "500 MB Extra Free": "500 MB Extra Free",
  "1 GB Extra Free": "1 GB Extra Free",
  1: "1",
  7: "7",
  "All Offers": "All Offers",
  "View details": "View details",
  Offers: "Offers",
  "Go to Merchant": "Go to Merchant",
  "Is it a game idea? Or a business? Or anything else?.":
    "Is it a game idea? Or a business? Or anything else?.",
  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects.":
    "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects.",
  "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself.":
    "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself.",
  "International Transfer": "International Transfer",
  "Bill Payments": "Bill Payments",
  Offers: "Offers",
  "Merchant Payments": "Merchant Payments",
  "iPay Payroll Product": "iPay Payroll Product",
  "iPay Cards": "iPay Cards",
  "Wallet Transfer": "Wallet Transfer",
  "Experience best rates and speed of instant international transfers. With iPay, your money reaches its destination in seconds, not days. Our extensive network spans over 43 countries and growing!":"Experience best rates and speed of instant international transfers. With iPay, your money reaches its destination in seconds, not days. Our extensive network spans over 43 countries and growing!",
  "Manage all your bills in one place. iPay is your one-stop e-wallet for stress-free bill payments. Currently you can pay your Vodafone and Kahramaa bills.":"Manage all your bills in one place. iPay is your one-stop e-wallet for stress-free bill payments. Currently you can pay your Vodafone and Kahramaa bills.",
  "Enjoy exclusive iPay benefits such as Savings up to QR 6000, free My Book deals , special rate on remittance fee and many cashbacks offers throughout the year.":"Enjoy exclusive iPay benefits such as Savings up to QR 6000, free My Book deals , special rate on remittance fee and many cashbacks offers throughout the year.",
  "Use iPay to pay at more than 5000 retailers for a seamless shopping experience.":"Use iPay to pay at more than 5000 retailers for a seamless shopping experience.",
  "iPay offers you a convenient payroll product to receive your salary through your iPay wallet account with no need to open a new bank account. You also get a free payroll card which can be used anywhere in Qatar and for online purchases.":"iPay offers you a convenient payroll product to receive your salary through your iPay wallet account with no need to open a new bank account. You also get a free payroll card which can be used anywhere in Qatar and for online purchases.",
  "Discover the easy way to pay anywhere in Qatar and online shopping with our free iPay cards. You have full control in your iPay Wallet card section to order a card, block your card or simply make a payment.":"Discover the easy way to pay anywhere in Qatar and online shopping with our free iPay cards. You have full control in your iPay Wallet card section to order a card, block your card or simply make a payment.",
  "Send money to any registered mobile number in Qatar. With iPay, your money reaches its destination in seconds, not days!":"Send money to any registered mobile number in Qatar. With iPay, your money reaches its destination in seconds, not days!",
};

const ar_translation = {
  Discover: "اكتشف",
  Contact: "اتصل بنا",
  "Welcome to iPay": "أهلاً بك في iPay",
  "The next generation E-Wallet": "الجيل التالي من المحفظات الإلكترونية",
  "Learn more": "اكتشف المزيد",
  "Get yours from": "احصل على محفظتك من",
  "Terms & Conditions": "الشروط واألحكام",
  "Privacy Policy": "سياسة الخصوصية",
  "Endorsed by": "معتمدة من",
  "Qatar Central Bank": "مصرف قطر المركزي",
  "Live Chat": "المحادثة المباشرة",
  Easy: "السهولة",
  "Register through the iPay app in 3 steps without visiting the store":
    "قم بالتسجيل من خلال تطبيق iPay في 3 خطوات دون زيارة المتجر",
  Secure: "الأمن",
  "iPay App design is secured with your biometrics and all your payments are SSL secured":
    "يتم تأمين تصميم تطبيق iPay باستخدام المقاييس الحيوية الخاصة بك وتكون جميع دفعاتك مؤمنة بواسطة طبقة المنفذ الآمن (SSL)",
  Fast: "السرعة",
  "Quick transfer to family and friends anywhere in the world":
    "حوّل الرصيد بسرعة للعائلة والأصدقاء في أي مكان في العالم",
  Convenient: "الملاءمة",
  "Track progress of your requests and transfers in real time from the iPay App. We are at your service 24/7":
    "تتبع تقدم طلباتك وتحويلاتك في الوقت الفعلي عبر تطبيق iPay. نحن في خدمتك على مدار الساعة طيلة أيام الأسبوع",
  "Discover iPay": "اكتشف iPay",
  "Send money in 3 simple steps": "أرسل الأموال في 3 خطوات بسيطة",
  "1. Download and Register": "1. التحميل والتسجيل",
  "Provide your local mobile number, QID, record a video selfie and set password":
    "أدخل رقم هاتفك المحمول المحلي ورقم بطاقة إثبات الشخصية القطرية وسجل فيديو بوضعية السيلفي وحدد كلمة السر",
  "2. Load Money": "2. تحميل الأموال",
  "Add money into your wallet through your debit card or via any of our outlets.":
    "أضف الأموال إلى محفظتك من خلال بطاقة الخصم الخاصة بك أو عبر أي من متاجرنا.",
  "3. Start transacting": "3. البدء بالعملية",
  "Send & receive money from family and friends":
    "أرسل الأموال واستلمها من أفراد العائلة والأصدقاء",
  "Wallet in all sizes": "محفظة بكل الأحجام",
  "Mini Wallet": "المحفظة الصغيرة",
  Features: "الميزات",
  "Daily Limit -QR X": "الحد اليومي - X ر.ق",
  "Wallet Limit - QR X": "حد المحفظة - X ر.ق",
  "Register with QID / Passport":
    "التسجيل عبر رقم بطاقة إثبات الشخصية القطرية / جواز السفر",
  "Registration via App or Store": "التسجيل عبر التطبيق أو في المتجر",
  "Full Wallet": "المحفظة الكاملة",
  "Register with QID ": "التسجيل عبر رقم بطاقة إثبات الشخصية القطرية ",
  "Registration via Store": "التسجيل في المتجر",
  "Visit Store": "زيارة المتجر",
  Assistance: "المساعدة",
  "Empowering Self Help": "المساعدة ذاتية المشجعة",
  FAQ: "الأسئلة المتكررة",
  "Frequently Asked Questions": "الأسئلة المتكررة",
  Tutorials: "البرامج التعليمية",
  "Learn to use iPay App": "تعلم كيفية استخدام تطبيق iPay",
  Troubleshooting: "اكتشاف المشاكل وإصلاحها",
  "Find solutions to all your issues":
    "اعثر على حلول لجميع المشاكل التي تواجهها",
  "Self Help Tutorials": "برامج تعليمية للمساعدة الذاتية",
  "International Transfers": "التحويلات الدولية",
  "Local Transfers": "التحويلات المحلية",
  Settings: "الإعدادات",
  "Cash In & Out": "الإيداع والسحب النقدي",
  Beneficiary: "المستفيد",
  Transactions: "التحويلات",
  "Video Title": "عنوان الفيديو",
  "Get in touch": "تواصل معنا",
  "Leave us your details & we will get back to you":
    "اترك لنا تفاصيلك وسنعاود الاتصال بك",
  "You can also call our iPay experts 24/7 on 4000 0555":
    "يمكنك أيضاً الاتصال بخبراء iPay على مدار الساعة طيلة أيام الأسبوع على الرقم 4000‎ 0555",
  "First & last name": "الاسم الأول واسم العائلة",
  "Mobile number": "رقم الجوال",
  "Local contact number": "رقم الاتصال المحلي",
  "E-mail": "البريد الإلكتروني",
  "Leave your feedback & comments": "اترك ملاحظاتك وآرائك وتعليقاتك",
  Submit: "إرسال",
  "Visit us": "تفضل بزيارتنا",
  Branches: "الفروع",
  Kiosks: "الأكشاك",
  "Self Service Machines": "أجهزة الخدمة الذاتية",
  "Show more": "عرض المزيد",
  "View on map": "عرض على الخريطة",
  "Nearest to you": "الأقرب إليك",
  "Coming soon": "قريباً",
  "Get directions": "الحصول على التوجيهات",
  "Your browser does not support the video tag":
    "متصفحك الحالي لا يدعم تشغيل الفيديو.",
  "Play on Enlarged View": "العب على العرض الموسع",
  "Maximize the image": "تكبير الصورة",
  search: "بحث",
  SUBMIT: "إرسال",
  English: "إنجليزي",
  Arabic: "عربي",
  "No Results found, Try to search more specific.":
    "لم يتم العثور على نتائج ، حاول البحث بشكل أكثر تحديدًا.",
  "Search Results": "نتائج البحث",
  "Something went wrong": "هناك خطأ ما",
  "No Direction available for the selected route":
    "لا يوجد اتجاه متاح للمسار المحدد",
  "Your Feedback Has Been Submitted": "مراجعتك قد تم تسليمها!",
  "We'll contact you soon.": "سنتصل بك قريبًا.",
  "Unable to fetch data at this moment.":
    "غير قادر على جلب البيانات في هذه اللحظة.",
  "Something went wrong. Please contact Admin":
    "هناك خطأ ما. يرجى الاتصال بالمسؤول",
  "Please visit the": "يرجى زيارة",
  "This is not the working page of the application":
    "هذه ليست صفحة عمل التطبيق",
  NAME_VALIDATION:
    "لا يقبل الاسم سوى الحروف الأبجدية التي يتراوح طولها بين 3 و 30.",
  MSISDN_VALIDATION:
    "رقم الهاتف المحمول يقبل فقط الأرقام التي يتراوح طولها بين 5و 15.",
  FEEDBACK_VALIDATION:
    "تقبل التعليقات فقط الأبجدية الرقمية وبعض الأحرف الخاصة التي يزيد طولها عن 5",
  SEARCH_VALIDATION: "إدخال بحث غير صالح.",
  EMAIL_VALIDATION: "* البريد الإلكتروني غير صالح",
  PERMISSION_DENIED: "الرجاء تمكين الموقع للحصول على الاتجاهات.",
  TARIFF_CHART: "مخطط التعرفة",
  CHARGES: "شحنة",
  "This Offer applies to recharge done from the iPay application":
    " ينطبق هذا العرض على تعبئة الرصيد باستخدام تطبيق iPay",
  "Customer can get the extra benefits each time they recharge till the end of the promotion period":
    "يمكن للعميل الحصول على المزايا اإلضافية في كل مرة يقوم فيها بتعبئة الرصيد حتى نهاية فترة العرض الترويجي",
  "Offer Valid from 18/01/2023 to 18/04/2023 (Promo Period)":
    "يسري العرض من 18/01/2023 إلى 18/04/2023 (فترة العرض الترويجي)",
  "General Prepaid Terms & Conditions apply":
    "تطبق الشروط واألحكام العامة للدفع المسبق",
  "Offer is applicable for recharge denominations":
    "يرسي العرض عىل فئات تعبئة الرصيد المحددة في الجدول أدناه",
  "To avail the offer, customers must use the iPay e-wallet to make their bill payment":
    " لالستفادة من العرض، يجب على العمالء استخدام محفظة iPay اإللكترونية إلجراء الدفع",
  "This offer is over and above any other offers that they are currently getting.":
    "هذا العرض يفوق أي عروض أخرى يحصلون عليها حاليًا",
  'The extra local data ("Benefit") will depend on the bill value as per the grid:':
    "ستعتمد مكافأة اإلنترنت المحلي اإلضافي على قيمة الفاتورة تبعاً للجدول أدناه:",
  "The Benefit will only be given to the primary number linked to the account and not on the number that makes the payment.":
    "سيتم منح المكافأة للرقم األساسي المرتبط بالحساب فقط وليس للرقم الذي يتم الدفع من خالله.",
  "Customers eligible for the Benefit will receive the bonus data on their primary number 24 hours after they make the payment.":
    "سيحصل العمالء المؤهلين للعرض على المكافأة عبر رقمهم األساسي بعد 24 ساعة من إجراء الدفع.",
  "Customers will get a notification message on their primary number once the free data has been activated.":
    "سيتلقى العمالء رسالة إشعار على رقمهم األساسي بمجرد تفعيل البيانات المجانية.",
  "Offer start date: 18th Jan 2023 and ends on 18th April 2023":
    "يبدأ العرض في 18 يناير 2023 وينتهي في 18 أبريل 2023",
  "Standard Post-paid terms and conditions apply.":
    "تطبق الشروط والأحكام العامة للباقات الشهرية",
  "Pay your next Vodafone bill using iPay and get up to 1GB local data for free":
    "ادفع فاتورة فودافون باستخدام iPay واحصل على ما يصل إلى 1 جيجابايت من اإلنترنت المحلي مجانًا",
  "Recharge with iPay and get 8% extra benefit on all recharges of QR30 & above":
    "قم بتعبئة رصيدك عبر iPay بمبلغ ٣٠ ر.ق وما فوق واحصل على 8 %قيمة إضافية.",
  "Offer valid from 18 January 2023 till 18 April 2023. Terms & Conditions Apply*":
    "يسري العرض من تاريخ 18 يناير 2023 إلى 18 أبريل 2023. تطبق الشروط واألحكام*",
  "Bill amount": "قيمة الفاتورة",
  "Benefit (local)": "المكافأة",
  "Validity - Days": "الصالحية - أيام",
  "Up to QR 10": "ما يصل إلى ١٠ ر.ق",
  "QR 11 - QR 20": "QR 11 - QR 20",
  "QR 21 - QR 50": "QR 21 - QR 50",
  "QR 51 - QR 99": "QR 51 - QR 99",
  "QR 100 & above": "100 ر.ق وما فوق",
  "50 MB Extra Free": "50 ميجابايت مجاناً",
  "100 MB Extra Free": "100 MB Extra Free",
  "200 MB Extra Free": "200 MB Extra Free",
  "500 MB Extra Free": "500 MB Extra Free",
  "1 GB Extra Free": "جيجابايت مجانا",
  1: "1",
  7: "7",
  "All Offers": "كل العروض",
  "View details": "عرض التفاصيل",
  Offers: "عروض",
  "Go to Merchant": "اذهب إلى التاجر",
  "Is it a game idea? Or a business? Or anything else?.":
    "هل هي فكرة لعبة؟ أو الأعمال التجارية؟ او اي شيء اخر؟.",
  "If you can visualise it, we can code it! Our apps are well designed, fluid and loved by everyone. Take a look at some of our recent projects.":
    "إذا كنت تستطيع تصور ذلك، يمكننا ترميزه! تطبيقاتنا مصممة بشكل جيد وسلسة ومحبوبة من قبل الجميع. ألق نظرة على بعض مشاريعنا الأخيرة.",
  "Get your custom web app built with us; whether it is for productivity or a great idea you may have. We have engineered state of the art web-apps that can amaze anyone! Have a look for yourself.":
    "احصل على تطبيق الويب المخصص الخاص بك والذي تم تصميمه معنا؛ سواء كان ذلك من أجل الإنتاجية أو فكرة رائعة قد تكون لديكم. لقد قمنا بتصميم تطبيقات الويب الحديثة التي يمكن أن تدهش أي شخص! إلقاء نظرة لنفسك.",
  "International Transfer": "النقل الدولي",
  "Bill Payments": "دفع الفواتير",
  Offers: "عروض",
  "Merchant Payments": "مدفوعات التاجر",
  "iPay Payroll Product": "منتج الرواتب iPay",
  "iPay Cards": "بطاقات آي باي",
  "Wallet Transfer": "نقل المحفظة",
  "Experience best rates and speed of instant international transfers. With iPay, your money reaches its destination in seconds, not days. Our extensive network spans over 43 countries and growing!":"استمتع بأفضل الأسعار وبسرعة التحويلات الدولية الفورية. مع iPay، تصل أموالك إلى وجهتها في ثوانٍ، وليس أيامًا. تمتد شبكتنا الواسعة إلى أكثر من 43 دولة وهي في توسّع مستمر!",
  "Manage all your bills in one place. iPay is your one-stop e-wallet for stress-free bill payments. Currently you can pay your Vodafone and Kahramaa bills.":"يمكنك الآن إدارة جميع فواتيرك في مكان واحد. iPay هي محفظتك الإلكترونية الشاملة لدفع الفواتير بكل سهولة. حالياً يمكنك دفع فواتير فودافون وكهرماء.",
  "Enjoy exclusive iPay benefits such as Savings up to QR 6000, free My Book deals , special rate on remittance fee and many cashbacks offers throughout the year.":" استمتع بمزايا iPay الحصرية مثل توفيرات تصل إلى 6000 ريال قطري، وعروض My Book المجانية، وسعر خاص على رسوم التحويلات والعديد من عروض الاسترداد النقدي على مدار العام.",
  "Use iPay to pay at more than 5000 retailers for a seamless shopping experience.":"استخدم iPay للدفع لدى أكثر من 5000 متجر واستمتع بتجربة تسوق سلسة.",
  "iPay offers you a convenient payroll product to receive your salary through your iPay wallet account with no need to open a new bank account. You also get a free payroll card which can be used anywhere in Qatar and for online purchases.":"تقدّم لك خدمة iPay منتجاً مناسباً لكشوف الرواتب حيث تتيح لك تلقي الراتب من خلال حسابك في محفظة iPay دون الحاجة إلى فتح حساب مصرفي جديد. يمكنك أيضاً الحصول على بطاقة رواتب مجانية يمكن استخدامها في أي مكان في قطر وللشراء عبر الإنترنت.",
  "Discover the easy way to pay anywhere in Qatar and online shopping with our free iPay cards. You have full control in your iPay Wallet card section to order a card, block your card or simply make a payment.":"اكتشف طريقة سهلة للدفع في أي مكان في قطر وللتسوّق عبر الإنترنت باستخدام بطاقات iPay المجانية الخاصة بنا. لديك الحق الكامل في التصرف بقسم بطاقة محفظة iPay لطلب البطاقة أو حظر بطاقتك أو حتى الدفع فقط.",
  "Send money to any registered mobile number in Qatar. With iPay, your money reaches its destination in seconds, not days!":"أرسل الأموال إلى أي رقم هاتف جوّال مسجَّل في قطر. مع iPay، تصل أموالك إلى وجهتها في ثوانٍ لا أيام!"

};

const fetchTranslations = (locale) => {
  let translations;
  if (locale === "ar") {
    translations = ar_translation;
  } else {
    translations = en_translation;
  }
  return translations;
};

export default fetchTranslations;
