import { MOBILE_RED_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect } from 'react';

const Button = props => {

    const { height = 45, width = 120,
        backgroundColor = "#fff",
        loading, wait = false,
        onSubmit = () => { },
        color = "#e70707",
        translations,
        buttonText = "SUBMIT",
        border = undefined,
        fontSize = "16px",
        padding = "10px 20px",
        type = "button", ...rest } = props;

    const styles = {
        rectangle: {
            width: width,
            height: height,
            margin: 5,
            textAlign: "center",
            borderRadius: "22.8px",
            cursor: 'pointer',
            backgroundColor: backgroundColor
        },
        content: {
            fontSize: fontSize,
            fontWeight: "bold",
            lineHeight: 2.86,
            color: color,
            borderRadius: "30px",
            border: border,
            cursor: 'pointer'
        }
    }

    useEffect(() => {
        var dots;
        var waitElt = document.getElementById("wait");
        if (loading && wait) {
            dots = setInterval(function () {
                if (waitElt.innerHTML.length > 3)
                    waitElt.innerHTML = "";
                else
                    waitElt.innerHTML += ".";
            }, 100);
        } else {
            waitElt.innerHTML = "";
            clearInterval(dots);
        }

        return () => clearInterval(dots)
    }, [loading])

    return (
        <>
            <div style={styles.rectangle} onClick={onSubmit}>
                <div style={styles.content}>
                    <button {...rest} type={type} style={{ cursor: 'pointer' }} >
                        {translations[buttonText]}{wait && <span id="wait" style={{
                            color: color, fontSize: fontSize,
                            fontWeight: "bold",
                        }}></span>}
                    </button>
                </div>
            </div>
        </>
    )

}

export default Button;