export const PAGE_MAPPING = {
  0: { path: "/home" },
  1: { path: "/discover", slideIndex: 0 },
  2: { path: "/discover", slideIndex: 1 },
  3: { path: "/assistance" },
  4: { path: "/assistance/faq" },
  5: { path: "/assistance/tutorials" },
  6: { path: "/assistance/troubleshooting" },
  7: { path: "/contact", slideIndex: 0 },
  8: { path: "/contact", slideIndex: 1 },
};

export const RED_PRIMARY_COLOR = "#C80600";
export const WHITE_PRIMARY_COLOR = "#fff";
export const GRAY_FOOTER_COLOR = "#2A292B";
export const GRAY_COLOR = "#EAEAEA";
export const PEACH_SHODOW_COLOR = "#fbb962";
export const MOBILE_RED_PRIMARY_COLOR = "#C80600";
export const OPAQUE_COLOR = "#f0f0f0";
export const FIRST_BATCH = 3;
export const BATCH_LENGTH = 6;
export const QA_TYPE_IMAGE = "image";
export const QA_TYPE_VIDEO = "video";
export const maxDots = 4;
// export const GOOGLE_MAP_URL = window.GOOGLE_MAP_URL;
export const GOOGLE_KEY = "AIzaSyDG0foWCtBkAHA1NDYHUe8Q1K8NCFwSkIY";
export const GOOGLE_KEY_1 = "AIzaSyCwsGu_aLvP0-GfaMyeRbUKpzvm4o8fHaI";
export const ZENDESK_KEY = "6840bb5a-ce3b-41bd-afd8-ba5d8c6a4dd6";
export const TRACKING_ID = "G-0B1YVCHK6T";
export const API_KEY_NOTIFICATION =
  "BB93n9WmUhKxX_a-nsNk4-NrF_bKlBZs58sLhb7mEcGHkMEkf-80HsMtPmPM_M-y3K74E3S5szdotI27iN8Ov0g";

// export const BASE_URL = 'https://digital.mv1.in/iPayOnlineInterface/api/v1/'

const BASE_URL = window.BASE_URL;

export const HOME_PAGE_URL = BASE_URL + "fetchHomePageContent";
export const GENERIC_PAGE_URL = BASE_URL + "fetchgenericpagecontent";
export const FETCH_ALL_CATEGORIES = BASE_URL + "fetchAllCategory";
export const FETCH_OFFERS_BY_CATEGORY_ID = BASE_URL + "fetchOffersByCategoryId";
export const FETCH_OFFERS_BY_CONTEXT = BASE_URL + "fetchOffersByContext";
export const FETCH_OFFERS_BY_ID = BASE_URL + "fetchOffersByID";
export const FETCH_ALL_OFFERS = BASE_URL + "fetchAllOffers";
export const DISCOVER_PAGE_URL = BASE_URL + "fetchDiscoverPageContent";
export const ASSISTANCE_PAGE_URL = BASE_URL + "fetchAssistancePageContent";
export const CONTACT_PAGE_URL = BASE_URL + "fetchcontactpagecontent";
export const FAQ_PAGE_URL = BASE_URL + "fetchFaqContent";
export const SEARCH_FAQ_CONTENT = BASE_URL + "searchFaqContent";
export const TS_PAGE_URL = BASE_URL + "fetchTroubleShootingContent";
export const SEARCH_TS_CONTENT = BASE_URL + "searchTroubleShootingContent";
export const SAVE_CUSTOMER_DETAILS = BASE_URL + "savecustomerdetails";
export const FETCH_TUTORIAL_CONTENT = BASE_URL + "fetchTutorialContent";
export const FETCH_ALL_TUTORIAL_CONTENT = BASE_URL + "fetchAllTutorialContent";
export const FETCH_PRIVACY_POLICY = BASE_URL + "privacypolicy";
export const FETCH_TERMS_AND_CONDITIONS = BASE_URL + "termsandconditions";
export const FETCH_HEADER_AND_FOOTER = BASE_URL + "fetchheaderfooter";
export const FETCH_TARIFF_DETAILS = BASE_URL + "fetchtariffdetails";
export const GLOBAL_SEARCH = BASE_URL + "globalsearch";
export const BASE_REDIRECT_URL = window.BASE_REDIRECT_URL;

export const CHAT_URL =
  "https://vfqatar.egain.cloud/system/templates/chat/vodafoneqa2/index.html?entryPointId=1001&templateName=vodafoneqa2&integrationType=AVAYA&ver=v11&locale=en-US";

export const ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.mwallet.vfq";

export const IOS_URL = "https://apps.apple.com/in/app/ipay-qatar/id1581998579";
