import LabelBox from 'components/LabelBox';
import { GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';

const EmbededHeader = props => {

    const location = useLocation();
    const { responsive, translations, setLocale, header, locale } = props;

    const styles = {
        tabContainer: {
            display: 'flex',
            padding: '1.5rem',
            paddingBottom: 0,
            borderTopRightRadius: '36px',
            borderTopLeftRadius: '36px',
            backgroundColor: WHITE_PRIMARY_COLOR,
            flexDirection: 'column'
        },
        tab: {
            display: 'flex',
        },
    }

    const [tabsList, setTabsList] = useState(() => {
        const tabsList = [];
        if (header) {
            header.forEach((item, index) => {
                if (index != 2) {
                    tabsList.push({
                        active: false,
                        url: `/${item.title}`,
                        label: `${item.displayTitle}`
                    })
                }
            })
        }
        return tabsList
    });

    useEffect(() => {

        const currentUrl = location.pathname;
        const updatedTabsList = [];
        tabsList.forEach((tab) => {
            let modifiedTab = { ...tab };
            if (tab.active && currentUrl.indexOf(tab.url) === -1) {
                modifiedTab.active = false;
            } else if (currentUrl.indexOf(tab.url) > -1) {
                modifiedTab.active = true;
            }
            updatedTabsList.push(modifiedTab);
        });
        setTabsList(updatedTabsList)

    }, [location])

    const handleClick = (url) => {
        props.history.push(url);
    }

    const handleBack = () => {
        props.history.goBack()
    }
    return (<>
        <div style={styles.tabContainer}>
            {location && (location.pathname !== '/home') ? < div style={{
                color: GRAY_FOOTER_COLOR, display: 'flex', cursor: 'pointer',
                fontSize: 26, paddingBottom: '0.5rem', width: "fit-content"
            }} onClick={handleBack}>
                &#8592;
            </div> : < div style={{
                color: GRAY_FOOTER_COLOR, display: 'flex',
                fontSize: 26, paddingBottom: '0.5rem'
            }} >
            </div>}
            <div style={{
                display: 'flex', width: '100%', fontSize: '11px',
                justifyContent: 'space-between', paddingBottom: '1rem'
            }}>
                {tabsList.map(tab => {
                    return (
                        <div key={tab.label} style={styles.tab}>
                            <LabelBox handleClick={() => handleClick(tab.url)}
                                undefinedColor={MOBILE_RED_PRIMARY_COLOR}
                                height="4px"
                                padding="10px 10px"
                                inactiveColor={MOBILE_RED_PRIMARY_COLOR}
                                backgroundColor={MOBILE_RED_PRIMARY_COLOR}
                                border={`0.75px solid ${MOBILE_RED_PRIMARY_COLOR}`}
                                id="mobile-link" labelText={tab.label} color={WHITE_PRIMARY_COLOR}
                                active={tab.active} embededActive={true} textCase="uppercase" />
                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}

export default withRouter(EmbededHeader)