import firebase from './firebase';


export function logEvent(event, eventParams, eventOptions) {
    let analytics;
    if (firebase) {
        analytics = firebase.analytics();
    }
    if (analytics) {
        analytics.logEvent(event, eventParams, eventOptions);
    }
}

