import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./card.module.css";

const MobileCard = (props) => {
  const {
    image,
    redirectUrl,
    heading,
    subHeading,
    style,
    translations,
    fromGeneric,
  } = props;
  const locale = localStorage.getItem("locale");

  const redirectHandler = (redirectUrl) => {
    if (redirectUrl.startsWith("http")) {
      window.open(redirectUrl);
    } else {
      props.history.push(redirectUrl);
      if (fromGeneric) window.location.reload(false);
    }
  };

  return (
    <>
      <div style={style} className={classes.container}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={image} />
        </div>

        <div>
          <div className={classes.headingContainer}>
            <div
              className={classes.heading}
              dir={locale === "ar" ? "rtl" : "ltr"}
            >
              {heading}
            </div>
            <div
              className={classes.subHeading}
              dir={locale === "ar" ? "rtl" : "ltr"}
            >
              {subHeading}
            </div>
          </div>

          <div
            className={classes.redirectButtonContainer}
            style={{ ...(locale === "ar" && { textAlign: "left" }) }}
          >
            <button
              onClick={() => {
                if (redirectUrl) return redirectHandler(redirectUrl);
              }}
              className={classes.redirectButton}
            >
              {translations?.["View details"] || "View Details"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MobileCard);
