// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getMessaging, onMessage } from "firebase/messaging";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyATs_det0K-q6S750lBE3d2IkxmLpG1rwg",
//   authDomain: "ipaystaging.firebaseapp.com",
//   databaseURL: "https://ipaystaging.firebaseio.com",
//   projectId: "ipaystaging",
//   storageBucket: "ipaystaging.appspot.com",
//   messagingSenderId: "369860431091",
//   appId: "1:369860431091:web:613687251fcde65ff3fb86",
//   measurementId: "G-0B1YVCHK6T"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

// onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     // ...
//   });


import firebase from "firebase/compat";

const firebaseConfig = {
  apiKey: "AIzaSyATs_det0K-q6S750lBE3d2IkxmLpG1rwg",
  authDomain: "ipaystaging.firebaseapp.com",
  databaseURL: "https://ipaystaging.firebaseio.com",
  projectId: "ipaystaging",
  storageBucket: "ipaystaging.appspot.com",
  messagingSenderId: "369860431091",
  appId: "1:369860431091:web:613687251fcde65ff3fb86",
  measurementId: "G-0B1YVCHK6T"
};

firebase.initializeApp(firebaseConfig);
// const messaging = app.messaging();

// // Get registration token. Initially this makes a network call, once retrieved
// // subsequent calls to getToken will return from cache.
// messaging.getToken({ vapidKey: 'BB93n9WmUhKxX_a-nsNk4-NrF_bKlBZs58sLhb7mEcGHkMEkf-80HsMtPmPM_M-y3K74E3S5szdotI27iN8Ov0g' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     // ...
//     console.log('current token', currentToken)
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });

export default firebase;