import ComingSoon from 'components/ComingSoon';
import LabelBox from 'components/LabelBox';
import Wrapper from 'components/Wrapper';
import { logEvent } from 'firebase-analytics';

import {
    BATCH_LENGTH, FIRST_BATCH, GRAY_COLOR, GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, OPAQUE_COLOR, PEACH_SHODOW_COLOR,
    RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR
} from 'global/constants';
import React, { useEffect, useState } from 'react';
import Fallback from 'components/Fallback';
import MyGoogleMap from 'components/MyGoogleMap';
import { withRouter } from 'react-router-dom';
import { formatTimings } from 'global/utils';

const Branches = props => {

    const { locations, currentLocation, translations, responsive, title, setLocation } = props;
    const [endItem, setEndItem] = useState(0);
    const [batchLoading, setBatchLoading] = useState(true);
    const [batches, setBatches] = useState([]);
    const [showMore, setShowMore] = useState(0);
    const [currentBatch, setCurrentBatch] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState();

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1em'
        }
    }
    useEffect(() => {
        const totalItems = locations.length;
        const newBatches = [];
        if (totalItems > 0) {
            if (totalItems < FIRST_BATCH)
                newBatches[0] = totalItems;
            else
                newBatches[0] = FIRST_BATCH;
            const batchCount = Math.floor((totalItems - FIRST_BATCH) / BATCH_LENGTH);
            for (let index = 0; index < batchCount; index++) {
                newBatches.push(BATCH_LENGTH);
            }
            const remainingItems = (totalItems - FIRST_BATCH) % BATCH_LENGTH;
            if (remainingItems >= 0)
                newBatches.push(remainingItems);
            setBatches(newBatches);
            setEndItem(newBatches[0] ? newBatches[0] : 0);
            setShowMore(newBatches[1] ? newBatches[1] : 0);
        }
        setBatchLoading(false);
    }, [])

    const updateBatch = () => {
        setBatchLoading(true);
        setTimeout(() => {
            setEndItem(prevEndItem => prevEndItem + batches[currentBatch + 1]);
            setShowMore(batches[currentBatch + 2] ? batches[currentBatch + 2] : 0);
            setCurrentBatch(prevBatch => prevBatch + 1);
            setBatchLoading(false);
        }, 1000);
    }
    return (
        <div style={styles.container}>
            <div className="mobile-title">
                {title}
            </div>
            {locations.length === 0 && batches.length === 0 ?
                <ComingSoon translations={translations} responsive={responsive} /> : selectedLocation ? <>
                    <div style={{
                        marginTop: 5,
                    }}>
                        <DetailedStore translations={translations} selectedLocation={selectedLocation}
                            currentLocation={currentLocation} setLocation={setLocation} />
                    </div>
                </> :
                    <div>
                        <div style={{
                            marginTop: 5,
                            marginBottom: 20,
                            justifyContent: 'center',
                            display: 'grid',
                            gridTemplateColumns: "auto auto",
                            gap: '10px',
                        }}>
                            {locations.slice(0, endItem).map((location, index) =>
                                <Store key={index + ""} translations={translations} location={location} id={location.id}
                                    key={location.id + ""} setSelectedLocation={setSelectedLocation}
                                    isNearest={index === 0} />)}
                        </div>
                        {showMore !== 0 ? batchLoading ? <Fallback loading={batchLoading} style={{ height: "2em" }}
                            color={responsive ? MOBILE_RED_PRIMARY_COLOR : WHITE_PRIMARY_COLOR} /> : <div style={{
                                fontSize: '14px', display: 'flex', justifyContent: 'center',
                                fontWeight: 'bold', margin: "20px auto 0px auto"
                            }}>
                            <LabelBox translations={translations}
                                border={`1px solid ${RED_PRIMARY_COLOR}`}
                                embededActive={true}
                                labelText={`${translations["Show more"]}(${showMore})`}
                                handleClick={updateBatch}
                                active={true} />
                        </div> : undefined}
                    </div>
            }
        </div>
    )
}

const Store = props => {

    const { isNearest = true, id, location, setSelectedLocation, translations } = props;

    return <div style={{ textAlign: 'center' }}>
        <Wrapper borderRadius="12px">
            <div style={{ height: 150, width: 140, fontSize: '8px' }}>
                {isNearest ? <div style={{
                    width: '65%',
                    height: 15,
                    margin: 'auto',
                    textAlign: 'center',
                    borderRadius: '0px 0px 30px 30px',
                    backgroundColor: RED_PRIMARY_COLOR
                }}>
                    <div style={{
                        fontWeight: 'bold',
                        color: WHITE_PRIMARY_COLOR,
                        lineHeight: 2
                    }}>
                        {translations["Nearest to you"]}
                    </div>
                </div> : <div style={{ height: 15 }}></div>}
                <div style={{
                    fontSize: '10px', textOverflow: 'ellipsis',
                    overflow: 'hidden', fontWeight: 'bold',
                    color: RED_PRIMARY_COLOR, margin: "10px 10px 5px 10px",
                    display: "-webkit-box",
                    height: "25px",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                }}>
                    {location.displayTitle}
                </div>
                <div style={{ height: "16px", marginBottom: "12px", marginTop: "15px" }}>
                    Address:
                    <div style={{
                        margin: '0px 10px',
                        overflowY: 'auto',
                        maxHeight: 20,
                    }} className="branches_visitus">
                        {location.address}
                    </div>
                </div>
                <div style={{
                    fontWeight: 'bold',
                    display: 'flex', justifyContent: 'center',
                    width: '80%', margin: '25px auto'
                }}>
                    <LabelBox handleClick={() => {
                        logEvent("view_on_map", {
                            search_term: location.displayTitle
                        });
                        setSelectedLocation(location)
                    }}
                        height="10px"
                        border={`0.75px solid ${RED_PRIMARY_COLOR}`}
                        embededActive={true}
                        padding="10px 10px"
                        active={true}
                        marginRight="2px"
                        icon={true} iconname="/iPayV2/icons/location.svg"
                        labelText={translations["View on map"]} color={RED_PRIMARY_COLOR}
                        backgroundColor={GRAY_COLOR}
                    />
                </div>
            </div>
        </Wrapper>
    </div >
}


const DetailedStore = props => {

    const { currentLocation, selectedLocation, translations, setLocation } = props;
    const [error, setError] = useState(false);
    const [distance, setDistance] = useState();

    useEffect(() => {
        var errorTimeout;
        if (error) {
            errorTimeout = setTimeout(() => {
                setError(false)
            }, 3000);
        } else {
            clearTimeout(errorTimeout);
        }

        return () => clearTimeout(errorTimeout)
    }, [error])


    const styles = {
        answerLine: {
            height: "1px",
            backgroundColor: ' rgba(112, 112, 112, 0.16)',
            margin: "4px 0px 4px 0px",
            lineHeight: "1.67"
        },
        smallImages: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        smallImagesText: {
            fontSize: 10,
            lineHeight: 1.7,
            marginTop: '0.5em'
        },
        inputWrapper1: {
            display: 'flex', width: '100%',
            justifyContent: 'center',
            animation: 'up 0.5s',
            animationTimingFunction: 'ease-in-out'
        },
        input: {
            display: 'flex', flexDirection: 'column',
        }
    }
    return (
        <div style={{
            display: 'flex', zIndex: 1, flexDirection: 'column',
            width: `calc(${window.innerWidth}px)`, alignItems: 'center',
            marginLeft: '-1em', justifyContent: 'center', position: 'relative',
            height: `40rem`, marginBottom: '-2.5rem', marginRight: '-1em',
        }}>
            <div style={{
                position: 'absolute',
                display: 'flex', fontSize: '14px',
                backgroundColor: WHITE_PRIMARY_COLOR,
                boxShadow: `0px 0px 1px ${GRAY_FOOTER_COLOR}`,
                bottom: '0em', overflow: 'auto', width: "55%",
                zIndex: 11, padding: '1em',
                borderRadius: '26px 26px 0px 0px', maxHeight: '15em', minHeight: '15em'
            }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                    <div style={{
                        fontWeight: 'bold', height: 40,
                        color: GRAY_FOOTER_COLOR, margin: "0 0 0.5em 0"
                    }}>
                        {selectedLocation.displayTitle}
                    </div>
                    <div style={{
                        color: GRAY_FOOTER_COLOR, margin: "0.5em 0 0 0"
                    }}>
                        <div style={{
                            maxHeight: "4rem",
                            overflowY: 'auto',
                            width: "100%"
                        }} className="branches_visitus">
                            {selectedLocation.address}
                        </div>
                    </div>
                    {/* <br /> */}
                    {/* <div style={{ maxHeight: 100, overflowY: 'auto', margin: '0px 10px', }} className="branches_visitus">
                        {selectedLocation.regularTimings === "" || selectedLocation.regularTimings === "NA" ?
                            selectedLocation.regularTimings :
                            <div>
                                {formatTimings(selectedLocation.regularTimings).map(timing => <div>{timing}</div>)}
                            </div>
                        }
                    </div> */}
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column', padding: '1em',
                    width: '100%', alignItems: 'flex-end'
                }}>
                    <div id="directions" style={{ display: 'flex' }}>
                        <img src="/iPayV2/icons/mobile-directions.svg" />
                    </div>
                    {distance && <div style={{ display: 'flex', fontSize: '10px', color: GRAY_FOOTER_COLOR, marginTop: '0.5rem' }}>
                        {distance}
                    </div>}
                    {error && <div id="error" style={{
                        ...styles.inputWrapper1, marginTop: '2px',
                        zIndex: 10, bottom: '0.5em',
                    }}>
                        <div style={{ ...styles.input }}>
                            <span style={{ fontSize: 13, fontWeight: 'bold', color: RED_PRIMARY_COLOR }}>{error}</span>
                        </div>
                    </div>}
                </div>
            </div>
            {error && <div style={{
                ...styles.inputWrapper1, marginTop: '2px', position: 'absolute',
                zIndex: 10, bottom: '0.5em', left: '-1.5em'
            }}>
                <div style={{ ...styles.input }}>
                    <span style={{ fontSize: 13, fontWeight: 'bold', color: RED_PRIMARY_COLOR }}>{error}</span>
                </div>
            </div>}
            <MyGoogleMap setDistance={setDistance} translations={translations}
                setError={setError} currentLocation={currentLocation} setLocation={setLocation}
                selectedLocation={selectedLocation} marginLeft="unset" />
        </div>)
}

export default withRouter(Branches);