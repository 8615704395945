import ComingSoon from 'components/ComingSoon'
import LabelBox from 'components/LabelBox'
import Wrapper from 'components/Wrapper'
import { GRAY_COLOR, GRAY_FOOTER_COLOR, MOBILE_RED_PRIMARY_COLOR, OPAQUE_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const DiscoverMobileFold2 = props => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "column",
            marginTop: '1rem'
        },
    }

    const { data, translations, handleSlidePlay, responsive,
        handleSlidePause, footerIconHandler } = props;
    const { rightContainer, leftContainer } = data;
    useEffect(() => {
        const nodes = document.getElementsByTagName('video');
        if (nodes && nodes[0])
            nodes[0].pause()
    }, [])

    const handleClick = () => {
        props.history.push('/contact', {
            slideIndex: 1
        })
    }

    return (<div style={styles.container}>
        <div className="mobile-title">
            {leftContainer.title}
        </div>
        <div onMouseEnter={handleSlidePause}
            onMouseLeave={handleSlidePlay}
            className="wallet_grid">
            {rightContainer.wallets.map((wallet, index) => {
                return (<Wallet key={index + ""} {...wallet}
                    handleClick={handleClick} responsive={responsive}
                    translations={translations} footerIconHandler={footerIconHandler} />)
            })}
        </div>
    </div>)
}


const Wallet = props => {

    const { displayTitle, features = [], handleClick, translations, footerIconHandler, responsive } = props;

    return (<div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
        <div className="wallet">
            {displayTitle}
        </div>
        <Wrapper backgroundColor={OPAQUE_COLOR} borderRadius="16px" height="100%">
            <div style={{
                width: 210, minHeight: 270, overflow: 'auto', fontWeight: 'normal',
                display: 'flex', flexDirection: 'column'
            }}>
                <div style={{ fontSize: '16px', fontWeight: 'bold', padding: "1rem 0 1.5rem 0" }}>
                    {translations["Features"]} :
                </div>
                {features.length === 0 ? <ComingSoon responsive={responsive} translations={translations} /> : features.map((feature, index) => {
                    return (<div style={{
                        fontSize: '16px', lineHeight: '1.5',
                        color: GRAY_FOOTER_COLOR, width: "90%", margin: 'auto'
                    }}>
                        {feature.featureType === 'text' && feature.featureDescription}
                        {feature.featureType === 'icon' && <div style={{
                            display: 'flex',
                            justifyContent: 'center', padding: "0.5rem 0"
                        }}>
                            {feature.assets && feature.assets.map(asset => {
                                return <img src={asset.mediaUrl} width={22} height={22}
                                    style={{ padding: "0 0.5rem", cursor: 'pointer' }}
                                    onClick={() => footerIconHandler(asset.redirectUrl)} />
                            })}
                        </div>}
                        {feature.featureType === 'button' &&
                            <div style={{
                                display: 'flex', justifyContent: 'center', fontSize: '8px',
                                padding: "0.5rem 0", fontWeight: 'bold', cursor: 'pointer'
                            }}>
                                <LabelBox handleClick={handleClick} embededActive={true} labelText={feature.featureDescription}
                                    color={RED_PRIMARY_COLOR} active={true} padding="5px 10px"
                                    border={`0.75px solid ${RED_PRIMARY_COLOR}`}
                                    backgroundColor={GRAY_COLOR} />
                            </div>}
                        {(index !== features.length - 1) && <hr style={{ color: GRAY_FOOTER_COLOR }} />}
                    </div>)
                })}
            </div>
        </Wrapper>
    </div>)

}

export default withRouter(DiscoverMobileFold2)