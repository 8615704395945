import Button from "components/Button";
import InputBox from "components/InputBox";
import { fetchContent } from "global/api";
import {
  GRAY_FOOTER_COLOR,
  RED_PRIMARY_COLOR,
  SAVE_CUSTOMER_DETAILS,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { logEvent } from "firebase-analytics";
const ContactUs = props => {
  const { handleBlur, handleFocus, data, translations } = props;
  const { leftContainer, rightContainer } = data;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [responseCompleted, setResponseCompleted] = useState(false);
  const initialData = {
    // email: "",
    feedbackAndComment: {
      value: "",
      isValid: true,
      pattern: /^[a-zA-Z0-9,()!. -]{5,}$/gi,
      errorMsg: translations["FEEDBACK_VALIDATION"],
    },
    msisdn: {
      value: "",
      isValid: true,
      pattern: /^[0-9]{5,15}$/gi,
      errorMsg: translations["MSISDN_VALIDATION"],
    },
    name: {
      value: "",
      isValid: true,
      pattern: /^[a-zA-Z ]{3,30}$/gi,
      errorMsg: translations["NAME_VALIDATION"],
    },
    email: {
      value: "",
      isValid: true,
      // pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
      // ^[a-zA-Z0-9][\w\-]*[_\w\-]*@[A-Za-z0-9\-A-Za-z0-9]+\.[a-zA-Z]{2,}$
      pattern:
        /^[a-zA-Z0-9][\w\-]*[\.]{0,1}[_\w\-]+@[A-Za-z0-9\-A-Za-z0-9]+\.[a-zA-Z]{2,}$/gi,
      errorMsg: translations["EMAIL_VALIDATION"],
    },
  };
  const [formData, setFormData] = useState(initialData);

  const onChangeHandler = event => {
    setError(false);
    const name = event.target.name;
    const value = event.target.value;
    const regexp = new RegExp(initialData[name].pattern);
    const isvalid = regexp.test(value);

    setFormData(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        isValid: isvalid,
        value: value,
      },
    }));
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "3em",
    },
    leftContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "80px",
      flexDirection: "column",
      flex: "20%",
    },
    description: {
      fontSize: "20px",
      color: GRAY_FOOTER_COLOR,
      fontWeight: "normal",
      lineHeight: "1.2",
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "70%",
      maxHeight: "calc(100vh - 14em)",
      overflow: "auto",
    },
    inputWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginTop: "1em",
    },
    inputWrapper1: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      animation: "up 0.5s",
      animationTimingFunction: "ease-in-out",
    },
    input: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  };

  useEffect(() => {
    var responseTimeout;
    if (responseCompleted) {
      if (document.getElementById("success"))
        document.getElementById("success").scrollIntoView();
      responseTimeout = setTimeout(() => {
        setResponseCompleted(false);
      }, 10000);
    } else {
      clearTimeout(responseTimeout);
    }

    return () => clearTimeout(responseTimeout);
  }, [responseCompleted]);

  const handleFeedback = event => {
    event.preventDefault();
    setLoading(true);
    setError();
    let isValid = true;
    const dataTobeSend = {
      id: "0",
    };
    logEvent("submit_feedback_start");
    if (Object.entries(formData).length > 0) {
      for (const key in formData) {
        if (formData[key].value === "" || !formData[key].isValid) {
          isValid = false;
          setError(formData[key].errorMsg);
          break;
        } else {
          dataTobeSend[key] = formData[key].value;
        }
      }
    } else {
      isValid = false;
      setError(translations["Something went wrong"]);
    }
    if (isValid) {
      fetchContent(SAVE_CUSTOMER_DETAILS, {}, dataTobeSend).then(response => {
        if (response) {
          if (response.code === 2000) {
            setResponseCompleted(true);
            setLoading(false);
            setError();
            setFormData(initialData);
            logEvent("submit_feedback_success");
          }
        } else {
          setError(translations["Something went wrong"]);
          logEvent("submit_feedback_fail");
          setLoading(false);
        }
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (error && document.getElementById("error"))
      document.getElementById("error").scrollIntoView();
  }, [error]);

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer} className="left-container">
        <div className="title">{leftContainer.title}</div>
        <div className="Line-2"></div>
        <div style={styles.description}>
          {leftContainer.pageDesc1}
          <br /> <br />
          {leftContainer.pageDesc2}
        </div>
      </div>
      <div
        style={styles.rightContainer}
        id="rightContainer"
        className="contactus_right_container"
      >
        <form style={{ width: "100%" }}>
          <input type="hidden" autoComplete="off" />
          <div style={styles.inputWrapper}>
            <div style={{ ...styles.input, marginRight: 20 }}>
              <InputBox
                translations={translations}
                value={formData["name"].value}
                name="name"
                handleChange={onChangeHandler}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                labelText={"First & last name"}
                // height={60}
                fontSize="14px"
                placeholder="Enter your name"
              />
              {!formData["name"].isValid && (
                <div
                  style={{
                    color: RED_PRIMARY_COLOR,
                    fontSize: 13,
                    marginTop: 4,
                  }}
                >
                  {translations["NAME_VALIDATION"]}
                </div>
              )}
            </div>
            <div style={{ ...styles.input, marginLeft: 20 }}>
              <InputBox
                translations={translations}
                value={formData["msisdn"].value}
                name="msisdn"
                handleChange={onChangeHandler}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                labelText={"Mobile number"}
                // height={60}
                fontSize="14px"
                placeholder=""
              />
              {!formData["msisdn"].isValid && (
                <div
                  style={{
                    color: RED_PRIMARY_COLOR,
                    fontSize: 13,
                    marginTop: 4,
                  }}
                >
                  {translations["MSISDN_VALIDATION"]}
                </div>
              )}
            </div>
          </div>
          <div style={{ ...styles.inputWrapper, marginTop: 40 }}>
            <div style={{ ...styles.input, marginRight: 20 }}>
              <InputBox
                translations={translations}
                value={formData["email"].value}
                name="email"
                handleChange={onChangeHandler}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                labelText={"E-mail"}
                // height={60}
                type="email"
                fontSize="14px"
                placeholder="Enter your email"
              />
              {!formData["email"].isValid && (
                <div
                  style={{
                    color: RED_PRIMARY_COLOR,
                    fontSize: 13,
                    marginTop: 4,
                  }}
                >
                  {translations["EMAIL_VALIDATION"]}
                </div>
              )}
            </div>
            {/* </div>
          <div style={styles.inputWrapper}> */}
            <div style={{ ...styles.input, marginLeft: 20 }}>
              <InputBox
                translations={translations}
                value={formData["feedbackAndComment"].value}
                name="feedbackAndComment"
                handleChange={onChangeHandler}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                labelText={"Leave your feedback & comments"}
                // height={60}
                fontSize="14px"
                placeholder="Mention your comments"
              />
              {!formData["feedbackAndComment"].isValid && (
                <div
                  style={{
                    color: RED_PRIMARY_COLOR,
                    fontSize: 13,
                    marginTop: 4,
                  }}
                >
                  {translations["FEEDBACK_VALIDATION"]}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              ...styles.inputWrapper,
              marginTop: "1em",
              cursor: "pointer",
            }}
            onMouseLeave={handleBlur}
            onMouseOver={handleFocus}
          >
            <div
              style={{ ...styles.input, alignItems: "center", marginTop: 60 }}
            >
              <Button
                translations={translations}
                onSubmit={handleFeedback}
                backgroundColor={WHITE_PRIMARY_COLOR}
                height={35}
                color={RED_PRIMARY_COLOR}
                fontSize="12px"
                width={100}
                padding="5px 5px"
                border={`0.75px solid ${RED_PRIMARY_COLOR}`}
                type="submit"
                wait={true}
                loading={loading}
              />
            </div>
          </div>
          {responseCompleted && (
            <div
              style={{ ...styles.inputWrapper1, marginTop: "5px" }}
              id="success"
            >
              <div style={{ ...styles.input }}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: GRAY_FOOTER_COLOR,
                    textAlign: "center",
                  }}
                >
                  {translations["Your Feedback Has Been Submitted"]}
                </span>
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: "300",
                    color: GRAY_FOOTER_COLOR,
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {translations["We'll contact you soon."]}
                </span>
              </div>
            </div>
          )}
          {error && (
            <div
              style={{ ...styles.inputWrapper1, marginTop: "5px" }}
              id="error"
            >
              <div style={{ ...styles.input }}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: GRAY_FOOTER_COLOR,
                    textAlign: "center",
                  }}
                >
                  {error}
                </span>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default withRouter(ContactUs);
