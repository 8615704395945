import { logEvent } from "firebase-analytics";
import { GRAY_FOOTER_COLOR, WHITE_PRIMARY_COLOR } from "global/constants";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./search.css";

function autocomplete(id, arr, callback, callback1, callback2) {
  /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
  var a,
    b,
    i,
    j,
    inp = document.getElementById(id);
  /*execute a function when someone writes in the text field:*/
  closeAllLists();
  /*close any already open lists of autocompleted values*/
  if (arr && arr.length === 0) {
    inp.style.borderBottomLeftRadius = "20px";
    inp.style.borderBottomRightRadius = "20px";
    return false;
  }
  /*create a DIV element that will contain the items (values):*/
  a = document.createElement("DIV");
  a.setAttribute("id", id + "autocomplete-list");
  a.setAttribute("class", "autocomplete-items");
  /*append the DIV element as a child of the autocomplete container:*/
  inp.parentNode.appendChild(a);
  /*for each item in the array...*/
  for (i = 0; i < arr.length; i++) {
    /*check if the item starts with the same letters as the text field value:*/
    const value = arr[i];
    inp.style.borderBottomLeftRadius = 0;
    inp.style.borderBottomRightRadius = 0;
    /*create a DIV element for each matching element:*/
    j = document.createElement("MAIN");
    b = document.createElement("DIV");
    /*make the matching letters bold:*/
    b.innerHTML = value.title;
    // b.innerHTML += arr[i].substr(val.length);
    /*insert a input field that will hold the current array item's value:*/
    b.innerHTML += "<input type='hidden' value='" + value.title + "'>";
    /*execute a function when someone clicks on the item value (DIV element):*/
    b.addEventListener("click", function (e) {
      /*insert the value for the autocomplete text field:*/
      /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
      closeAllLists();
      callback(value);
      callback1();
    });
    j.appendChild(b);
    a.appendChild(j);
    callback2();
  }

  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  /*execute a function when someone clicks in the document:*/
  document.addEventListener("click", function (e) {
    if (e.target.id !== id) {
      inp.style.borderBottomLeftRadius = "20px";
      inp.style.borderBottomRightRadius = "20px";
      closeAllLists(e.target);
      callback1();
    }
  });
}

const SearchBox = (props) => {
  const searchRef = useRef();
  const location = useLocation();
  const {
    id = "search",
    searchHandler,
    responsive,
    src = responsive
      ? "/iPayV2/icons/search.svg"
      : "/iPayV2/icons/faq search.svg",
    border = location.pathname.includes("discover")
      ? "1px solid rgba(255, 255, 255, 0.8)"
      : "1px solid rgba(200, 6, 0, 0.8)",
    labelRequired = true,
    expandHandler,
    inputHandler,
    translations,
    arr,
    callbackHandler,
    marginRight = "1rem",
    suggestionsRequired = false,
  } = props;
  const [input, setInput] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [autoCompleteActive, setAutoCompleteActive] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const styles = {
    inputContainer: {
      display: "flex",
      marginRight: marginRight,
      justifyContent: "flex-end",
      position: "relative",
    },
    input: {
      width: "0px",
      height: 40,
    },
    expandedInput: {
      border: border,
      borderRadius: "20px",
      height: 38,
      color: WHITE_PRIMARY_COLOR,
      paddingLeft: 15,
      paddingRight: 40,
      width: "80px",
    },
    autocompletedInput: {
      borderRadius: "15px 15px 0 0",
    },
    search: {
      position: "absolute",
      zIndex: 1,
      right: -17,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };

  useEffect(() => {
    const Search = document.getElementById(id);
    const search = (event) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        logEvent("search", {
          search_term: event.target.value,
        });
        if (suggestionsRequired) {
          // setInput("")
          autocomplete(id, []);
        }
        searchHandler(event.target.value);
      }
    };
    if (expanded) {
      if (Search) {
        Search.addEventListener("keyup", search);
      }
    }
    return () => {
      if (Search) Search.removeEventListener("keyup", search);
    };
  }, [expanded]);

  const expandSearch = (expand) => {
    if (expand) {
      document.getElementById(id).focus();
      setExpanded(expand);
      if (expandHandler) expandHandler(true);
    } else {
      setInput("");
      setIsValid(true);
      setTimeout(() => {
        setExpanded(expand);
        if (expandHandler) expandHandler(false);
      }, [500]);
    }
  };

  const callback = (data) => {
    if (callbackHandler) {
      callbackHandler(data);
    }
  };

  const callback1 = () => {
    setInput("");
    setIsValid(true);
    setAutoCompleteActive(false);
  };

  useEffect(() => {
    if (document.getElementById(id) && suggestionsRequired && expanded)
      autocomplete(id, arr, callback, callback1, () =>
        setAutoCompleteActive(true)
      );
  }, [arr]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        if (expanded) expandSearch(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchRef, expanded]);

  const onChangeInput = (event) => {
    const query = event.target.value;

    const regexp = new RegExp(/^[a-zA-Z0-9 \.?!]{0,}$/gi);
    const isValid = regexp.test(query);
    if (inputHandler && isValid) {
      inputHandler(query);
    }
    setIsValid(isValid);
    setInput(query);
  };

  return (
    <div
      style={{
        ...styles.inputContainer,
        marginLeft: !expanded ? "2rem" : undefined,
      }}
      ref={searchRef}
    >
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: expanded ? "120px" : "0px",
        }}
      >
        <input
          id={id}
          type="text"
          autoComplete="off"
          value={input}
          onChange={onChangeInput}
          style={{
            ...styles.input,
            ...(expanded ? styles.expandedInput : {}),
            backgroundColor: autoCompleteActive
              ? WHITE_PRIMARY_COLOR
              : undefined,
            color: autoCompleteActive ? "black" : undefined,
          }}
        />
      </div>
      <div
        style={{
          ...styles.search,
        }}
        onMouseEnter={() => expandSearch(true)}
        onMouseDown={() => expandSearch(true)}
      >
        {!autoCompleteActive && (
          <>
            <img
              src={src}
              width={40}
              height={39}
              style={{ cursor: "pointer" }}
              onClick={() => searchHandler(input)}
            />
            <div>
              {!isValid && (
                <div
                  style={{
                    color: autoCompleteActive ? "black" : undefined,
                    fontSize: 10,
                    fontWeight: 400,
                    opacity: "0.8",
                  }}
                >
                  {translations["SEARCH_VALIDATION"]}
                </div>
              )}
              {isValid && labelRequired && (
                <div
                  style={{
                    fontWeight: "lighter",
                    fontSize: "10px",
                    opacity: "0.8",
                  }}
                >
                  {translations["search"]}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
