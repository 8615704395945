import { GRAY_FOOTER_COLOR, PEACH_SHODOW_COLOR } from 'global/constants';
import React from 'react';

const InputBox = props => {

    const { height = 45, fontSize = "14px",
        minWidth = 100, backgroundColor = "#EAEAEA",
        labelText = "Your label here",
        placeholder = "Enter your text here",
        labelColor = GRAY_FOOTER_COLOR,
        border,
        boxShadow = `5px 3px ${PEACH_SHODOW_COLOR}`,
        handleFocus,
        borderRadius = "15px",
        type = "text",
        handleBlur,
        value = "",
        translations,
        marginLeft = height / 2,
        name = "input",
        handleChange, color = "#e70707", required = true, ...rest
    } = props;

    const styles = {
        rectangle: {
            minWidth: minWidth,
            height: height,
            display: 'flex',
            marginRight: 10,
            paddingLeft: height / 2,
            borderRadius: borderRadius,
            border: border,
            backgroundColor: backgroundColor,
            // boxShadow: boxShadow
        },
        content: {
            fontSize: fontSize,
            fontWeight: "normal",
            width: "100%",
            display: 'flex',
            alignContent: 'center'
        },
        label: {
            marginLeft: marginLeft,
            fontSize: "16px",
            lineHeight: 2.86,
            color: labelColor,
            textTransform: "capitalize"
        },
        required: {
            color: labelColor,
            marginLeft: 3
        }
    }

    return (
        <>
            <div style={styles.label}>
                {translations[labelText]}{required && <span style={styles.required}>*</span>}
            </div>
            <div style={styles.rectangle}>
                <div style={styles.content}>
                    <input onChange={handleChange} name={name} value={value}
                        style={{ width: '100%' }} onFocus={handleFocus} onBlur={handleBlur}
                        required={required} type={type} placeholder={translations[placeholder]} {...rest} />
                </div>
            </div>
        </>
    )



}

export default InputBox;