import React from 'react';
import { withRouter } from 'react-router-dom';

const MobileTCPrivacy = props => {

    const { createMarkup, leftContainer, rightContainer } = props;

    return (<div className="mobile-container">
        <div className="mobile-title">
            {rightContainer.description.displayTitle}
        </div>
        <div style={{
            minHeight: '100%', fontSize: '11px', marginBottom: "-1rem",
            maxHeight: '100%', overflowY: 'auto'
        }} dangerouslySetInnerHTML={createMarkup()}>
        </div>
    </div >
    )

}

export default withRouter(MobileTCPrivacy)