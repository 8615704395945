import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DiscoverFold1 from "./Fold1";
import DiscoverFold2 from "./Fold2";
import Slider from "react-slick";
import {
  DISCOVER_PAGE_URL,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import { fetchContent } from "global/api";
import Fallback from "components/Fallback";
import "../../devices.min.css";
import "../../index.css";
import MobileDiscover from "./Mobile";
import { logEvent } from "firebase-analytics";

const Discover = (props) => {
  const { translations, responsive } = props;
  const [slideIndex, setSlideIndex] = useState(0);
  const [videosCompleted, setVideosCompleted] = useState(false);
  const [fold1, setFold1] = useState();
  const [fold2, setFold2] = useState();

  const settings = {
    dots: true,
    beforeChange: (current, next) => {
      setSlideIndex(next);
    },
    speed: 2000,
    autoplaySpeed: 10000,
    customPaging: (i) => <div className="dot"></div>,
  };

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    logEvent("discover_content_load_start");
    fetchContent(DISCOVER_PAGE_URL, {}).then((response) => {
      if (response !== {}) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setFold1(data.folds[0]);
            setFold2(data.folds[1]);
          }
          setLoading(false);
          logEvent("discover_content_load_success");
        } else {
          setIsError(true);
          setLoading(false);
          logEvent("discover_content_load_fail");
        }
      } else {
        setIsError(true);
        setLoading(false);
        logEvent("discover_content_load_fail");
      }
    });
  }, []);

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  const handleSlidePlay = () => {
    ref.current.slickPlay();
  };

  const handleSlidePause = () => {
    ref.current.slickPause();
  };

  useEffect(() => {
    if (ref.current) {
      if (slideIndex !== 0) {
        setVideosCompleted(false);
        handleSlidePlay();
      } else {
        if (videosCompleted) {
          handleSlidePlay();
        } else handleSlidePause();
      }
    }
  }, [slideIndex, videosCompleted]);

  const ref = useRef();

  const footerIconHandler = (url) => {
    if (url) window.open(url);
  };

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <div style={{ display: "block" }}>
      <Slider ref={ref} {...settings}>
        <div>
          <DiscoverFold1
            translations={translations}
            data={fold1}
            handleVideosCompleted={(flag) => setVideosCompleted(flag)}
          />
        </div>
        <div>
          <DiscoverFold2
            footerIconHandler={footerIconHandler}
            data={fold2}
            translations={translations}
            handleSlidePause={handleSlidePause}
            handleSlidePlay={handleSlidePlay}
          />
        </div>
      </Slider>
    </div>
  ) : (
    <MobileDiscover
      footerIconHandler={footerIconHandler}
      {...props}
      fold1={fold1}
      fold2={fold2}
    />
  );
};

export default withRouter(Discover);
