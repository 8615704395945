import React, { useEffect, useState } from "react";
import LabelBox from "components/LabelBox";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import SearchBox from "components/SearchBox";
import MobileHeader from "./Mobile/index";
import { fetchContent } from "global/api";
import {
  GLOBAL_SEARCH,
  PAGE_MAPPING,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import { logEvent } from "firebase-analytics";
import { debounce } from "lodash";

const Header = (props) => {
  const location = useLocation();
  const {
    responsive,
    translations,
    changeLocale,
    header,
    locale,
    backgroundColor,
    innerRef,
  } = props;
  const [arr, setArr] = useState([]);

  const styles = {
    headerContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      height: "5em",
      zIndex: 4,
      justifyContent: "center",
      // position: 'fixed',
      // top: 0,
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      width: "65%",
      fontSize: "14px",
      color: WHITE_PRIMARY_COLOR,
      fontWeight: "bold",
      justifyContent: "center",
      alignItems: "center",
      borderBottomLeftRadius: "50px",
      borderBottomRightRadius: "50px",
      // mixBlendMode: 'multiply',
      opacity: location.pathname.includes("discover") ? 0.5 : 1,
      backdropFilter: "blur(0px)",
      backgroundColor: backgroundColor,
      zIndex: 4,
    },
    rightHeader: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      justifyContent: "space-around",
      alignItems: "center",
    },
    tabContainer: {
      display: "flex",
    },
    tab: {
      display: "flex",
      cursor: "pointer",
      position: "relative",
    },
    inputContainer: {
      display: "flex",
      right: "5%",
      top: "23px",
      zIndex: 3,
      alignItems: "center",
      justifyContent: "flex-end",
      position: "absolute",
    },
  };

  const [tabsList, setTabsList] = useState(() => {
    const tabsList = [];
    if (header) {
      header.forEach((item, index) => {
        tabsList.push({
          active: false,
          url: `/${item.title}`,
          label: item.title,
          displayTitle: item.displayTitle,
          mediaUrl: item.mediaUrl,
        });
      });
    }
    return tabsList;
  });

  useEffect(() => {
    const currentUrl = location.pathname;
    const updatedTabsList = [];
    tabsList.forEach((tab) => {
      let modifiedTab = { ...tab };
      if (tab.active && currentUrl.indexOf(tab.url) === -1) {
        modifiedTab.active = false;
      } else if (currentUrl.indexOf(tab.url) > -1) {
        modifiedTab.active = true;
      }
      updatedTabsList.push(modifiedTab);
    });
    setTabsList(updatedTabsList);
  }, [location]);

  const handleClick = (url) => {
    props.history.push(url);
  };

  const searchHandler = (query) => {
    const regexp = new RegExp(/^[a-zA-Z0-9 \.?!]{0,}$/gi);
    const isValid = regexp.test(query);
    if (query && query.length > 0 && isValid) {
      props.history.push("/search", { searchQuery: query });
    }
  };

  const callbackHandler = (data) => {
    const { pageNo = 0 } = data;
    props.history.replace(PAGE_MAPPING[pageNo].path, {
      searchQueryData: data,
      slideIndex: PAGE_MAPPING[pageNo].slideIndex,
    });
  };

  const inputHandler = (query) => {
    if (query && query.trim() && query.length > 2) {
      debouncedSearchContent(query);
    } else {
      setArr([]);
    }
  };

  const debouncedSearchContent = debounce((query) => {
    logEvent("global_search_start");
    fetchContent(GLOBAL_SEARCH, { query: query }).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            if (
              data.rightContainer.results &&
              data.rightContainer.results.length > 4
            ) {
              setArr(data.rightContainer.results.slice(0, 4));
            } else {
              setArr(data.rightContainer.results);
            }
            logEvent("global_search_success");
          } else {
            setArr([]);
          }
        } else if (response.code === 2004) {
          setArr([]);
          logEvent("global_search_no_results");
        }
      } else {
        setArr([]);
        logEvent("global_search_fail");
      }
    });
  }, 2000);

  const goToMerchantHandler = () => {
    window.open(window.MERCHANT_URL, "_blank");
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <>
      {!responsive ? (
        <div ref={innerRef} style={styles.headerContainer}>
          <div
            className="merchant"
            style={{
              width: "120px",
              height: "36px",
              top: "23px",
              borderRadius: 36,
              cursor: "pointer",
              zIndex: 3,
            }}
            onClick={goToMerchantHandler}
          >
            <div style={{ display: "flex" }}>
              <img src="/iPayV2/icons/merchant.svg" width={36} />
            </div>
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                fontSize: "9px",
                alignItems: "center",
                color: "#fff",
              }}
              id="text"
            >
              <div className="go_to_consumer">
                {translations["Go to Merchant"]}
              </div>
            </div>
          </div>
          <div style={styles.rightContainer} className="header_right_container">
            <div style={styles.rightHeader}>
              {tabsList.slice(0, 2).map((tab) => {
                return (
                  <>
                    <div
                      key={tab.label}
                      style={styles.tab}
                      className={tab.label}
                      {...(tab.label === "ourProducts" && {
                        onMouseEnter: () => setShow(true),
                        onMouseLeave: () => setShow(false),
                      })}
                    >
                      <LabelBox
                        handleClick={() => handleClick(tab.url)}
                        id="link"
                        labelText={tab.displayTitle}
                        color={WHITE_PRIMARY_COLOR}
                        backgroundColor={""}
                        active={tab.active}
                        textCase="capitalize"
                      />
                      {show && tab.label === "ourProducts" && <DropdownList translations={translations}/>}
                    </div>
                  </>
                );
              })}
              {tabsList.slice(2, 3).map((tab) => {
                return (
                  <div style={styles.tab}>
                    <img src={tab.mediaUrl} onClick={() => handleClick("/")} />
                  </div>
                );
              })}
              {tabsList.slice(3).map((tab) => {
                return (
                  <div key={tab.label} style={styles.tab}>
                    <LabelBox
                      handleClick={() => handleClick(tab.url)}
                      id="link"
                      labelText={tab.displayTitle}
                      color={WHITE_PRIMARY_COLOR}
                      backgroundColor={""}
                      active={tab.active}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={styles.inputContainer} id="searchContainer">
            {!isExpanded && (
              <div style={{ marginRight: "1rem" }}>
                <span
                  onClick={() => changeLocale(locale === "en" ? "ar" : "en")}
                  style={{
                    cursor: "pointer",
                    color: location.pathname.includes("discover")
                      ? "rgba(255, 255, 255, 0.8)"
                      : "rgba(200, 6, 0, 0.8)",
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {locale === "en" ? "عربي" : "English"}
                </span>
              </div>
            )}
            <SearchBox
              callbackHandler={callbackHandler}
              labelColor={
                location.pathname.includes("discover")
                  ? "rgba(255, 255, 255, 0.8)"
                  : "rgba(200, 6, 0, 0.8)"
              }
              src={
                location.pathname.includes("discover")
                  ? "/iPayV2/icons/search.svg"
                  : undefined
              }
              inputHandler={inputHandler}
              suggestionsRequired={true}
              arr={arr}
              searchHandler={searchHandler}
              translations={translations}
              expandHandler={(expand) => setIsExpanded(expand)}
            />
          </div>
        </div>
      ) : (
        <MobileHeader
          goToMerchantHandler={goToMerchantHandler}
          {...props}
          callbackHandler={callbackHandler}
          inputHandler={inputHandler}
          suggestionsRequired={true}
          arr={arr}
          searchHandler={searchHandler}
          handleClick={handleClick}
        />
      )}
    </>
  );
};

const DropdownList = ({translations,
  list = [
    { label: "Wallet Transfer", key: "walletTransfer" },
    { label: "Merchant Payments", key: "merchantPayments" },
    { label: "International Transfer", key: "internationalTransfer" },
    { label: "Bill Payments", key: "billPayments" },
    { label: "iPay Cards", key: "iPayCards" },
    { label: "Offers", key: "offers" },
  ],
}) => {
  const history = useHistory();
  const location = useLocation();

  const redirectHandler = (tab) => {
    console.log("window.location.hash", window.location.hash);
    if (window.location.hash.replace("#", "") === tab.key) {
      document.getElementById(tab.key)?.scrollIntoView({ behavior: "smooth" });
    } else {
      history.push(`/ourProducts#${tab.key}`);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 10,
        padding: "16px 24px 28px 24px",
        boxShadow: "0px 3px 13px 0px rgba(0, 0, 0, 0.13)",
        position: "absolute",
        top: "44px",
        left: "-37px",
      }}
    >
      {list.map((l, index) => {
        return (
          <div
            onClick={() => redirectHandler(l)}
            style={{
              color: "#807F7F",
              whiteSpace: "nowrap",
              paddingTop: 12,
              paddingBottom: 15,
              borderBottom: "1px solid #B5B5B580",
              minWidth: 158,
            }}
            key={index}
          >
            {translations[l.label] || l.label}
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(Header);
