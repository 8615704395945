import { RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from "global/constants";
import React, { useEffect, useRef, useState } from "react";
import classes from "./tabs.module.css";
import { FETCH_ALL_CATEGORIES } from "global/constants";
import { fetchContent } from "global/api";

const Tabs = (props) => {
  const locale = localStorage.getItem("locale");

  const { setactiveTabParent, responsive, translations } = props;

  const [activeTab, setActiveTab] = useState();
  const [canScroll, setCanScroll] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const [canScrollRight, setCanScrollRight] = useState(true);

  const [categories, setCategories] = useState([
    { displayTitleEn: "All Offers", displayTitleAr: "جميع العروض", id: null },
  ]);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const tabsRef = useRef(null);

  useEffect(() => {
    setIsError(false);
    fetchContent(FETCH_ALL_CATEGORIES, {}).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setCategories(
              [
                {
                  displayTitleEn: "All Offers",
                  displayTitleAr: "جميع العروض",
                  id: "FETCH_ALL_OFFERS",
                },
              ].concat(data)
            );
          }
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setActiveTab(categories?.[0]?.id);
    setCanScroll(
      !(
        document.getElementById("tabsInnerContainer").scrollWidth ==
        document.getElementById("tabsInnerContainer").clientWidth
      )
    );
  }, [categories]);

  useEffect(() => {
    setactiveTabParent(activeTab);
  }, [activeTab]);

  /**************************************************************************************** */

  const handleTabClick = (index) => {
    setActiveTab(index);
    // setactiveTabParent(index);
    try {
      document.getElementById("tabsInnerContainer").scrollLeft =
        document.getElementById("button_" + index)?.offsetLeft;
    } catch (error) {}
  };

  const prevHandler = () => {
    const tabsInnerContainer = document.getElementById("tabsInnerContainer");
    const tabsOutterContainer = document.getElementById("tabsOutterContainer");

    tabsInnerContainer.scrollBy({
      left: -200,
      behavior: "smooth",
    });

    setTimeout(() => {
      setCanScrollLeft(+tabsInnerContainer.scrollLeft > 0);
    }, 300);

    setTimeout(() => {
      tabsInnerContainer.scrollWidth > tabsOutterContainer.clientWidth &&
      tabsInnerContainer.scrollLeft <
        tabsInnerContainer.scrollWidth - tabsOutterContainer.clientWidth
        ? setCanScrollRight(true)
        : setCanScrollRight(false);
    }, 500);
  };

  const nextHandler = () => {
    const tabsInnerContainer = document.getElementById("tabsInnerContainer");
    const tabsOutterContainer = document.getElementById("tabsOutterContainer");

    tabsInnerContainer.scrollBy({
      left: 200,
      behavior: "smooth",
    });

    setTimeout(() => {
      setCanScrollLeft(+tabsInnerContainer.scrollLeft > 0);
    }, 300);

    setTimeout(() => {
      tabsInnerContainer.scrollWidth > tabsOutterContainer.clientWidth &&
      tabsInnerContainer.scrollLeft <
        tabsInnerContainer.scrollWidth - tabsOutterContainer.clientWidth
        ? setCanScrollRight(true)
        : setCanScrollRight(false);
    }, 500);
  };

  /**************************************************************************************** */

  return (
    <div className={classes.container} id="container">
      {!responsive && (
        <div style={{ width: "20px" }}>
          {canScroll && canScrollLeft && (
            <button
              onClick={prevHandler}
              className={classes.scrollButtonLeft}
              id="prevButton"
            >
              &lt;
            </button>
          )}
        </div>
      )}

      <div className={classes.tabsOutterContainer} id="tabsOutterContainer">
        <div
          className={classes.tabsInnerContainer}
          id="tabsInnerContainer"
          style={{
            ...(!canScroll && { justifyContent: "center" }),
            position: "relative",
          }}
          ref={tabsRef}
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          {categories?.map((tab, index) => (
            <button
              id={"button_" + tab.id}
              className={classes.tab + " tabButton"}
              style={{
                ...(tab.id === activeTab && {
                  borderBottom: "1px solid " + RED_PRIMARY_COLOR,
                  fontWeight: "800",
                }),
              }}
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {locale === "ar" ? tab.displayTitleAr : tab.displayTitleEn}
            </button>
          ))}
        </div>
      </div>
      {!responsive && (
        <div style={{ width: "20px" }}>
          {canScroll && canScrollRight && (
            <button
              onClick={nextHandler}
              className={classes.scrollButtonRight}
              id="nextButton"
            >
              &gt;
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Tabs;
