import React from "react";
import ReactDOM from "react-dom";
import "./slick.min.css";
import "./slick-theme.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "devices.min.css";
import { registerServiceWorker } from "./serviceWorker";
import ScrollToTop from "components/ScrollToTop";

// firebase.initializeApp({
//   apiKey: "AIzaSyATs_det0K-q6S750lBE3d2IkxmLpG1rwg",
//   authDomain: "ipaystaging.firebaseapp.com",
//   databaseURL: "https://ipaystaging.firebaseio.com",
//   projectId: "ipaystaging",
//   storageBucket: "ipaystaging.appspot.com",
//   messagingSenderId: "369860431091",
//   appId: "1:369860431091:web:613687251fcde65ff3fb86",
//   measurementId: "G-0B1YVCHK6T"
// })

// ReactGA.initialize(TRACKING_ID);
ReactDOM.render(
  <React.StrictMode>
    <Router basename={"/ipayonlinev2"}>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();
