import Fallback from "components/Fallback";
import Wrapper from "components/Wrapper";
import { fetchContent } from "global/api";
import {
  ASSISTANCE_PAGE_URL,
  GRAY_COLOR,
  GRAY_FOOTER_COLOR,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useState } from "react";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import MobileAssistance from "./Mobile";
import { logEvent } from "firebase-analytics";

const Assistance = (props) => {
  const { translations, responsive } = props;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "3rem",
      height: "calc(100%)",
    },
    leftContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "4em",
      flexDirection: "column",
      flex: "20%",
    },
    description: {
      fontSize: "32px",
      color: GRAY_FOOTER_COLOR,
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "80%",
      height: "100%",
      paddingRight: "1em",
    },
  };
  const [leftContainer, setLeftContainer] = useState();
  const [rightContainer, setRightContainer] = useState();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    logEvent("assistance_content_load_start");
    fetchContent(ASSISTANCE_PAGE_URL, {}).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setLeftContainer(data.leftContainer);
            setRightContainer(data.rightContainer);
            logEvent("assistance_content_load_success");
          }
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
          logEvent("assistance_content_load_fail");
        }
      } else {
        setIsError(true);
        setLoading(false);
        logEvent("assistance_content_load_fail");
      }
    });
  }, []);

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : !responsive ? (
    <div id="assistance" style={styles.container}>
      <div style={styles.leftContainer} className="left-container">
        <div className="title">{leftContainer.title}</div>
        <div className="Line-2"></div>
        <div style={styles.description}>{leftContainer.description}</div>
      </div>
      <div style={styles.rightContainer}>
        <div
          id="assistanceGridConainer"
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            gap: 20,
            height: "100%",
          }}
        >
          {rightContainer.assets &&
            rightContainer.assets.map((asset) => {
              return (
                <EnhancedWrapper
                  key={asset}
                  description={asset.description}
                  title={asset.title}
                  displayTitle={asset.displayTitle}
                  src={asset.mediaUrl}
                />
              );
            })}
        </div>
      </div>
    </div>
  ) : (
    <MobileAssistance
      {...props}
      leftContainer={leftContainer}
      rightContainer={rightContainer}
    />
  );
};

const EnhancedWrapper = (props) => {
  const {
    title = "Title",
    displayTitle = "Title",
    src,
    description = "Description Text",
  } = props;
  const { url } = useRouteMatch();
  return (
    <div style={{ height: "100%" }}>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to={`${url}/${title}`} style={{ textDecoration: "none" }}>
          <img
            src={src}
            alt={title}
            style={{
              maxHeight: "110px",
              maxWidth: "110px",
              height: "auto",
              width: "auto",
            }}
          />
        </Link>
      </div>{" "}
      <Link to={`${url}/${title}`} style={{ textDecoration: "none" }}>
        <div
          style={{
            height: "100%",
            backgroundColor: GRAY_COLOR,
            borderRadius: "30px 30px 0px 0px",
          }}
        >
          <div
            style={{ width: 230, textAlign: "center", paddingTop: 60 }}
            id="assistancewrapper"
          >
            <div
              style={{
                fontSize: "20px",
                color: RED_PRIMARY_COLOR,
                fontWeight: "bold",
              }}
            >
              {displayTitle}
            </div>
            <div
              style={{
                fontSize: "14px",
                color: GRAY_FOOTER_COLOR,
                width: "60%",
                margin: "35px auto",
                lineHeight: 1.36,
              }}
            >
              {description}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default withRouter(Assistance);
