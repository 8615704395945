import LabelBox from 'components/LabelBox';
import { GRAY_FOOTER_COLOR, RED_PRIMARY_COLOR } from 'global/constants';
import React from 'react';

const SideMenu = props => {

    const { menu, selectMenuItemHandler, activeId, styles = {
        marginBottom: 10,
        labelWidth: 110
    } } = props;

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        {menu.map(item => (
            <div key={item.id + ""} style={{ marginBottom: styles.marginBottom }}>
                <LabelBox labelText={item.displayTitle} padding="0px"
                    border={`0.75px solid ${RED_PRIMARY_COLOR}`}
                    inactiveColor={GRAY_FOOTER_COLOR}
                    className="sidemenulabel"
                    embededActive={activeId == item.sequenceNo}
                    iconname={item.mediaUrl} active={activeId == item.sequenceNo}
                    handleClick={() => selectMenuItemHandler(item.sequenceNo)} iconWidth={33}
                    icon={true} labelTextStyle={{ width: styles.labelWidth }} />
            </div>
        ))}
    </div>

}

export default SideMenu
