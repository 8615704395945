import Fallback from "components/Fallback";
import {
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  GENERIC_PAGE_URL,
  GRAY_FOOTER_COLOR,
  FETCH_OFFERS_BY_CATEGORY_ID,
  FETCH_OFFERS_BY_CONTEXT,
  BASE_REDIRECT_URL,
} from "global/constants";
import { fetchContent } from "global/api";
import React, { useEffect, useState } from "react";
import classes from "./generic-pages.module.css";
import { useParams } from "react-router-dom";
import { logEvent } from "firebase-analytics";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { getMobileIconURL } from "global/utils";
import OffersSection from "container/OffersSection";
import SliderOffersSection from "container/SliderOffersSection";
import MobileSliderOffersSection from "container/SliderOffersSection/Mobile";
import Card from "components/Card";
import MobileCard from "components/Card/Mobile";

const Genericpage = (props) => {
  const { responsive, translations } = props;
  const [rightContainerAssets, setRightContainerAssets] = useState();

  const [html, setHtml] = useState();
  const [pageData, setPageData] = useState();
  const [offers, setOffers] = useState();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { pagecontext } = useParams();

  const locale = localStorage.getItem("locale");
  const [image, setImage] = useState();

  const loadHtml = (htmlUrl) => {
    if (htmlUrl) {
      axios
        .get(htmlUrl)
        .then((data) => {
          setHtml(
            `<div style="margin-top:1rem;direction:${
              locale === "en" ? "ltr" : "rtl"
            };  color: ${GRAY_FOOTER_COLOR};">
               ${data.data}
             </div>`
          );
          setLoading(false);
        })
        .catch((data) => {
          setLoading(false);
          setHtml(
            `<div style="text-align: ${
              responsive ? (locale === "en" ? "left" : "right") : "center"
            }; margin-top:1rem;color: ${GRAY_FOOTER_COLOR};">
              ${translations["Unable to fetch data at this moment."]}
             </div>`
          );
        });
    }
  };

  const fetchOfferDetails = () => {
    setIsError(false);
    setLoading(true);
    fetchContent(FETCH_OFFERS_BY_CONTEXT, {
      contextPath: "/" + pagecontext,
    }).then((response) => {
      if (response) {
        if (response.code === 2000) {
          const data = response.data;
          if (data) {
            setPageData(data);
            let htmlUrl = data?.html;
            if (htmlUrl && htmlUrl.includes(BASE_REDIRECT_URL)) {
              const origin = window.origin;
              htmlUrl = htmlUrl.replace(BASE_REDIRECT_URL, origin);
            }
            loadHtml(htmlUrl);
            // if (data?.html) {
            //   setHtml(
            //     `<div style="margin-top:1rem;direction:${
            //       locale === "en" ? "ltr" : "rtl"
            //     };  color: ${GRAY_FOOTER_COLOR};">
            //     ${decodeURI(data.html)}
            //  </div>`
            //   );
            // } else {
            //   setHtml(
            //     `<div style="text-align: ${
            //       responsive ? (locale === "en" ? "left" : "right") : "center"
            //     }; margin-top:1rem;color: ${GRAY_FOOTER_COLOR};">
            //   ${translations["Unable to fetch data at this moment."]}
            //  </div>`
            //   );
            // }
            fetchOffersByCategoryId(data?.category?.id);
          }
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setLoading(false);
      }
    });
  };

  const fetchOffersByCategoryId = (id) => {
    setIsError(false);
    setLoading(true);
    fetchContent(FETCH_OFFERS_BY_CATEGORY_ID, {
      catId: id,
    }).then((response) => {
      if (response && response.code === 2000) {
        const data = response.data;
        if (data && data.length > 0) {
          setOffers(
            data?.filter((item) => item?.offerContext != "/" + pagecontext)
          );
        }
        setLoading(false);
      } else {
        setIsError(true);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchOfferDetails();
  }, [pagecontext]);

  useEffect(() => {
    if (isError) props.history.replace("errorPage");
  }, [isError]);

  useEffect(() => {
    console.log("offers", offers);
  }, [offers]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    const resizeWindow = () => {
      if (window.innerWidth <= 600) {
        setImage(pageData?.mobileImage || pageData?.image);
      } else {
        setImage(pageData?.image || pageData?.mobileImage);
      }
    };
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [pageData]);

  function createMarkup() {
    return { __html: html };
  }

  return loading ? (
    <Fallback
      loading={loading}
      color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR}
    />
  ) : (
    <div className={classes.container}>
      <div className={classes.title} dir={locale === "ar" ? "rtl" : "ltr"}>
        {pageData?.heading}
      </div>
      <div className={classes.banner_image_div}>
        <img
          src={image}
          className={classes.banner_image}
          alt={pageData?.description}
          title={pageData?.description}
        />
      </div>
      <div className={classes.subtitle} dir={locale === "ar" ? "rtl" : "ltr"}>
        {pageData?.description}
      </div>
      <div className={classes.html} dangerouslySetInnerHTML={createMarkup()} />

      {offers?.length > 0 && <div className={classes.divider} />}

      {offers?.length > 0 && (
        <div
          className={classes.categoryTitle}
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          {locale === "ar"
            ? `آخر ${pageData?.category?.displayTitleAr} عروض`
            : `Other ${pageData?.category?.displayTitleEn} Offers`}
        </div>
      )}

      {!responsive && offers?.length > 0 && (
        <div className={classes.cardContainer} id="OffersSection">
          {offers?.length > 1 && (
            <SliderOffersSection
              translations={translations}
              items={offers}
              fromGeneric={true}
            />
          )}

          {offers?.length === 1 && (
            <div style={{ display: "flex" }}>
              <Card
                translations={translations}
                image={offers[0]?.thumbnail}
                redirectUrl={offers[0]?.offerContext}
                heading={offers[0]?.heading}
                subHeading={offers[0]?.overlayText}
              />
            </div>
          )}
        </div>
      )}

      {responsive && offers?.length > 0 && (
        <div className={classes.cardContainer} id="MobileOfferSection">
          <MobileSliderOffersSection
            translations={translations}
            items={offers.slice(0)}
            fromGeneric={true}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(Genericpage);
