import Fallback from "components/Fallback";
import LabelBox from "components/LabelBox";
import OffersSection from "container/OffersSection";
import { fetchContent } from "global/api";
import {
  GRAY_FOOTER_COLOR,
  WHITE_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  QA_TYPE_IMAGE,
  QA_TYPE_VIDEO,
} from "global/constants";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";

const MobileHome = (props) => {
  const {
    locale,
    translations,
    leftContainer,
    responsive,
    rightContainer,
    goToLearnMore,
    middleContainer,
    settings,
    redirectHandler,
    setVideoPausedByUser,
    showOfferSection,
  } = props;

  const styles = {
    middleContainer: {
      display: "flex",
      justifyContent: "center",
      // position: "relative",
      margin: "10px 0px",
      width: "100%",
    },
  };

  const slideRef = useRef();

  const nextHandler = () => {
    if (slideRef && slideRef.current) {
      setAutoplay(true);
      slideRef.current.slickNext();
    }
  };

  const prevHandler = () => {
    if (slideRef && slideRef.current) {
      setAutoplay(true);
      slideRef.current.slickPrev();
    }
  };

  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    autoplay ? slideRef?.current?.slickPlay() : slideRef?.current?.slickPause();
  }, [autoplay, slideRef]);

  return (
    <div>
      <div className="home-mobile-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <div className="mobile-title">{leftContainer.title}</div>
        </div>
        <div style={styles.middleContainer} className="middle-container">
          <div
            id="home-mobile-slider"
            style={{
              width: "100%",
              // height: "244px",
              height: "126px",
              WebkitBackgroundClip: "padding-box",
            }}
          >
            <Slider
              {...settings}
              swipe={true}
              id="mySlides"
              ref={slideRef}
              swipeToSlide={true}
            >
              {middleContainer &&
              middleContainer.assetsMiddle &&
              middleContainer.assetsMiddle.length > 0 ? (
                middleContainer.assetsMiddle.map((asset, index) => {
                  let content;
                  if (asset.assestType === QA_TYPE_IMAGE) {
                    content = (
                      <div
                        style={{
                          // width: "100px",
                          // height: "296px",
                          height: "126px",
                        }}
                        key={index + ""}
                        onClick={() => {
                          if (asset.isRedirected)
                            return redirectHandler(asset.redirectUrl);
                        }}
                      >
                        <img
                          src={
                            asset?.mobileImage || asset?.image || asset.mediaUrl
                          }
                          // src="https://digital.mv1.in/cdn/iPayV2/icons/KarwaBannerEN.jpg"
                          style={{
                            // height: "244px",
                            height: "126px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                            ...(asset.isRedirected && { cursor: "pointer" }),
                            ...(!asset.isRedirected && { cursor: "default" }),
                            border: "1px solid transparent",
                          }}
                          alt={asset.displayTitle}
                        />
                      </div>
                    );
                  } else if (asset.assestType === QA_TYPE_VIDEO) {
                    content = (
                      <div
                        style={{
                          backgroundColor: RED_PRIMARY_COLOR,
                        }}
                        key={index + ""}
                      >
                        {asset.mediaUrl && (
                          <video
                            style={{
                              // height: "244px",
                              height: "126px",
                              display: "block",
                              margin: "auto",
                              width: "100%",
                              backgroundColor: RED_PRIMARY_COLOR,
                            }}
                            controls
                            src={asset.mediaUrl}
                            id={"video_" + index}
                            onPause={() => {
                              setVideoPausedByUser(true);
                              setAutoplay(true);
                            }}
                            onEnded={() => {
                              setVideoPausedByUser(false);
                              nextHandler();
                            }}
                            alt={asset.displayTitle}
                            onPlay={() => setAutoplay(false)}
                          />
                        )}
                      </div>
                    );
                  } else {
                    content = <div key={index + ""}>{asset.description}</div>;
                  }
                  return content;
                })
              ) : (
                <div className="hand-image">
                  <img src={"/iPayV2/icons/hand-image.png"} />
                </div>
              )}
            </Slider>
          </div>
          {!responsive && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "10%",
                cursor: "pointer",
              }}
              onClick={prevHandler}
            >
              <div
                style={{
                  display: "flex",
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  textAlign: "center",
                  color: WHITE_PRIMARY_COLOR,
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {" "}
                  <img src="/iPayV2/icons/previcon.svg" />
                </div>
              </div>
            </div>
          )}
          {!responsive && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "10%",
                cursor: "pointer",
              }}
              onClick={nextHandler}
            >
              <div
                style={{
                  display: "flex",
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  textAlign: "center",
                  color: WHITE_PRIMARY_COLOR,
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {" "}
                  <img src="/iPayV2/icons/nexticon.svg" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "2rem",
            padding: "1rem",
          }}
        >
          {rightContainer.assets.map((asset, index) => {
            return (
              <Grid
                title={asset.displayTitle}
                key={asset.id}
                description={asset.description}
                icon={asset.mediaUrl}
              />
            );
          })}
        </div>
      </div>
      {showOfferSection && (
        <OffersSection
          translations={translations}
          responsive={responsive}
          fromGeneric={false}
        />
      )}
    </div>
  );
};

const Grid = (props) => {
  const { icon, title, description } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal",
        color: GRAY_FOOTER_COLOR,
      }}
    >
      <div style={{ display: "flex" }}>
        <img
          src={icon}
          alt={title}
          style={{
            maxHeight: "100px",
            maxWidth: "100px",
            height: "auto",
            width: "auto",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          margin: "10px 0",
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {title}
      </div>
      <div style={{ display: "flex", fontSize: "14px" }}>{description}</div>
    </div>
  );
};

export default withRouter(MobileHome);
