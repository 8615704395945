import Fallback from 'components/Fallback';
import Wrapper from 'components/Wrapper';
import { GRAY_FOOTER_COLOR, OPAQUE_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, withRouter, } from 'react-router-dom';


const MobileAssistance = props => {

    const { translations, responsive, leftContainer, rightContainer } = props;

    const styles = {
        description: {
            fontSize: '14px', color: GRAY_FOOTER_COLOR, fontWeight: 600,
            marginBottom: '5px'
        },
    }

    return (<div className="mobile-container">
        <div style={styles.description}>
            {leftContainer.description}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "auto", gap: 10 }}>
            {rightContainer.assets && rightContainer.assets.map(asset => {
                return <EnhancedWrapper description={asset.description} title={asset.title} displayTitle={asset.displayTitle} src={asset.mediaUrl} />
            })}
        </div>
    </div>)
}

const EnhancedWrapper = (props) => {

    const { title = "Title", displayTitle = "Title", src, description = "Description Text" } = props;
    const { url } = useRouteMatch();
    return (
        <Wrapper backgroundColor={OPAQUE_COLOR} width={"100%"} borderRadius="12px">
            <Link to={`${url}/${title}`} style={{ textDecoration: "none" }}>
                <div style={{
                    width: '100%', height: 70, gridTemplateAreas: "'menu main'",
                    display: 'grid', gridTemplateColumns: '20% 75%'
                }}>
                    <div style={{ gridArea: 'menu', margin: 'auto', }}>
                        <img src={src} alt={title} style={{
                            maxHeight: '40px', maxWidth: '40px', height: 'auto', width: 'auto'
                        }} />
                    </div>
                    <div style={{
                        fontSize: "14px", color: GRAY_FOOTER_COLOR, fontWeight: 'normal',
                        margin: "auto 0", lineHeight: 1.36, gridArea: 'main',
                    }}>
                        <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{displayTitle}</span><br />
                        {description}
                    </div>
                </div>
            </Link>
        </Wrapper>
    )
}


export default withRouter(MobileAssistance);