import {
  GRAY_FOOTER_COLOR,
  QA_TYPE_IMAGE,
  QA_TYPE_TEXT,
  QA_TYPE_VIDEO,
  RED_PRIMARY_COLOR,
} from "global/constants";
import React from "react";

const Accordion = (props) => {
  const {
    id,
    tabLabel,
    checkedId,
    setCheckedId,
    text,
    type,
    assets = [],
    handlePreview,
    translations,
    responsive,
  } = props;

  const styles = {
    answerLine: {
      height: "2px",
      backgroundColor: "#ccc",
      width: "100%",
      margin: "auto",
      lineHeight: "1.67",
    },
  };
  return (
    <div className="tab">
      <input
        type="radio"
        id={`rd${id}`}
        name="rd"
        value={id}
        checked={checkedId === id}
        onChange={setCheckedId}
      />
      <label className="tab-label" htmlFor={`rd${id}`}>
        <span>
          {id + 1}. <span dir="auto"> {tabLabel} </span>
        </span>
      </label>
      <div className="tab-content">
        <div style={styles.answerLine}></div>
        <div
          style={{
            color: RED_PRIMARY_COLOR,
            fontSize: "18px",
            lineHeight: "1.67",
          }}
        >
          Answer :
        </div>
        {assets.length !== 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: 10,
            }}
          >
            {assets.map((asset) => {
              if (asset.assestType === QA_TYPE_VIDEO) {
                return (
                  <div
                    style={{ position: "relative", cursor: "pointer" }}
                    onClick={() => handlePreview("video", asset)}
                  >
                    {/* <ReactPlayer prel ref={playerRef} controls url={asset.mediaUrl} /> */}
                    <video
                      preload="metadata"
                      style={{
                        width: "100%",
                        maxHeight: "20vh",
                        height: "auto",
                        boxShadow: `0px 0px 0px 1px #e0e0e0`,
                      }}
                    >
                      <source
                        src={
                          asset.mediaUrl === null
                            ? asset.redirectUrl
                            : asset.mediaUrl
                        }
                        type="video/mp4"
                      />
                      {
                        translations[
                          "Your browser does not support the video tag."
                        ]
                      }
                    </video>
                    <div
                      className="tooltip"
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        top: "25%",
                        left: "25%",
                        right: "25%",
                      }}
                    >
                      <img
                        alt="play_button"
                        src="/iPayV2/icons/Video.svg"
                        style={
                          responsive ? { width: 40, height: 40 } : undefined
                        }
                      />
                    </div>
                    <span
                      className="tooltiptext"
                      style={{
                        top: "12%",
                        right: "10%",
                      }}
                    >
                      {translations["Play on Enlarged View"]}
                    </span>
                  </div>
                );
              } else if (asset.assestType === QA_TYPE_IMAGE) {
                return (
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      width: "fit-content",
                    }}
                    onClick={() => handlePreview("image", asset)}
                  >
                    <img
                      src={
                        asset.mediaUrl === null
                          ? asset.redirectUrl
                          : asset.mediaUrl
                      }
                      style={{
                        width: "fit-content",
                        boxShadow: `0px 0px 0px 1px #e0e0e0`,
                        maxHeight: "20vh",
                        height: "auto",
                      }}
                    />
                    <div
                      className="tooltip"
                      style={{
                        position: "absolute",
                        textAlign: "right",
                        top: "0%",
                        right: "0%",
                        padding: 5,
                      }}
                    >
                      <img
                        alt="play_button"
                        src="/iPayV2/icons/maximize.png"
                        style={{ width: "33%" }}
                      />
                    </div>
                    <span
                      className="tooltiptext"
                      style={{
                        top: "5px",
                        right: "10%",
                      }}
                    >
                      {translations["Maximize the image"]}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}
        <div
          style={{ whiteSpace: "pre-line" }}
          dir="auto"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        {/* <div style={{ padding: "1em 0" }}>
                <div style={{ ...styles.answerLine, height: 2 }}></div>
            </div> */}
      </div>
    </div>
  );
};

export default Accordion;
