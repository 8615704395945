import React, { useEffect, useRef, useState } from 'react';
import ContactUs from './ContactUs';
import VisitUs from './VisitUs';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';

const MobileContact = (props) => {

    const { translations, fold1, fold2, initialSlide, error, setLocation,
        responsive, currentLocation, category, initialCase } = props;
    const slideShowRef = useRef(null);

    const settings = {
        dots: true,
        autoplay: false,
        swipe: true,
        swipeToSlide: true,
        initialSlide: initialSlide,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        customPaging: i => (
            <div
                className="dot-mobile">
            </div>
        ),
        dotsClass: "slick-dots-mobile",
        beforeChange: (current, next) => props.history.push('/contact', { slideIndex: next })
    };
    const handleFocus = () => {
        // if (slideShowRef.current && slideShowRef.current.slickPause)
        //     slideShowRef.current.slickPause()
    }
    const handleBlur = () => {
        // if (slideShowRef.current && slideShowRef.current.slickPlay)
        //     slideShowRef.current.slickPlay()
    }

    return (<div className="mobile-container">
        <Slider ref={slideShowRef} {...settings}>
            <ContactUs handleBlur={handleBlur} handleFocus={handleFocus}
                data={fold1} translations={translations} />
            <VisitUs {...props} data={fold2} handleBlur={handleBlur} responsive={responsive} initialCase={initialCase}
                handleFocus={handleFocus} error={error} currentLocation={currentLocation} setLocation={setLocation}
                translations={translations} category={category} />
        </Slider>
    </div>
    )
}

export default withRouter(MobileContact);
