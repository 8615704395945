import Button from "components/Button";
import InputBox from "components/InputBox";
import { logEvent } from "firebase-analytics";
import { fetchContent } from "global/api";
import {
  GRAY_FOOTER_COLOR,
  MOBILE_RED_PRIMARY_COLOR,
  RED_PRIMARY_COLOR,
  SAVE_CUSTOMER_DETAILS,
  WHITE_PRIMARY_COLOR,
} from "global/constants";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const ContactUs = (props) => {
  const { handleBlur, handleFocus, data, translations } = props;
  const { leftContainer, rightContainer } = data;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseCompleted, setResponseCompleted] = useState(false);
  //   const initialData = {
  //     email: "",
  //     feedbackAndComment: "",
  //     id: 0,
  //     msisdn: "",
  //     name: "",
  //   };

  const initialData = {
    // email: "",
    feedbackAndComment: {
      value: "",
      isValid: true,
      pattern: /^[a-zA-Z0-9,()!. -]{5,}$/gi,
      errorMsg: translations["FEEDBACK_VALIDATION"],
    },
    msisdn: {
      value: "",
      isValid: true,
      pattern: /^[0-9]{5,15}$/gi,
      errorMsg: translations["MSISDN_VALIDATION"],
    },
    name: {
      value: "",
      isValid: true,
      pattern: /^[a-zA-Z ]{3,30}$/gi,
      errorMsg: translations["NAME_VALIDATION"],
    },
    email: {
      value: "",
      isValid: true,
      // pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
      pattern:
        /^[a-zA-Z0-9][\w\-]*[\.]{0,1}[_\w\-]+@[A-Za-z0-9\-A-Za-z0-9]+\.[a-zA-Z]{2,}$/gi,
      errorMsg: translations["EMAIL_VALIDATION"],
    },
  };

  const [formData, setFormData] = useState(initialData);

  //   const onChangeHandler = (event) => {
  //     const name = event.target.name;
  //     const value = event.target.value;
  //     setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   };

  const onChangeHandler = (event) => {
    setError(false);
    const name = event.target.name;
    const value = event.target.value;
    const regexp = new RegExp(initialData[name].pattern);
    const isvalid = regexp.test(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        isValid: isvalid,
        value: value,
      },
    }));
  };

  const styles = {
    description: {
      fontSize: "18px",
      color: GRAY_FOOTER_COLOR,
      lineHeight: "1.2",
    },
    inputWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginTop: "1em",
    },
    inputWrapper1: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      animation: "up 0.5s",
      animationTimingFunction: "ease-in-out",
    },
    input: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  };

  useEffect(() => {
    var responseTimeout;
    if (responseCompleted) {
      responseTimeout = setTimeout(() => {
        setResponseCompleted(false);
      }, 10000);
    } else {
      clearTimeout(responseTimeout);
    }

    return () => clearTimeout(responseTimeout);
  }, [responseCompleted]);

  //   const handleFeedback = (event) => {
  //     event.preventDefault();
  //     setLoading(true);
  //     setError(false);
  //     let isValid = true;
  //     if (Object.entries(formData).length > 0) {
  //       for (const key in formData) {
  //         if (formData[key] === "") {
  //           isValid = false;
  //           break;
  //         }
  //       }
  //     } else {
  //       isValid = false;
  //       setError(true);
  //     }
  //     if (isValid) {
  //       fetchContent(SAVE_CUSTOMER_DETAILS, {}, formData).then((response) => {
  //         if (response) {
  //           if (response.code === 2000) {
  //             setResponseCompleted(true);
  //             setLoading(false);
  //             setFormData(initialData);
  //           }
  //         } else {
  //           setLoading(false);
  //         }
  //       });
  //     } else {
  //       setLoading(false);
  //     }
  //   };

  const handleFeedback = (event) => {
    event.preventDefault();
    setLoading(true);
    setError();
    let isValid = true;
    const dataTobeSend = {
      id: "0",
    };
    logEvent("submit_feedback_start");
    if (Object.entries(formData).length > 0) {
      for (const key in formData) {
        if (formData[key].value === "" || !formData[key].isValid) {
          isValid = false;
          setError(formData[key].errorMsg);
          break;
        } else {
          dataTobeSend[key] = formData[key].value;
        }
      }
    } else {
      isValid = false;
      setError(translations["Something went wrong"]);
    }
    if (isValid) {
      fetchContent(SAVE_CUSTOMER_DETAILS, {}, dataTobeSend).then((response) => {
        if (response) {
          if (response.code === 2000) {
            setResponseCompleted(true);
            setLoading(false);
            setError();
            setFormData(initialData);
            logEvent("submit_feedback_success");
          }
        } else {
          setError(translations["Something went wrong"]);
          logEvent("submit_feedback_fail");
          setLoading(false);
        }
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="mobile-title" style={{ maxWidth: "unset" }}>
        {leftContainer.title}
      </div>
      <div style={{ height: 10 }}></div>
      <div style={styles.description}>
        {leftContainer.pageDesc1}
        <div style={{ height: 10 }}></div>
        {leftContainer.pageDesc2}
      </div>
      <form>
        <input type="hidden" autoComplete="off" />
        <div style={styles.inputWrapper}>
          <div style={{ ...styles.input }}>
            <InputBox
              marginLeft={0}
              // border={`1px solid ${GRAY_FOOTER_COLOR}`}
              labelColor={GRAY_FOOTER_COLOR}
              boxShadow={"unset"}
              translations={translations}
              value={formData.name.value}
              name="name"
              handleChange={onChangeHandler}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              labelText={"First & last name"}
              height={30}
              fontSize="14px"
            />
            {!formData["name"].isValid && (
              <div
                style={{
                  color: RED_PRIMARY_COLOR,
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {translations["NAME_VALIDATION"]}
              </div>
            )}
          </div>
          <div style={{ ...styles.input }}>
            <InputBox
              marginLeft={0}
              // border={`1px solid ${GRAY_FOOTER_COLOR}`}
              labelColor={GRAY_FOOTER_COLOR}
              boxShadow={"unset"}
              translations={translations}
              value={formData.msisdn.value}
              name="msisdn"
              handleChange={onChangeHandler}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              labelText={"Mobile number"}
              height={30}
              fontSize="14px"
            />
            {!formData["msisdn"].isValid && (
              <div
                style={{
                  color: RED_PRIMARY_COLOR,
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {translations["MSISDN_VALIDATION"]}
              </div>
            )}
          </div>
        </div>
        <div style={styles.inputWrapper}>
          <div style={{ ...styles.input }}>
            <InputBox
              marginLeft={0}
              // border={`1px solid ${GRAY_FOOTER_COLOR}`}
              labelColor={GRAY_FOOTER_COLOR}
              boxShadow={"unset"}
              translations={translations}
              value={formData?.email?.value}
              name="email"
              handleChange={onChangeHandler}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              labelText={"E-mail"}
              height={30}
              type="email"
              fontSize="14px"
            />
            {!formData["email"].isValid && (
              <div
                style={{
                  color: RED_PRIMARY_COLOR,
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {translations["EMAIL_VALIDATION"]}
              </div>
            )}
          </div>
        </div>
        <div style={styles.inputWrapper}>
          <div style={{ ...styles.input }}>
            <InputBox
              marginLeft={0}
              height={60}
              // border={`1px solid ${GRAY_FOOTER_COLOR}`}
              labelColor={GRAY_FOOTER_COLOR}
              boxShadow={"unset"}
              translations={translations}
              value={formData.feedbackAndComment.value}
              name="feedbackAndComment"
              handleChange={onChangeHandler}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              labelText={"Leave your feedback & comments"}
              fontSize="14px"
            />
            {!formData["feedbackAndComment"].isValid && (
              <div
                style={{
                  color: RED_PRIMARY_COLOR,
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {translations["FEEDBACK_VALIDATION"]}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            ...styles.inputWrapper,
            marginTop: "1em",
            cursor: "pointer",
          }}
        >
          <div style={{ ...styles.input, alignItems: "center" }}>
            <Button
              translations={translations}
              onSubmit={handleFeedback}
              backgroundColor={WHITE_PRIMARY_COLOR}
              height={35}
              color={MOBILE_RED_PRIMARY_COLOR}
              fontSize="12px"
              width={100}
              padding="5px 5px"
              border={`0.75px solid ${MOBILE_RED_PRIMARY_COLOR}`}
              type="submit"
              wait={true}
              loading={loading}
            />
          </div>
        </div>
        {responseCompleted && (
          <div
            style={{ ...styles.inputWrapper1, marginTop: "5px" }}
            id="success"
          >
            <div style={{ ...styles.input }}>
              <span
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: GRAY_FOOTER_COLOR,
                  textAlign: "center",
                }}
              >
                {translations["Your Feedback Has Been Submitted"]}
              </span>
              <span
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  color: GRAY_FOOTER_COLOR,
                  textAlign: "center",
                }}
              >
                {" "}
                {translations["We'll contact you soon."]}
              </span>
            </div>
          </div>
        )}
        {error && (
          <div style={{ ...styles.inputWrapper1, marginTop: "5px" }} id="error">
            <div style={{ ...styles.input }}>
              <span
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: MOBILE_RED_PRIMARY_COLOR,
                  textAlign: "center",
                }}
              >
                {translations["Something went wrong"]}!
              </span>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default withRouter(ContactUs);
