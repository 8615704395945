import React from 'react';
import Wrapper from 'components/Wrapper';
import { GRAY_FOOTER_COLOR, OPAQUE_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';

const VideoBox = props => {

    const { src,
        title = "",
        width = 220,
        borderRadius = "30px",
        height = 200,
        backgroundColor = WHITE_PRIMARY_COLOR,
        onClick,
        description = "" } = props;

    return (
        <>
            <Wrapper borderRadius={borderRadius}>
                <div style={{ width: width, height: height, textAlign: 'center', backgroundColor: backgroundColor }}>
                    {!src ? <div style={{
                        width: '100%', height: '60%',
                        borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
                        backgroundColor: "#ccc",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={onClick}>
                        <div>
                            <img src="/iPayV2/icons/play.svg" height={40} width={40} />
                        </div>
                    </div> :
                        <video style={{ width: '100%', height: '60%', }} controls>
                            <source src={src} type="video/mp4" />
                        </video>}
                    <div style={{ height: '40%', backgroundColor: backgroundColor }}>
                        <div style={{ marginTop: 10 }}>
                            <span style={{
                                fontSize: "14px",
                                color: RED_PRIMARY_COLOR,
                                fontWeight: 'bold'
                            }}>{title}</span>
                        </div>
                        <div style={{
                            padding: "0px 20px",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "2",
                            overflow: 'hidden',
                            textOverflow: "ellipsis"
                        }}>
                            <span style={{
                                fontSize: "14px",
                                color: GRAY_FOOTER_COLOR,
                                fontWeight: 'normal'
                            }}>{description}</span>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

export default VideoBox;