import axios from 'axios';
import Fallback from 'components/Fallback';
import SearchBox from 'components/SearchBox';
import Wrapper from 'components/Wrapper';
import { fetchContent } from 'global/api';
import { GLOBAL_SEARCH, GRAY_FOOTER_COLOR, PAGE_MAPPING, WHITE_PRIMARY_COLOR } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

const MobileSearchResults = props => {

    const { translations, leftContainer, rightContainer, resultHandler } = props;

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "row",
            width: '100%',
            height: 'calc(100% - 2em)',
            marginTop: "3em",
        },
        leftContainer: {
            display: 'flex',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "20%", marginTop: '2rem'
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: "75%",
            marginLeft: '3em',
            marginRight: '3em',
            alignItems: 'center',
            justifyContent: 'center'
        },
        answerLine: {
            height: "1px",
            backgroundColor: "#ccc",
            margin: "4px 0px 4px 0px",
            lineHeight: "1.67"
        }
    }

    return (<div className="mobile-container">
        <div className="mobile-title">
            {leftContainer.title && leftContainer.title.split(":").length > 1
                ? <>
                    {leftContainer.title.split(":")[0]}:
                    <br />
                    <span style={{ fontSize: "16px" }}>{leftContainer.title.split(":")[1]}</span>
                </>
                : <>{leftContainer.title}</>}
        </div>
        {rightContainer.results && rightContainer.results.length > 0 ?
            <div style={{
                paddingBottom: '0.75em',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {rightContainer.results.map((result, index) => {
                    return <div>
                        <div key={index + ""} style={{
                            cursor: 'pointer',
                            display: 'flex',
                            fontSize: 14, padding: '1em 0',
                            color: GRAY_FOOTER_COLOR, textAlign: 'left'
                        }} onClick={() => resultHandler(result)}>
                            {index + 1}. {result.title}
                        </div>
                        {index !== rightContainer.results.length - 1 && <div style={styles.answerLine}>
                        </div>}
                    </div>
                })}
            </div>
            : <div style={{
                color: GRAY_FOOTER_COLOR, fontSize: 14,
                display: 'flex',
                flexDirection: 'column',
                display: 'flex',
                marginTop : '10rem',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {translations["No Results found, Try to search more specific."]}
            </div>}
    </div>)


}

export default withRouter(MobileSearchResults);