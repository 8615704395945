import Accordion from 'components/Accordion';
import Fallback from 'components/Fallback';
import SearchBox from 'components/SearchBox';
import SideMenu from 'components/SideMenu';
import Wrapper from 'components/Wrapper';
import { logEvent } from 'firebase-analytics';
import { fetchContent } from 'global/api';
import { GRAY_COLOR, MOBILE_RED_PRIMARY_COLOR, QA_TYPE_IMAGE, QA_TYPE_VIDEO, RED_PRIMARY_COLOR, SEARCH_TS_CONTENT, TS_PAGE_URL } from 'global/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import MobileTroubleShooting from '../Mobile/TroubleShooting'
import ComingSoon from 'components/ComingSoon';

const TroubleShooting = props => {

    const styles = {
        container: {
            display: 'flex',
            width: '100%',
            marginTop: "2em",
            height: '100%',
            alignItems: 'center'
        },
        leftContainer: {
            display: 'flex',
            alignItems: 'flex-start', marginLeft: '6em',
            flexDirection: 'column', flex: "30%"
        },
        description: {
            fontSize: '14px', color: "#fff", maxHeight: '40vh', overflowY: "auto"
        },
        rightContainer: {
            display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: "6em",
            flex: "65%",
            height: "100%",
            overflow: 'hidden'
        },
        answerLine: {
            height: "2px",
            backgroundColor: "#ccc",
            width: 'calc(100% - 2em)',
            margin: '1em auto',
            lineHeight: "1.67"
        }
    }

    const [leftContainer, setLeftContainer] = useState();
    const [rightContainer, setRightContainer] = useState();
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState({
        loading: false,
        isSearch: false
    });
    const [isError, setIsError] = useState(false);
    const [activeId, setActiveId] = useState("1");
    const [video, setVideo] = useState();
    const [image, setImage] = useState();
    const [checkedId, setCheckedId] = useState(0);
    const location = useLocation();
    const [serverResponse, setServerResponse] = useState(false);
    const { translations, responsive } = props;

    useEffect(() => {
        setIsError(false)
        fetchFaq("");
    }, [])

    useEffect(() => {
        if (isError)
            props.history.replace('errorPage')
    }, [isError])

    useEffect(() => {
        // check for any data in search query params;
        if (serverResponse) {
            if (location && location.state) {
                setRightContainer({ questions: [location.state.searchQueryData] })
            }
            setLoading(false)
        }
    }, [serverResponse, location])


    const fetchFaq = (subCategory, cb) => {
        logEvent(`ts_content_load_start_${subCategory}`);
        fetchContent(TS_PAGE_URL, { subCategory: subCategory }).then((response) => {
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        const leftContainer = data.leftContainer;
                        let activeId;
                        if (leftContainer && leftContainer.menu && leftContainer.menu.length > 0)
                            activeId = leftContainer.menu[0].sequenceNo;
                        setActiveId(activeId)
                        setLeftContainer(leftContainer);
                        setRightContainer(data.rightContainer);
                        setServerResponse(true)
                    }
                    logEvent(`ts_content_load_success_${subCategory}`);
                    // setLoading(false)
                } else {
                    setIsError(true);
                    setLoading(false);
                    logEvent(`ts_content_load_fail_${subCategory}`);
                }
            } else {
                setIsError(true);
                setLoading(false);
                logEvent(`ts_content_load_fail_${subCategory}`);
            }
        });
    }

    const fetchQuestions = (subCategory, cb) => {
        setSearchLoading({
            loading: true,
            isSearch: false
        });
        logEvent(`ts_content_load_start_${subCategory}`);
        fetchContent(TS_PAGE_URL, { subCategory: subCategory }).then((response) => {
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        setRightContainer(data.rightContainer);
                    }
                    setSearchLoading({
                        loading: false,
                        isSearch: false
                    });
                    logEvent(`ts_content_load_success_${subCategory}`);
                    cb()
                }
            } else {
                setSearchLoading({
                    loading: false,
                    isSearch: false
                });
                logEvent(`ts_content_load_fail_${subCategory}`);
            }
        });
    }

    const selectMenuItemHandler = (id) => {

        const menu = leftContainer.menu.find(item => {
            return item.sequenceNo == id
        })
        fetchQuestions(menu.title, () => {
            setActiveId(id);
            setVideo();
            setImage();
        })
    }

    const handlePreview = (type, asset) => {
        if (type === QA_TYPE_VIDEO)
            setVideo(asset);
        else if (type === QA_TYPE_IMAGE)
            setImage(asset)
    }

    const searchHandler = (query) => {
        setSearchLoading({
            loading: true,
            isSearch: true
        });
        logEvent("ts_search_start");
        fetchContent(SEARCH_TS_CONTENT, { query: query }).then((response) => {
            // console.log(response)
            if (response) {
                if (response.code === 2000) {
                    const data = response.data;
                    if (data) {
                        setRightContainer(data.rightContainer);
                    }
                    setSearchLoading({
                        loading: false,
                        isSearch: true
                    });
                    logEvent("ts_search_success");
                }
            } else {
                setSearchLoading({
                    loading: false,
                    isSearch: true
                });
                logEvent("ts_search_fail");
            }
        });
    }

    const [videoLoading, setVideoLoading] = useState(false);

    useEffect(() => {
        setVideoLoading(video ? video === "default" ? false : video.mediaUrl ? false : true : false);
        const id = setTimeout(() => {
            setVideoLoading(false)
        }, 10000);

        return () => clearTimeout(id);
    }, [video])

    return loading ? <Fallback loading={loading}
        color={responsive ? MOBILE_RED_PRIMARY_COLOR : RED_PRIMARY_COLOR} /> : !responsive ? <div style={styles.container}>
            <div style={styles.leftContainer} className="left-container">
                <div className="title">
                    {leftContainer.title}
                </div>
                <div className="Line-2">
                </div>
                <div style={styles.description}>
                    <SideMenu styles={{ labelWidth: 110, marginBottom: 7 }}
                        translations={translations}
                        activeId={activeId} menu={leftContainer.menu}
                        selectMenuItemHandler={selectMenuItemHandler} />
                </div>
            </div>
            <div style={{ ...styles.rightContainer, alignItems: searchLoading.loading ? 'center' : 'flex-start' }}>
                {searchLoading.loading ?
                    <Fallback loading={searchLoading.loading} color={RED_PRIMARY_COLOR} /> :
                    !(video || image) ?
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", paddingTop: "1em" }}>
                                <SearchBox border="1px solid rgb(200, 6, 0, 0.8)" labelRequired={false}
                                    id="troubleShootingSearch" src="/iPayV2/icons/faq search.svg"
                                    searchHandler={searchHandler} translations={translations} />
                            </div>
                            <div style={{
                                width: "80%", marginTop: "10px", height: '100%', backgroundColor: GRAY_COLOR,
                                overflow: 'auto', borderRadius: '30px 30px 0px 0px'
                            }}>
                                <div style={{
                                    minHeight: "100%",
                                    overflowY: 'auto', padding: "10px 20px",
                                }}>
                                    {rightContainer.questions && rightContainer.questions.length !== 0 ? rightContainer.questions.map((faq, index) => (
                                        <Accordion handlePreview={handlePreview}
                                            type={faq.type} text={faq.text}
                                            translations={translations}
                                            assets={faq.assets}
                                            setCheckedId={() => setCheckedId(index)}
                                            checkedId={checkedId} id={index} tabLabel={faq.question} />
                                    )) : searchLoading.isSearch ? <div style={{
                                        color: RED_PRIMARY_COLOR, fontSize: 24, width: '100%',
                                        minHeight: '100%',
                                        maxHeight: '100%',
                                        display: 'flex',
                                        marginTop: '25%',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {translations["No Results found, Try to search more specific."]}
                                    </div> : < ComingSoon translations={translations} />}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div onClick={() => {
                                setVideo();
                                setImage()
                            }} style={{ display: 'flex', cursor: 'pointer', width: '100%' }}>
                                <img src="/iPayV2/icons/backicon.svg" />
                            </div>
                            <div style={{
                                display: 'flex',
                                minHeight: "330px", padding: '1em 6em', maxHeight: '330px',
                                overflow: 'auto', width: 'calc(100% - 12em)'
                            }}>
                                {videoLoading && <Fallback loading={videoLoading}
                                    style={{ position: "absolute" }}
                                    color={RED_PRIMARY_COLOR} height="400px" />}
                                {video ?
                                    video.mediaUrl ? <video autoPlay="true"
                                        controls
                                        playsInline
                                        loop
                                        onLoadCapture={() => setVideoLoading(false)}
                                        width="600px" height="360px">
                                        <source src={video.mediaUrl} type="video/mp4" />
                                    </video> : <iframe allowFullScreen={true}
                                        onLoadCapture={() => setVideoLoading(false)}
                                        loading="lazy" src={video.redirectUrl}
                                        height="360px" style={{ width: "600px" }}
                                    />
                                    : <img src={image.mediaUrl == null ? image.redirectUrl : image.mediaUrl}
                                        width="100%" />
                                }
                            </div>
                        </>
                }
            </div>
        </div> : <MobileTroubleShooting {...props} leftContainer={leftContainer} />
}

export default withRouter(TroubleShooting);