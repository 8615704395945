import React from 'react';
import { GRAY_FOOTER_COLOR, RED_PRIMARY_COLOR, WHITE_PRIMARY_COLOR } from 'global/constants';
import { getIconURL } from 'global/utils';

const LabelBox = props => {

    const {
        active = false,
        embededActive = false,
        handleClick,
        icon = false,
        iconname = "",
        className="",
        width = "fit-content",
        id = "label",
        color = RED_PRIMARY_COLOR,
        inactiveColor = WHITE_PRIMARY_COLOR,
        textCase = "none",
        backgroundColor = WHITE_PRIMARY_COLOR,
        labelText = "Your label",
        iconWidth = 20,
        undefinedColor = undefined,
        padding = "10px 20px",
        height = "fit-content",
        marginRight = "12px",
        borderRadius = "20px",
        border = `2px solid ${backgroundColor}`,
        labelTextStyle = {} } = props;

    const styles = {
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: active ? color : inactiveColor,
            padding: padding,
            cursor: "pointer",
            width: width,
            height: height,
            fontWeight: active ? 'bold' : 'normal',
            backgroundColor: active ? backgroundColor : undefined,
            // borderRadius: "2rem",
            borderRadius: borderRadius,
            border: embededActive ? border : undefined
        }
    }

    const clickHandler = () => {
        if (handleClick) {
            handleClick();
        }
    }

    // getIconURL(active, iconname)
    return <div style={styles.content} onClick={clickHandler} id={id}>
        {icon && <div style={{ marginRight: marginRight, display: 'flex' }}>
            <img src={getIconURL(active, iconname)} width={iconWidth} height={iconWidth} />
        </div>}
        <div className={className} style={{
            ...labelTextStyle, textTransform: textCase,
            whiteSpace: "nowrap", overflow: 'hidden', textOverflow: 'ellipsis'
        }} >
            {labelText}
            <div id={id + "text"} style={{ borderBottom: (id === "link" && active) ? '1px solid #fff' : undefined }}></div>
        </div>
    </div>

}

export default LabelBox;