import ComingSoon from 'components/ComingSoon';
import React from 'react';

const Kiosks = props => {

    const { data = [], translations, responsive, title } = props;

    return (
        <div>
            <div className="mobile-title">
                {title}
            </div>
            {data && data.length === 0 ? <div
                style={{
                    display: 'flex',
                    marginTop: '3rem',
                    justifyContent: 'center'
                }}><ComingSoon translations={translations}
                    responsive={responsive} /></div> : null}
        </div>
    )
}

export default Kiosks;